export const idlFactory = ({ IDL }) => {
  const IndexServerStatistics = IDL.Record({
    'prim' : IDL.Record({
      'rts_max_live_size' : IDL.Nat,
      'rts_stable_memory_size' : IDL.Nat,
      'rts_memory_size' : IDL.Nat,
      'rts_total_allocation' : IDL.Nat,
      'rts_max_stack_size' : IDL.Nat,
      'rts_logical_stable_memory_size' : IDL.Nat,
      'rts_heap_size' : IDL.Nat,
      'rts_reclaimed' : IDL.Nat,
      'rts_version' : IDL.Text,
    }),
  });
  const EnterpriseProfile = IDL.Record({
    'tel' : IDL.Text,
    'tex' : IDL.Text,
    'zip' : IDL.Text,
    'name' : IDL.Text,
    'email' : IDL.Text,
    'address' : IDL.Text,
  });
  const Statistics = IDL.Record({
    'bridge' : IDL.Record({ 'incoming' : IDL.Nat64, 'outgoing' : IDL.Nat64 }),
    'prim' : IDL.Record({
      'rts_max_live_size' : IDL.Nat,
      'rts_stable_memory_size' : IDL.Nat,
      'rts_memory_size' : IDL.Nat,
      'rts_total_allocation' : IDL.Nat,
      'rts_max_stack_size' : IDL.Nat,
      'rts_logical_stable_memory_size' : IDL.Nat,
      'rts_heap_size' : IDL.Nat,
      'rts_reclaimed' : IDL.Nat,
      'rts_version' : IDL.Text,
    }),
    'pleximail' : IDL.Record({
      'incoming' : IDL.Nat64,
      'outgoing' : IDL.Nat64,
    }),
    'account' : IDL.Record({
      'premium' : IDL.Int,
      'free' : IDL.Int,
      'lite' : IDL.Int,
      'count' : IDL.Int,
    }),
  });
  const CanisterStatistics = IDL.Record({
    'canisterId' : IDL.Text,
    'statistics' : Statistics,
  });
  const PartitionStatistics = IDL.Record({
    'pk' : IDL.Text,
    'canisterCount' : IDL.Int,
    'profile' : IDL.Opt(EnterpriseProfile),
    'statistics' : IDL.Vec(CanisterStatistics),
  });
  const AllStatistics = IDL.Record({
    'partitions' : IDL.Vec(PartitionStatistics),
    'index' : IndexServerStatistics,
  });
  const InterCanisterActionResult = IDL.Variant({
    'ok' : IDL.Null,
    'err' : IDL.Text,
  });
  const IndexCanister = IDL.Service({
    'autoScalePlexiMailServiceCanister' : IDL.Func([IDL.Text], [IDL.Text], []),
    'chargeCanister' : IDL.Func([IDL.Text], [IDL.Nat], []),
    'createPlexiMailServiceCanisterByGroup' : IDL.Func(
        [IDL.Text],
        [IDL.Opt(IDL.Text)],
        [],
      ),
    'deleteServiceCanisterById' : IDL.Func([IDL.Text, IDL.Text], [], []),
    'getCanistersByPK' : IDL.Func([IDL.Text], [IDL.Vec(IDL.Text)], ['query']),
    'getIndexStatistics' : IDL.Func(
        [],
        [IndexServerStatistics],
        ['composite_query'],
      ),
    'getPartitions' : IDL.Func([], [IDL.Vec(IDL.Text)], ['composite_query']),
    'getProfile' : IDL.Func(
        [IDL.Text],
        [IDL.Opt(EnterpriseProfile)],
        ['composite_query'],
      ),
    'getStatistics' : IDL.Func([], [AllStatistics], ['composite_query']),
    'getStatisticsByCanister' : IDL.Func(
        [IDL.Text],
        [CanisterStatistics],
        ['composite_query'],
      ),
    'getStatisticsByPK' : IDL.Func(
        [IDL.Text],
        [PartitionStatistics],
        ['composite_query'],
      ),
    'saveProfile' : IDL.Func([IDL.Text, EnterpriseProfile], [IDL.Bool], []),
    'upgradeServiceCanisterByPk' : IDL.Func(
        [IDL.Text, IDL.Vec(IDL.Nat8), IDL.Vec(IDL.Principal)],
        [IDL.Vec(IDL.Tuple(IDL.Text, InterCanisterActionResult))],
        [],
      ),
  });
  return IndexCanister;
};
export const init = ({ IDL }) => { return []; };
