// import './App.css';
import React, { useState, useEffect, createRef } from "react";

import {
  VStack,
  Text,
  Button,
  TableContainer,
  Table,
  // TableCaption,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  // Tfoot,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Input,
  InputGroup,
  // InputRightElement,
  InputRightAddon,
  // Checkbox,
  // InputGroup,
  // InputRightElement,
  // Progress,
  // Spinner,
  // Link,
  IconButton,
  useDisclosure,
  Box,
  // HStack,
  Flex,
  FormLabel,
  FormControl,
  Center,
  List,
  ListItem,
  MenuButton,
  Menu,
  MenuList,
  MenuItem,
  // Checkbox
  // ,MenuButton,MenuDivider
} from "@chakra-ui/react";

import { showError, showSuccess } from "../../common/errors";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import toast, { Toaster } from "react-hot-toast";
// import { QRCodeCanvas } from "qrcode.react";
// import Strings from "../../config/Strings";
// import { exportComponentAsJPEG } from "react-component-export-image";
// import { FaEye, FaEyeSlash, FaUndo } from "react-icons/fa";

import {
  FiTrash2,
  FiCopy,
  FiBookOpen,
  FiBook,
  FiChevronDown,
  FiChevronRight,
  FiUsers,
  FiSearch,
  FiPlus,
  FiEdit3,
  FiSend,
  FiSave,
  FiRotateCcw,
  FiUser,
  // FiMoreVertical,
  // FiUser,
  // FiEdit,
  // FiDelete,
  // FiTrash,
  // FiPlus,
  // FiChevronUp,
  // FiCheckSquare,
  // FiSquare,
  // FiMinusSquare,
  // FiFolder,
  // FiFolderPlus,
} from "react-icons/fi";

// import CheckboxTree from 'react-checkbox-tree';
// import 'react-checkbox-tree/lib/react-checkbox-tree.css';
import { Tree } from "react-arborist";

import { Facebook } from "react-content-loader";
import EnterpriseStrings from "./EnterpriseStrings";

import CopyToClipboard from "react-copy-to-clipboard";
import { mailAddressSuffix } from "../../common/constants";

// import copy from 'copy-to-clipboard';

const UsersAlert = ({ disclosure, onSelected }) => {
  // https://reactjsexample.com/a-sortable-virtual-customizable-tree-component-for-react/#tree-component

  const [users, setUsers] = useState([]);

  const [filteredUsers, setFilteredUsers] = useState([]);
  useEffect(() => {
    if (disclosure.isOpen) {
      setUsers([]);
      setFilteredUsers([]);
      window.mailService
        .getAllUsers()
        .then((users) => {
          if (!users || users.length === 0) {
            setUsers([]);
            setFilteredUsers([]);
          } else {
            setUsers(users);
            setFilteredUsers(users);
          }
        })
        .catch((e) => {
          console.error(e);
          showError(e);
        });
    }
  }, [disclosure.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const filterUsers = (keywords) => {
    if (keywords) {
      keywords = keywords.toLowerCase();
    }
    if (!users || users.length === 0) {
      setFilteredUsers([]);
      return;
    }

    // const contains = (item.id.toLowerCase().startsWith(query.toLowerCase()) ||
    // item.name.toLowerCase().startsWith(query.toLowerCase()))
    const fus = users.filter((user) => {
      if (user.name && user.name.toLowerCase().startsWith(keywords)) {
        return true;
      }
      if (user.email && user.email.toLowerCase().startsWith(keywords)) {
        return true;
      }
      return false;
    });
    setFilteredUsers(fus);
  };
  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {EnterpriseStrings.address_book.users_dlg.title}
          </AlertDialogHeader>

          <AlertDialogBody>
            <FormControl>
              <FormLabel>
                {EnterpriseStrings.address_book.users_dlg.filter.title}{" "}
              </FormLabel>
              <InputGroup>
                <Input
                  autoComplete="off"
                  placeholder={
                    EnterpriseStrings.address_book.users_dlg.filter.placeholder
                  }
                  type={"text"}
                  onChange={(e) => {
                    //onSelected(e.target.value);
                    filterUsers(e.target.value || "");
                  }}
                />

                <InputRightAddon
                  cursor={"pointer"}
                  onClick={() => {
                    /*console.log('addon is clicked')*/
                  }}
                >
                  <FiSearch color="gray.300" />
                </InputRightAddon>
              </InputGroup>
            </FormControl>

            <List spacing={0} mt={4} textColor="gray.600" fontSize={"12px"}>
              {filteredUsers &&
                filteredUsers.map((user) => {
                  return (
                    <ListItem
                      key={user.addr}
                      paddingTop={1}
                      paddingBottom={1}
                      borderBottom={"1px solid lightGray"}
                      cursor={"pointer"}
                      onClick={() => {
                        const contact = {
                          name: user.name,
                          email: user.email || "",
                          addr: `${user.addr}${mailAddressSuffix()}`,
                        };
                        // user.addr = `${user.addr${mailAddressSuffix()}`;
                        onSelected(contact);
                        disclosure.onClose();
                      }}
                    >
                      <VStack fontSize={"sm"} spacing={0} align={"left"}>
                        <Text wordBreak={"break-all"} fontWeight={"bold"}>
                          {user.name}
                        </Text>
                        <Text wordBreak={"break-all"} fontWeight={"normal"}>
                          {user.email}
                        </Text>
                        <Text wordBreak={"break-all"} fontWeight={"address"}>
                          {user.addr.toLowerCase() + mailAddressSuffix()}
                        </Text>
                      </VStack>
                    </ListItem>
                  );
                })}

              {(!filteredUsers || filteredUsers.length === 0) && (
                <ListItem
                  key={"empty"}
                  paddingTop={1}
                  paddingBottom={1}
                  borderBottom={"1px solid lightGray"}
                  cursor={"pointer"}
                  onClick={() => {
                    disclosure.onClose();
                  }}
                >
                  <VStack fontSize={"sm"} spacing={0} align={"left"}>
                    <Text wordBreak={"break-all"} fontWeight={"bold"}>
                      {EnterpriseStrings.address_book.users_dlg.empty}
                    </Text>
                  </VStack>
                </ListItem>
              )}
            </List>
          </AlertDialogBody>

          <AlertDialogFooter>
            {(!filteredUsers || filteredUsers.length === 0) && (
              <Button
                colorScheme={"gray"}
                mr={4}
                onClick={() => {
                  disclosure.onClose();
                }}
              >
                {EnterpriseStrings.address_book.users_dlg.cancel}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

const CreateContactNodeAlert = ({
  isOrg,
  disclosure,
  parentNode,
  onCompleted,
}) => {
  // Encode your PlexiMail Address into a picture or QR code for passing along to your friends and partners.

  // Optionally enter a password for extra protection.
  const [isCreating, setCreating] = useState(false);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [addr, setAddr] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  // https://reactjsexample.com/a-sortable-virtual-customizable-tree-component-for-react/#tree-component
  const userDisclosure = useDisclosure();
  const [defaultName, setDefaultName] = useState(null);

  useEffect(() => {
    if (disclosure.isOpen && isOrg && !parentNode) {
      window.mailService
        .getEnterpriseProfile()
        .then((profile) => {
          setDefaultName(profile && profile.name);
          setName(profile && profile.name);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      setDefaultName(null);
      setName(null);
    }
  }, [disclosure.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const createContact = async () => {
    try {
      setCreating(true);
      if (isOrg) {
        const uuid = window.mailService.generateUUIDSync();
        onCompleted({ id: uuid, name: name, children: [] });
      } else {
        onCompleted({ name, email, addr });
      }
      disclosure.onClose();
    } catch (e) {
      console.error(e);
      showError(e);
    } finally {
      setCreating(false);
    }
  };

  return (
    <>
      <UsersAlert
        disclosure={userDisclosure}
        onSelected={(user) => {
          // console.log(user);
          if (user) {
            setSelectedUser(user);
            if (user.name && user.name.toLowerCase() !== "me") {
              setName(user.name);
            } else {
              setName(null);
            }
            setEmail(user.email);
            setAddr(user.addr);
          }
        }}
      />
      <AlertDialog
        isOpen={disclosure.isOpen}
        onClose={disclosure.onClose}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {isOrg
                ? EnterpriseStrings.address_book.add_dlg.title.create_org
                : EnterpriseStrings.address_book.add_dlg.title.create_contact}
            </AlertDialogHeader>

            <AlertDialogBody>
              {isOrg && (
                <FormControl>
                  <FormLabel>
                    {EnterpriseStrings.address_book.add_dlg.name}{" "}
                  </FormLabel>
                  <Input
                    autoComplete="off"
                    defaultValue={defaultName}
                    placeholder={
                      EnterpriseStrings.address_book.add_dlg.name_placeholder
                    }
                    type={"text"}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </FormControl>
              )}
              {!isOrg && (
                <>
                  <FormControl>
                    <FormLabel>
                      {EnterpriseStrings.address_book.add_dlg.name}{" "}
                    </FormLabel>

                    {/* <AutoComplete openOnFocus>
                  <AutoCompleteInput variant="filled" onChange={(e) => {
                    console.log('onChanged:', e);
                  }} />
                  <AutoCompleteList>
                    {users.map((user) => (
                      <AutoCompleteItem
                        key={`${user.addr}`}
                        value={user.name}
                        textTransform="capitalize"
                      >
                        {user.name}
                        
                      </AutoCompleteItem>
                    ))}
                  </AutoCompleteList>
                </AutoComplete> */}
                    <InputGroup>
                      <Input
                        autoComplete="off"
                        placeholder={
                          EnterpriseStrings.address_book.add_dlg
                            .name_placeholder
                        }
                        defaultValue={
                          selectedUser &&
                          selectedUser.name.toLowerCase() !== "me"
                            ? selectedUser.name
                            : ""
                        }
                        value={name}
                        type={"text"}
                        onChange={(e) => {
                          setName(e.target.value);
                        }}
                      />

                      {/* <InputRightElement pointerEvents="none" children={<FiSearch color="gray.300" />} /> */}
                      <InputRightAddon
                        cursor={"pointer"}
                        onClick={() => {
                          userDisclosure.onOpen();
                        }}
                      >
                        <FiPlus color="gray.300" />
                      </InputRightAddon>
                    </InputGroup>
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      {EnterpriseStrings.address_book.add_dlg.email}{" "}
                    </FormLabel>
                    <Input
                      autoComplete="off"
                      placeholder={
                        EnterpriseStrings.address_book.add_dlg.email_placeholder
                      }
                      type={"text"}
                      defaultValue={selectedUser ? selectedUser.email : ""}
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>
                      {EnterpriseStrings.address_book.add_dlg.plexi_addr}{" "}
                    </FormLabel>
                    <Input
                      autoComplete="off"
                      placeholder={
                        EnterpriseStrings.address_book.add_dlg
                          .plexi_addr_placeholder
                      }
                      type={"text"}
                      defaultValue={selectedUser ? selectedUser.addr : ""}
                      value={addr}
                      onChange={(e) => {
                        setAddr(e.target.value);
                      }}
                    />
                  </FormControl>
                </>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                isDisabled={isCreating}
                colorScheme={"gray"}
                mr={4}
                onClick={() => {
                  setName(null);
                  setEmail(null);
                  setAddr(null);
                  setSelectedUser(null);
                  disclosure.onClose();
                }}
                ml={3}
              >
                {EnterpriseStrings.address_book.add_dlg.cancel}
              </Button>
              <Button
                isDisabled={false}
                isLoading={isCreating}
                colorScheme={"green"}
                onClick={() => {
                  createContact().then((e) => {
                    setName(null);
                    setEmail(null);
                    setAddr(null);
                    setSelectedUser(null);
                  });
                }}
              >
                {EnterpriseStrings.address_book.add_dlg.create}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const AddressBookContent = ({
  selectedAccount,
  setSelectedRecipients,
  composeDisclosure,
  disclosure,
  selectedIndex,
  setSelectedIndex,
}) => {
  // const { addToast } = useToasts()
  // const [qrcodeFilter, setQRCodeFilter] = useState('none');

  const [term, setTerm] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [addressBooks, setAddressBooks] = useState(null);
  const [contacts, setContacts] = useState(null);
  const [filteredContacts, setFilteredContacts] = useState(null);
  const [parentNode, setParentNode] = useState(null);
  const [isOrg, setOrg] = useState(true);
  const alertDisclosure = useDisclosure({
    onOpen: () => {},
  });
  const [originVersion, setOriginVersion] = useState(0);
  // const [modifyVersion, setModifyVersion] = useState(0);
  const globalTree = (tree) => {
    // @ts-ignore
    window.tree = tree;
  };
  const contactFilterInputRef = createRef();

  const [isOpen, setIsOpen] = useState(false);
  const menuRef = createRef();
  useEffect(() => {
    // window.mailService.get
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    console.log("AddressBook content changed:", addressBooks);
  }, [addressBooks]); // eslint-disable-line react-hooks/exhaustive-deps

  const addressBookIsChanged = () => {
    return (
      addressBooks &&
      addressBooks.length !== 0 &&
      originVersion !== addressBooks[0].version
    );
  };
  useEffect(() => {
    // didUpdate
    if (!disclosure.isOpen) {
      return;
    }
    if (selectedIndex !== 2) {
      return;
    }
    setLoading(true);
    setAddressBooks(null);
    setOriginVersion(0);
    getAddressBook()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
        showError(e);
        setLoading(false);
      });
  }, [disclosure.isOpen, selectedIndex]); // eslint-disable-line react-hooks/exhaustive-deps

  const findDataNode = (node, id) => {
    if (node.id === id) {
      return node;
    }

    for (const org of node.children) {
      const v = findDataNode(org, id);
      if (v) {
        return v;
      }
    }
    return null;
  };

  const saveAddressBook = async () => {
    if (
      addressBooks &&
      addressBooks.length !== 0 &&
      originVersion !== addressBooks[0].version
    ) {
      const key = await window.mailService.createEnterpriseContacts();
      await window.mailService.putEnterpriseContacts(key, addressBooks);
      setOriginVersion(addressBooks[0].version);
    }
  };

  const getAddressBook = async () => {
    let contacts = await window.mailService.getEnterpriseContacts();
    if (contacts && contacts.length === 1) {
      setOriginVersion(contacts[0].version);
    }
    setAddressBooks(contacts);
  };

  const onCreate = ({ parentId, parentNode, index, type }) => {
    const id = window.mailService.generateUUIDSync();

    let parent = null;
    for (const ab of addressBooks) {
      const n = findDataNode(ab, parentId);
      if (n) {
        parent = n;
        if (!ab.version) {
          ab.version = 1;
        } else {
          ab.version += 1;
        }
        break;
      }
    }
    if (parent) {
      if (!parent.children) {
        parent.children = [];
      }

      parent.children.push({ id: id, name: "Unnamed", children: [] });
    }
    setAddressBooks([...addressBooks]);
    return { id };
  };
  const onRename = ({ id, name, node }) => {
    let nodeData = null;
    for (const ab of addressBooks) {
      const n = findDataNode(ab, id);
      if (n) {
        nodeData = n;
        if (!ab.version) {
          ab.version = 1;
        } else {
          ab.version += 1;
        }
        break;
      }
    }
    if (nodeData) {
      nodeData.name = name;
    }
  };

  const onMove = ({ dragIds, dragNodes, parentId, parentNode, index }) => {
    let parentData = null;
    for (const ab of addressBooks) {
      const n = findDataNode(ab, parentNode.data.id);
      if (n) {
        parentData = n;
        if (!ab.version) {
          ab.version = 1;
        } else {
          ab.version += 1;
        }
        break;
      }
    }

    if (parentData) {
      if (!parentData.children) {
        parentData.children = [];
      }

      for (const dragNode of dragNodes) {
        parentData.children.push(dragNode.data);
      }
    }

    for (const node of dragNodes) {
      const p = node.parent;

      let parentNodeData = null;
      for (const ab of addressBooks) {
        const n = findDataNode(ab, p.id);
        if (n) {
          parentNodeData = n;
          if (!ab.version) {
            ab.version = 1;
          } else {
            ab.version += 1;
          }
          break;
        }
      }

      if (parentNodeData && parentNodeData.children) {
        parentNodeData.children = parentNodeData.children.filter((c) => {
          return c.id !== node.id;
        });
      }
    }
    setAddressBooks([...addressBooks]);
  };

  const onDelete = ({ ids, nodes }) => {
    for (const node of nodes) {
      const p = node.parent;

      let parentNodeData = null;
      for (const ab of addressBooks) {
        const n = findDataNode(ab, p.id);
        if (n) {
          parentNodeData = n;
          if (!ab.version) {
            ab.version = 1;
          } else {
            ab.version += 1;
          }
          break;
        }
      }

      if (parentNodeData && parentNodeData.children) {
        parentNodeData.children = parentNodeData.children.filter((c) => {
          return c.id !== node.id;
        });
      }
    }
    setAddressBooks([...addressBooks]);
  };

  const filterContacts = (v) => {
    if (!contacts || contacts.length === 0) {
      return;
    }
    const keywords = (v || "").toLowerCase();
    const fc = contacts.filter((c) => {
      if (c.name && c.name.toLowerCase().indexOf(keywords) !== -1) {
        return true;
      }
      if (c.email && c.email.toLowerCase().indexOf(keywords) !== -1) {
        return true;
      }
      if (c.addr && c.addr.toLowerCase().indexOf(keywords) !== -1) {
        return true;
      }
      return false;
    });
    setFilteredContacts(fc);
  };

  const deleteContact = async (c) => {
    for (const ab of addressBooks) {
      const n = findDataNode(ab, parentNode.id);
      if (n) {
        if (!ab.version) {
          ab.version = 1;
        } else {
          ab.version += 1;
        }
        const members = n.members.filter((m) => {
          return m.addr !== c.addr;
        });
        n.members = members;
        setContacts(n.members);
        setFilteredContacts(n.members);
        break;
      }
    }
    setAddressBooks([...addressBooks]);
  };
  return (
    <>
      <CreateContactNodeAlert
        isOrg={isOrg}
        disclosure={alertDisclosure}
        parentNode={parentNode}
        onCompleted={(result) => {
          console.log("editor:", result);
          if (parentNode) {
            let node = null;
            for (const ab of addressBooks) {
              const n = findDataNode(ab, parentNode.data.id);
              if (n) {
                node = n;
                if (!ab.version) {
                  ab.version = 1;
                } else {
                  ab.version += 1;
                }
                break;
              }
            }
            if (isOrg) {
              if (!node.children) {
                node.children = [];
              }
              node.children.push(result);
            } else {
              if (!node.members) {
                node.members = [];
              }
              node.members.push(result);
              setContacts(node.members);
              setFilteredContacts(node.members);
              if (contactFilterInputRef && contactFilterInputRef.current) {
                contactFilterInputRef.current.value = "";
              }
              console.log("json:", JSON.stringify([...addressBooks]));
            }
            setAddressBooks([...addressBooks]);
          } else {
            if (addressBooks) {
              result.version = 1;
              setAddressBooks([...addressBooks, result]);
            } else {
              result.version = 1;
              setAddressBooks([result]);
            }
          }
          //setModifyVersion(modifyVersion + 1);
        }}
      />
      {isLoading && (
        <Flex border={"1px solid gray"}>
          <Facebook />
        </Flex>
      )}
      {!isLoading && (
        <Flex border={"1px solid gray"}>
          <Box
            w="320px"
            minH={480}
            p={3}
            borderRight={"1px solid gray"}
            overflow={"scoll-x"}
          >
            {!addressBooks && (
              <Center h={"100%"}>
                <Button
                  onClick={() => {
                    setOrg(true);
                    setParentNode(null);
                    alertDisclosure.onOpen();
                  }}
                >
                  {EnterpriseStrings.address_book.create}
                </Button>
              </Center>
            )}
            {addressBooks && (
              <>
                <Input
                  defaultValue={term}
                  placeholder={EnterpriseStrings.address_book.filter}
                  mb={2}
                  onChange={(e) => setTerm(e.currentTarget.value)}
                />

                <Menu
                  isOpen={isOpen}
                  ref={menuRef}
                  id={"tree-context-menu"}
                  onClose={() => {
                    setIsOpen(false);
                  }}
                >
                  <MenuList
                    onAnimationEnd={(e) => {
                      // const menu = menuRef.current;//document.querySelector("[role=menu]");

                      const menu = document.getElementById(
                        "menu-list-tree-context-menu"
                      );
                      // const menu = document.querySelector("[role=menu]");
                      if (menu) {
                        //menu.focus();
                      }
                    }}
                  >
                    <MenuItem
                      icon={<FiEdit3 />}
                      onClick={() => {
                        const [selectedId] = window.tree.selectedIds;
                        if (selectedId) {
                          window.tree.focus(selectedId);
                          setTimeout(() => {
                            return window.tree.edit(selectedId).catch((e) => {
                              console.error(e);
                            });
                          });
                        }
                      }}
                    >
                      {EnterpriseStrings.address_book.context_menu.edit}
                    </MenuItem>
                    <MenuItem
                      icon={<FiPlus />}
                      onClick={() => {
                        window.tree
                          .create()
                          .then(() => {})
                          .catch((e) => {
                            console.error(e);
                          });
                      }}
                    >
                      {EnterpriseStrings.address_book.context_menu.add_org}
                    </MenuItem>
                    <MenuItem
                      icon={<FiPlus />}
                      onClick={() => {
                        setOrg(false);
                        alertDisclosure.onOpen();
                      }}
                    >
                      {EnterpriseStrings.address_book.context_menu.add_user}
                    </MenuItem>
                    <MenuItem
                      icon={<FiTrash2 />}
                      onClick={() => {
                        const [selectedId] = window.tree.selectedIds;
                        if (selectedId) {
                          window.tree.delete(selectedId);
                        }
                      }}
                    >
                      {EnterpriseStrings.address_book.context_menu.delete}
                    </MenuItem>
                  </MenuList>
                </Menu>
                <Tree
                  // initialData={addressBooks}
                  data={addressBooks}
                  ref={globalTree}
                  searchTerm={term}
                  onActivate={(node) => {
                    console.log("onActivate:", node);
                    setContacts(node.data.members);
                    setFilteredContacts(node.data.members);
                    if (
                      contactFilterInputRef &&
                      contactFilterInputRef.current
                    ) {
                      contactFilterInputRef.current.value = "";
                    }
                    setParentNode(node);
                  }}
                  onSelect={(node) => {
                    if (node && node.length > 0) {
                      setParentNode(node[0]);
                    }
                    console.log("onSelect:", node);
                  }}
                  openByDefault={true}
                  onCreate={onCreate}
                  onRename={onRename}
                  onMove={onMove}
                  onDelete={onDelete}
                  onContextMenu={(e) => {
                    e.preventDefault(); // prevent the default behaviour when right clicked

                    setIsOpen(true);
                    console.log("err");
                    const menu = document.getElementById(
                      "menu-list-tree-context-menu"
                    );
                    if (!menu) {
                      return;
                    }

                    const popper = menu.parentElement;

                    const x = e.clientX;
                    const y = e.clientY;

                    Object.assign(popper.style, {
                      top: `${y}px`,
                      left: `${x}px`,
                    });
                  }}
                  // childrenAccessor={(node) => {
                  //   console.log(node.items)
                  //   return node.items;
                  // }}
                >
                  {Node}
                </Tree>
                {/* <Text>
                <strong>Short Keys</strong>
                </Text> */}
                {EnterpriseStrings.address_book.notes}
              </>
            )}

            {/* <CheckboxTree
                      nodes={treeNodes}
                      checked={checked}
                      expanded={expanded}
                      onCheck={checked => setChecked( checked )}
                      onExpand={expanded => setExpanded( expanded )}
                      /> */}
          </Box>
          {/* <Box flex='1'> */}

          <TableContainer flex="1">
            {/* {parentNode && ( */}
            <Flex justifyContent={"space-between"} p={3}>
              <InputGroup
                onChange={(e) => {
                  filterContacts(e.target.value);
                }}
                mr={2}
              >
                <Input
                  disabled={!parentNode}
                  ref={contactFilterInputRef}
                  placeholder={EnterpriseStrings.address_book.filter_contact}
                />
                <InputRightAddon>
                  <FiSearch />
                </InputRightAddon>
              </InputGroup>
              <Box>
                <IconButton
                  disabled={isSaving}
                  icon={<FiRotateCcw />}
                  colorScheme={"red"}
                  variant="outline"
                  aria-label="Discard"
                  title="Discard"
                  mr={3}
                  onClick={() => {
                    setLoading(true);
                    setAddressBooks(null);
                    setOriginVersion(0);
                    setSaving(false);
                    setContacts(null);
                    setFilteredContacts(null);
                    setParentNode(null);
                    setOrg(true);
                    getAddressBook()
                      .then(() => {
                        setLoading(false);
                      })
                      .catch((e) => {
                        console.error(e);
                        showError(e);
                        setLoading(false);
                      });
                  }}
                  spacing={{ base: "6", md: "6" }}
                />
                <IconButton
                  disabled={!addressBookIsChanged()}
                  isLoading={isSaving}
                  aria-label="Save"
                  title="Save"
                  icon={<FiSave />}
                  colorScheme={"green"}
                  variant="outline"
                  mr={3}
                  onClick={() => {
                    setSaving(true);
                    saveAddressBook()
                      .then(() => {
                        setSaving(false);
                        // disclosure.onClose();
                        showSuccess("Saved");
                      })
                      .catch((e) => {
                        setSaving(false);
                        console.error(e);
                        showError(e);
                      });
                  }}
                  spacing={{ base: "6", md: "6" }}
                />
                <Menu isLazy>
                  <MenuButton
                    as={IconButton}
                    variant="outline"
                    aria-label="Add"
                    title="Add"
                    disabled={!parentNode}
                    icon={<FiPlus />}
                    colorScheme={"gray"}
                  />
                  <MenuList>
                    <MenuItem
                      icon={<FiUsers />}
                      onClick={() => {
                        setOrg(true);
                        alertDisclosure.onOpen();
                      }}
                    >
                      {EnterpriseStrings.address_book.create_org}
                    </MenuItem>
                    <MenuItem
                      icon={<FiUser />}
                      onClick={() => {
                        setOrg(false);
                        alertDisclosure.onOpen();
                      }}
                    >
                      {EnterpriseStrings.address_book.create_contact}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Box>
            </Flex>
            {/* )} */}
            <Table mt={4} variant="simple">
              {/* <TableCaption>
                  <Text>
                    Accounts
                  </Text>
              </TableCaption> */}
              <Thead>
                <Tr>
                  {/* <Th>{EnterpriseStrings.address_book.contacts.col_select}</Th> */}
                  <Th>{EnterpriseStrings.address_book.contacts.col_name}</Th>
                  <Th>{EnterpriseStrings.address_book.contacts.col_email}</Th>
                  <Th>{EnterpriseStrings.address_book.contacts.col_addr}</Th>
                  <Th>{EnterpriseStrings.address_book.contacts.col_ops}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {/* textDecoration={item.status === 0 ? 'none': 'line-through red'} */}
                {filteredContacts &&
                  filteredContacts.length > 0 &&
                  filteredContacts.map((contact) => {
                    return (
                      <Tr key={contact.addr}>
                        {/* <Td><Checkbox defaultChecked={false} colorScheme={"green"} onChange={(e) => {
                      }} /></Td> */}
                        {/* <Td><Checkbox /></Td> */}
                        <Td>{contact.name}</Td>
                        <Td>{contact.email}</Td>
                        <Td>{contact.addr}</Td>
                        <Td>
                          <IconButton
                            background={"none"}
                            color="red"
                            icon={<FiTrash2 />}
                            onClick={() => {
                              deleteContact(contact)
                                .then()
                                .catch((e) => {
                                  console.error(e);
                                  showError(e);
                                });
                            }}
                          />
                          <CopyToClipboard
                            text={`'${contact.name}' <${contact.addr}>`}
                            onCopy={() => {
                              showSuccess("Copied");
                            }}
                          >
                            <IconButton
                              background={"none"}
                              color="gray"
                              icon={<FiCopy />}
                              // onClick={() => {
                              //   copy(`'${contact.name}' <${contact.addr}>`, {onCopy: () =>{
                              //     showSuccess('Copied')
                              //   }});
                              // }}
                            />
                          </CopyToClipboard>
                          <IconButton
                            background={"none"}
                            color="green"
                            icon={<FiSend />}
                            onClick={() => {
                              const addr = window.mailService.parseAddress(
                                contact.name,
                                contact.addr
                              );
                              setSelectedRecipients([addr]);
                              composeDisclosure.onOpen();
                            }}
                          />
                        </Td>
                      </Tr>
                    );
                  })}

                {(!filteredContacts || filteredContacts.length === 0) && (
                  <Tr>
                    <Td colSpan={4} align="center" w={"100%"} maxW={"100%"}>
                      <VStack width={"100%"}>
                        <Text
                          color={"rgb(74, 85, 104)"}
                          width={"100%"}
                          textAlign="center"
                          algin={"center"}
                          fontWeight={"bold"}
                          fontSize={"md"}
                        >
                          {EnterpriseStrings.address_book.contacts.empty}
                        </Text>
                        {/* <Text
                            color="gray"
                            fontSize="sm"
                            width={"100%"}
                            algin={"center"}
                            textAlign="center"
                          >
                            {"Empty"}
                          </Text> */}
                      </VStack>
                    </Td>
                  </Tr>
                )}
              </Tbody>

              {/* <Tfoot>
                <Tr>
                  <Th colSpan={5}>
                    <Flex justify={'flex-end'} mt={4} fontWeight={'bold'}>
                      <Text align="right" textAlign={'right'}>
                          asdasdasdas
                      </Text>
                    </Flex>
                  </Th>
                </Tr>
              </Tfoot> */}
            </Table>
          </TableContainer>
          {/* </Box> */}
        </Flex>
      )}
    </>
  );
};

function Node({ node, style, dragHandle }) {
  const Icon = node.level === 0 ? (node.isOpen ? FiBookOpen : FiBook) : FiUsers;

  return (
    <Flex
      alignItems={"center"}
      ref={dragHandle}
      style={style}
      cursor={"pointer"}
      // onMouseOver={() => {
      //   console.log('Mouse Over Node:', node);
      // }}
      // onMouseLeave={() => {
      //   console.log('Mouse Leave Node:', node);
      // }}
      background={
        node.state.isSelected && !node.isEditing ? "lightGray" : "none"
      }
      // onClick={() => node.isInternal && node.toggle()}
      justifyContent={"space-between"}
      onContextMenu={(e) => {
        if (!node.state.isSelected) {
          // e.stopPropagation();
          // e.preventDefault();
          node.tree.select(node.id);
        }
      }}
    >
      <Flex alignItems={"center"}>
        {/* {!node.state.isLeaf && node.isOpen &&
      <FiChevronDown />
      }
      {!node.state.isLeaf && !node.isOpen &&
      <FiChevronRight />
      } */}
        {node.isOpen ? <FiChevronDown /> : <FiChevronRight />}
        <Icon pl={2} />
        {node.isEditing && (
          <Input
            h={"24px"}
            pl={1}
            autoFocus={true}
            defaultValue={node.data.name}
            onFocus={(e) => e.currentTarget.select()}
            onBlur={() => node.reset()}
            onKeyDown={(e) => {
              if (e.key === "Escape") node.reset();
              if (e.key === "Enter") node.submit(e.currentTarget.value);
            }}
          />
        )}
        {!node.isEditing && <Text pl={1}>{node.data.name}</Text>}
      </Flex>
      {/* <Flex hidden={!node.state.isSelected}>
        
        <Menu isLazy>
          <MenuButton onClick={(e) => {
            e.stopPropagation();
            console.log('node state:', node.state);
          }}>
          <FiMoreVertical size={14} cursor={'pointer'} />
          </MenuButton>
          <MenuList>
            <MenuItem icon={<FiUsers />}>Add Group</MenuItem>
            <MenuItem icon={<FiUser />}>Add Contact</MenuItem>

            <MenuDivider />
            <MenuItem icon={<FiEdit3 />} onClick={() => {
              node.edit();
            }}>Edit Group</MenuItem>
            <MenuItem icon={<FiTrash2 />}>Delete Group</MenuItem>
          </MenuList>
        </Menu>
      </Flex> */}
      {/* <span>{node.isEditing ? <Input node={node} /> : node.data.name}</span> */}
      {/* <span>{node.data.unread === 0 ? null : node.data.unread}</span> */}
    </Flex>
  );

  // return (
  //   <div ref={ref} style={styles.row}>
  //     <div style={styles.indent}>
  //       {data.name}
  //     </div>
  //   </div>
  // )
}

export default AddressBookContent;
