import {
    Text,
    Button,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    // useDisclosure,
    Checkbox,
    HStack,
    VStack,
    Menu,
    MenuButton,
    Box,
    useColorModeValue,
    MenuList,
    MenuItem,
    Divider,
    FormLabel,
    InputGroup,
    Input,
    Heading,
    InputRightElement,
    IconButton,
    Progress,
    Skeleton,
    Link,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    PinInput,
    PinInputField,
    Spinner,
    Image,
    List,
    ListItem, 
    Avatar,
    Center
    // IconButton,
  } from '@chakra-ui/react';
  
import {CopyToClipboard} from 'react-copy-to-clipboard';

import toast, { Toaster } from 'react-hot-toast';

import React, { useEffect, useState } from 'react';
import { /*FaArrowAltCircleDown,*/ FaArrowAltCircleUp, FaEthereum, FaEye, FaEyeSlash, FaFileSignature, FaRegCopy } from 'react-icons/fa';
import { /*FiDownload,FiCreditCard,*/  FiDownloadCloud, FiMoreHorizontal, /*FiSettings,*/ FiPlus, FiLifeBuoy, FiChevronDown, FiEdit3, FiSave, FiX } from 'react-icons/fi';
import { ClientError, showError } from '../common/errors';
import AFWallet from './AFWallet';

import zxcvbn from 'zxcvbn';
import AFWalletRPCError from './AFWalletRPCError';
// import Strings from '../config/Strings';
// import {ReactComponent as MetaMaskIcon} from '../components/metamask.svg';
import WalletStrings from './WalletStrings';
import { ethers } from 'ethers';
import { AiOutlineTransaction } from 'react-icons/ai';
import {EthereumChains} from '../utils/Types'

import CreateWalletAlert from './CreateWalletAlert';
import ImportWalletAlert from './ImportWalletAlert';

import {ResetAlert} from "../components/ConfirmationButton"

import AppConfig from '../config/AppConfig'
import * as CurrencyUtils from '../utils/CurrencyUtils'
import ContentLoader from 'react-content-loader';
import { useAuth } from '../icp/authContext';
const WalletListLoader = (props) => (
    <ContentLoader 
      speed={2}
      width={400}
      height={150}
      viewBox="0 0 400 150"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      {...props}
    >
      <circle cx="10" cy="20" r="8" /> 
      <rect x="25" y="15" rx="5" ry="5" width="300" height="10" /> 
      <circle cx="10" cy="50" r="8" /> 
      <rect x="25" y="45" rx="5" ry="5" width="300" height="10" /> 
      <circle cx="10" cy="80" r="8" /> 
      <rect x="25" y="75" rx="5" ry="5" width="300" height="10" /> 
      <circle cx="10" cy="110" r="8" /> 
      <rect x="25" y="105" rx="5" ry="5" width="300" height="10" />
    </ContentLoader>
  )

export const WalletUIStatus = {
    Choose: 0,
    Connect: 1,
    Wallet: 2,
    SendTransaction: 3,
    Sign: 4,
    Backup: 5,
    ChangePINCode: 6,
    ResetPINCode: 7,
    Migrate: 8,
    ChangeWeb3StorageAPIToken: 9,
    Unlock: 10,
    VerifyPinCode: 11,
    SwitchWallet: 12,
};

function CoverConfirmationAlert({disclosure, message, onCompleted}) {
    
    return (
        <AlertDialog
          isOpen={disclosure.isOpen}
          onClose={disclosure.onClose}
          closeOnOverlayClick={false}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {WalletStrings.ui.override.title}
              </AlertDialogHeader>
  
              <AlertDialogBody>
              <Text>{message || WalletStrings.ui.override.message}</Text>
              </AlertDialogBody>
  
              <AlertDialogFooter>
                {!(window.wallet.pendingBackup && window.wallet.pendingBackup.isReBackup) &&
                <Button colorScheme={"green"} onClick={() => {
                    disclosure.onClose(); 
                    onCompleted(false);
                }}>{WalletStrings.ui.override.button.no}</Button>
                }
                <Button ml={4} colorScheme={"red"} onClick={() => {
                    disclosure.onClose(); 
                    onCompleted((window.wallet.pendingBackup && window.wallet.pendingBackup.isReBackup) ? false : true);
                }}>{WalletStrings.ui.override.button.yes}</Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
    )
}

function UnlockPanel({wallet, onComplete}) {
    const [isInitializing, setInitializing] = useState(false);
    const resetDisclosure = useDisclosure();
    const {logout} = useAuth();
    /*
    const [password, setPassword] = useState(null); 
    const [showPassword, setShowPassword] = useState(false);
    const colors = ['gray', 'red', 'pink', 'orange', 'green'];

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    function verifyPassword(e) {
        const password = e.target.value || '';
        if (password === '') {
            setPassword(null)
            return;
        }
        setPassword(password);
    }

    function unlock() {
        setInitializing(false);
        wallet.unlock(password).then(() => {
            setInitializing(true);
            onComplete();
        }).catch(e => {
            console.error(e);
            setInitializing(false);
            showError(e);
        })
    }

    function cancel() {
        onComplete(AFWalletRPCError.unauthorized);
    }
    */

    // // TODO: 
    // function reset() {

    //     // W3UIWeb3Storage.keyring = {state, actions};
    //     localStorage.clear();
    //     window.wallet.reset().then(() => {
    //       window.forceQuit = true;
    //       // window.location.reload();
    //       setTimeout(function(){
    //         window.location.reload(true);
    //       });
    //     }).catch((e) => {
    //       console.error(e);
    //       showError(e);
    //     });
    // }
    /*
    const reset = () => {
        window.mailService.reset().then(() => {
          return window.wallet.reset();
        }).then(() => {
          window.forceQuit = true;
          // window.location.reload();
          setTimeout(function(){
            window.location.reload(true);
          });
        }).catch((e) => {
          console.error(e);
          showError(e);
        });
    }
    */

    const resetCurrent = () => {
        window.mailService.reset(true).then(() => {
            if (logout) {
                return logout().then(() => {
                  return window.wallet.reset();
                });
              }
            return window.wallet.reset(true);
        }).then(() => {
            window.forceQuit = true;
            // window.location.reload();
            setTimeout(function(){
              window.location.reload(true);
            });
          }).catch((e) => {
            console.error(e);
            showError(e);
          });
    }

    return (
        <>
        <ResetAlert disclosure={resetDisclosure} onClick={() => {
            resetCurrent().then(() => {}).catch(e => {
                showError(e);
            });
        }} />

        <VStack>
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    {WalletStrings.ui.unlock.title}
            </Heading>
            <Flex width={"100%"} direction={"column"} align="left">
                {/* <FormLabel htmlFor='country'>PIN Code</FormLabel> */}
                {/* <InputGroup>
                    <Input type={showPassword ? 'text' : 'password'} onChange={verifyPassword} />
                    <InputRightElement>
                    {showPassword &&
                    <IconButton icon={<FaEye />} onClick={togglePassword} />
                    }
                    {!showPassword &&
                    <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
                    }
                    </InputRightElement>
                </InputGroup> */}

                <HStack mt={10} align={"center"} justifyContent={"center"}>
                <PinInput type='number' autoFocus={true} mask={true} onComplete={(e) => {
                    const password = e || '';
                    console.log('PinInput: ', password);
                    // setPassword(password);
                    setInitializing(false);
                    wallet.unlock(password).then(() => {
                        setInitializing(true);
                        onComplete(password, null);
                    }).catch(e => {
                        console.error(e);
                        setInitializing(false);
                        showError(e);
                    })
                }}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                </PinInput>
                {/* {showPassword &&
                <IconButton icon={<FaEye />} onClick={togglePassword} />
                }
                {!showPassword &&
                <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
                } */}
                </HStack>
            </Flex>
            <Flex width={"100%"} direction={"column"} align="center">
                <Text mt={2} fontSize={"10px"} color="gray.600">
                {WalletStrings.ui.unlock.note}
                </Text>
            </Flex>

            <Flex width={"100%"} direction={"column"} align="center">
                <Text mt={2} mb={2} color="red.600">
                    OR
                </Text>

                <Link href={"#"} color={"#00aaff"} onClick={() => {
                    // reset()
                    resetDisclosure.onOpen();
                }}>{WalletStrings.ui.unlock.discard}</Link>
                <Text mt={1} fontSize={"10px"} color="gray.600">
                {WalletStrings.ui.unlock.never_used}
                </Text>
            </Flex>

            {isInitializing &&
            <Flex width={"100%"} direction={"column"} align="center">
                <HStack mt={5} align="center">
                    <Spinner size={"sm"} color="orange" /> 
                    <Text fontSize={"sm"} color="orange">
                    {WalletStrings.ui.unlock.unlocking}
                    </Text>
                </HStack>
            </Flex>
            }
            {/* <Button
                fontFamily={'heading'}
                mt={20}
                w={'full'}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                isLoading={isInitializing}
                _hover={{
                    bgGradient: 'linear(to-r, red.400,pink.400)',
                    boxShadow: 'xl',
                }}
                onClick={unlock}>
                Unlock
            </Button> */}

            {/* <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgColor={"gray.200"}
                color={'black'}
                onClick={() => {
                    cancel();
                }}>
            Back
            </Button> */}
        </VStack>
        </>
    )
}

function BackupWalletPanel({wallet}) {
    const [showPassword, setShowPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(null);
    const [entropyStrength, setEntropyStrength] = useState(null);
    
    const [isInitializing, setInitializing] = useState(false);
    const [password, setPassword] = useState(null); 
    const [entropySalt, setEntropySalt] = useState(null); 
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [confirmed, setConfirmed] = useState(false); 
    const [backupStatus, setBackupStatus] = useState(0);// 0 get start, 1 backup, 2 finish backing up.
    const colors = ['gray', 'red', 'pink', 'orange', 'green'];
    const [errorMessage, setErrorMessage] = useState(null);
    const [cryptonMessage, setCryptonMessage] = useState(null);
    const disclosure = useDisclosure({
        onOpen: ()=>{
        }
    });

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    function verifyFormItems(confirmPassword, password, entropyStrength, passwordStrength) {
        if (!passwordStrength) {
            // return false;
            setConfirmed(false);
            return;
        }
        
        if (!entropyStrength) {
            // return false;
            setConfirmed(false);
            return;
        }

        const verified = (confirmPassword === password);
        if (!verified) {
            // return false;
            setConfirmed(false);
            return;
        }

        if(entropyStrength.score < 3 || passwordStrength.score < 4) {
            // return true;
            setConfirmed(false);
            return;
        }

        setConfirmed(true);
    }

    function verifyPassword(e) {
        const password = (e.target.value || '').trim();
        if (password === '') {
            setPasswordStrength(null);
            setPassword(null)
            setConfirmed(false);
            return;
        }
        if (wallet.pendingBackup && wallet.pendingBackup.isReBackup) {
            setPassword(password);
            setConfirmed(true);
        } else {
            setPassword(password);
            // setConfirmed((confirmPassword === password) &&  entropySalt && entropySalt.length > 0);
            const r = zxcvbn(password);
            setPasswordStrength(r);
            verifyFormItems(confirmPassword, password, entropyStrength, r);
        }
    }

    function verifyEntropySalt(e) {

        if (wallet.pendingBackup && wallet.pendingBackup.isReBackup) {
            setEntropyStrength(null);
            return;
        }

        const entropySalt = (e.target.value || '').trim();
        if (entropySalt === '') {
            setEntropyStrength(null);
            return;
        }
        const r = zxcvbn(entropySalt);
        setEntropyStrength(r);
        console.log('xxxxx:', r);

        verifyFormItems(confirmPassword, password, r, passwordStrength);
    }

    function verifyConfirmPassword(e) {
        const confirmPassword = (e.target.value || '').trim();
        const verified = (confirmPassword === password);
        setConfirmPassword(confirmPassword);
        // setConfirmed(verified && entropySalt && entropySalt.length > 0);

        verifyFormItems(confirmPassword, password, entropyStrength, passwordStrength);


        if (confirmPassword.length === password.length && !verified) {
            setErrorMessage(WalletStrings.ui.backup.passphrase_not_match);
        } else {
            setErrorMessage(null);
        }
    }

    async function backupWallet(covered=false) {
        try {
            // salt, password, apiToken, force=false, local=false
            if (password.length > 500) {
                showError('The passphrase exceeds the length limit');
                return;
            }
            if (entropySalt.length > 500) {
                showError('The entropy salt exceeds the length limit');
                return;
            }

            setInitializing(true);
            await wallet.backup(entropySalt, password, covered);
            setInitializing(false);
            // wallet.disclosure.onClose();

            setBackupStatus(2);
        } catch(e) {
            setInitializing(false);
            console.error(e);
            if (e.code === ClientError.CODE_CRYPTON_EXISTED || e.code === ClientError.CODE_CRYPTON_NOT_EXISTED) {

                setCryptonMessage(e.message);
                disclosure.onOpen();
            } else {
                showError(e);
            }
        }
    }

    return (

        <VStack>

                    
            <Image width={"72px"} src="crypton-320.png"  />
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                alignContent={"center"}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    <Text mt={5}>
                    {WalletStrings.ui.backup.title}
                    </Text>
            </Heading>
            {(backupStatus === 0 && !window.appConfig.isNewCreatedUser) &&
            <>
            <Flex width={"100%"} direction={"column"} align="left">
                {/* <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                {WalletStrings.ui.backup.backup_required}
                </Text> */}
                <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                {(wallet.pendingBackup && wallet.pendingBackup.isReBackup) ? WalletStrings.ui.backup.description_3 : WalletStrings.ui.backup.description_1}
                </Text>
                {wallet.pendingBackup && wallet.pendingBackup.rename &&
                <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                {WalletStrings.ui.backup.description_6}&nbsp;<Link href="https://ai-fi.cc/help.html#pleximail-running-sessions" target="_blank" color="#00aaff">Learn more.</Link>
                </Text>
                }

                {(!wallet.pendingBackup || !wallet.pendingBackup.rename) &&
                <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                    
                {(wallet.pendingBackup && wallet.pendingBackup.isReBackup) ? WalletStrings.ui.backup.description_5: WalletStrings.ui.backup.description_2}&nbsp;<Link href="https://ai-fi.cc/help.html#pleximail-running-sessions" target="_blank" color="#00aaff">Learn more.</Link>

                </Text>
                }
            </Flex>
            <Flex width={"100%"} direction={"column"} align="center">
                <Button w={"100%"} mt={"20px"} colorScheme={"green"} onClick={() => { 
                    
                    setBackupStatus(1);
                }}>{WalletStrings.ui.backup.next}</Button>
            </Flex>
            </>
            }

            {(backupStatus === 0 && window.appConfig.isNewCreatedUser) &&
            <>
            <Flex width={"100%"} direction={"column"} align="left">
                {/* <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                {WalletStrings.ui.backup.backup_required}
                </Text> */}
                <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                {WalletStrings.ui.backup.description_3}
                </Text>
                <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                {WalletStrings.ui.backup.description_4}
                </Text>
            </Flex>
            <Flex width={"100%"} direction={"column"} align="center">
                <Button w={"100%"} mt={"20px"} colorScheme={"green"} onClick={() => { 
                    
                    setBackupStatus(1);
                }}>{WalletStrings.ui.backup.next}</Button>
            </Flex>
            </>
            }
            {backupStatus === 1 && <>
            <Flex width={"100%"} direction={"column"} align="left">
                <FormLabel htmlFor='country'>
                {WalletStrings.ui.backup.recovery_passphrase}
                </FormLabel>
                <InputGroup>
                <Input
                    autoComplete="off" placeholder={WalletStrings.ui.backup.recovery_passphrase_placeholder} type={showPassword ? 'text' : 'password'} onChange={verifyPassword} />
                
                <InputRightElement>
                {showPassword &&
                <IconButton icon={<FaEye />} onClick={togglePassword} />
                }
                {!showPassword &&
                <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
                }
                </InputRightElement>
                </InputGroup>
                {(passwordStrength) &&
                <>
                    <Progress mt={2} value={(passwordStrength.score + 1) * 20} colorScheme={colors[passwordStrength.score]} size='xs' />
                    {/* <Text fontSize={"12px"} align={"right"} color={colors[passwordStrength.score]}>{passwordStrength.feedback.warning}</Text> */}
                    <Text fontSize={"12px"} align={"right"} color={colors[passwordStrength.score]}>{(passwordStrength.feedback.warning && passwordStrength.feedback.warning.length > 0) ? passwordStrength.feedback.warning : passwordStrength.crack_times_display.online_no_throttling_10_per_second}</Text>
                </>
                }
                <Text color="gray" mt={2}>
                {WalletStrings.ui.backup.passphrase_note}
                </Text>
            </Flex>
            <Flex width={"100%"} direction={"column"} align="left" mt={4} hidden={ (passwordStrength === null ? true : (passwordStrength.score < 4)) }>
            {/* <Flex width={"100%"} direction={"column"} align="left" mt={4}> */}
                <FormLabel htmlFor='country'>
                {WalletStrings.ui.backup.confirm_recovery_passphrase}
                </FormLabel>
                <InputGroup>
                <Input
                    autoComplete="off" type={showPassword ? 'text' : 'password'} onChange={verifyConfirmPassword} />
                <InputRightElement>
                {showPassword &&
                <IconButton icon={<FaEye />} onClick={togglePassword} />
                }
                {!showPassword &&
                <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
                }
                </InputRightElement>
                </InputGroup>
            </Flex>

            <Flex width={"100%"} direction={"column"} align="left">
                <FormLabel htmlFor='country' mt={4}>
                {WalletStrings.ui.backup.entropy_salt}
                </FormLabel>
                <InputGroup>
                <Input
                    autoComplete="off" placeholder={WalletStrings.ui.backup.entropy_salt_placeholder} type={'text'} defaultValue={entropySalt} onChange={(e) => {
                    setEntropySalt(e.target.value);
                    
                    verifyEntropySalt(e);
                }} />

                </InputGroup>

                {(entropyStrength) &&
                <>
                    <Progress mt={2} value={(entropyStrength.score + 1) * 20} colorScheme={colors[entropyStrength.score]} size='xs' />
                    <Text fontSize={"12px"} align={"right"} color={colors[entropyStrength.score]}>{(entropyStrength.feedback.warning && entropyStrength.feedback.warning.length > 0) ? entropyStrength.feedback.warning : entropyStrength.crack_times_display.online_no_throttling_10_per_second}</Text>
                </>
                }
                <Text color="gray" mt={2}>
                {WalletStrings.ui.backup.entropy_salt_note}
                </Text>

            </Flex>

            {(errorMessage && errorMessage.length > 0) &&
            <Flex width={"100%"} direction={"column"} align="center">
                <HStack mt={5} align="center">
                    <Text fontSize={"sm"} color="orange">
                    {errorMessage}
                    </Text>
                </HStack>
            </Flex>
            }

            <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                colorScheme={"green"} 
                // bgGradient="linear(to-r, red.400,pink.400)"
                // color={'white'}
                isDisabled={!confirmed}
                isLoading={isInitializing}
                // _hover={{
                //     bgGradient: 'linear(to-r, red.400,pink.400)',
                //     boxShadow: 'xl',
                // }}
                onClick={() => {
                    backupWallet(false);
                }}>
                {(wallet.pendingBackup && wallet.pendingBackup.isReBackup) ? WalletStrings.ui.backup.update : WalletStrings.ui.backup.backup }
            </Button>

            <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                colorScheme={"gray"} 
                // bgGradient="linear(to-r, red.400,pink.400)"
                // color={'white'}
                isDisabled={isInitializing}
                // _hover={{
                //     bgGradient: 'linear(to-r, red.400,pink.400)',
                //     boxShadow: 'xl',
                // }}
                onClick={() => {
                    // setBegin(false);
                    setBackupStatus(0);
                }}>
                {WalletStrings.ui.backup.back}
            </Button>

            </>}
            {backupStatus === 2 && 
            <>
            <Flex width={"100%"} direction={"column"} align="center">
                <Text mt={"20px"} fontWeight={"bold"} color={"green"}>
                {WalletStrings.ui.backup.succeed}
                </Text>
                {(wallet.pendingBackup && wallet.pendingBackup.message) &&
                <Text mt={"20px"} fontSize="sm" color={"gray.600"}>
                {wallet.pendingBackup.message}
                </Text>
                }
            </Flex>
            <Flex width={"100%"} direction={"column"} align="center">
                <Button w={"100%"} mt={"20px"} colorScheme={"green"} onClick={() => { 
                    const close = wallet.pendingBackup.close
                    const rename = wallet.pendingBackup.rename
                    if (wallet.pendingBackup) {
                        wallet.pendingBackup.resolve();
                        wallet.pendingBackup = null;
                    }
                    if (close) {
                        wallet.disclosure.onClose();
                    } else {
                        if (!rename) {
                            wallet.showUI().then(() => {}).catch(e => {});
                        }
                    }
                    // setInitializing(true);
                    // setTimeout(() => {
                    //     setInitializing(false);
                    //     wallet.disclosure.onClose();
                    // }, 300)
                }}>{WalletStrings.ui.backup.next}</Button>
            </Flex>
            </>
            }
            <CoverConfirmationAlert disclosure={disclosure} message={cryptonMessage} onCompleted={(covered) => {
                if (covered) {
                    backupWallet(covered);
                } else {
                    // showError(WalletStrings.ui.backup.token_exists);
                }
            }} />
            {/* <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgColor={"gray.200"}
                color={'black'}
                onClick={() => {
                    
                }}>
            Back
            </Button> */}
        </VStack>
    )
}

function ConfigWalletPanel({setChainId, wallet, status, setRpcProviderType, setShouldConfig}) {
    const [showPassword, setShowPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(null);//eslint-disable-line
    const [isInitializing, setInitializing] = useState(false);
    const [password, setPassword] = useState(null); 
    const [entropySalt, setEntropySalt] = useState(null); 
    const [confirmed, setConfirmed] = useState(false); 
    const [configStatus, setConfigStatus] = useState(window.isOneClickResume ? 2 : 0);
    const colors = ['gray', 'red', 'pink', 'orange', 'green'];//eslint-disable-line
    const [errorMessage, setErrorMessage] = useState(null);
    
    // const [isChecking, setChecking] = useState(true);
    // const [isRegistered, setRegistered] = useState(false);

    useEffect(() => {
        // setChecking(true);
        // setRegistered(false);

        // window.plexiMailService.isRegistered().then(isRegistered => {
        //     setChecking(false);
        //     setRegistered(isRegistered);
        // }).catch(e => {
        //     setChecking(false);
        //     showError(e);
        // })
    }, [])

    const togglePassword = () => {
        setShowPassword(!showPassword);
    }

    function verifyPassword(e) {
        const password = (e.target.value || '').trim();
        if (password === '') {
            setPasswordStrength(null);
            setPassword(null)
            return;
        }
        // if (setupWizardState === SetupWizardState.EnterPassword) {
        //     setPassword(password);
        //     return;
        // }
        if (configStatus === 1) {
            const r = zxcvbn(password);
            setPasswordStrength(r);
        } else if (configStatus === 2) {
            setConfirmed(password && password.length > 0 && entropySalt && entropySalt.length > 0);
        }
        setPassword(password);
    }

    function confirmPassword(e) {//eslint-disable-line
        const confirm = (e.target.value || '').trim();

        const verified = (confirm === password);
        setConfirmed(verified);
        
        if (confirm.length === 6 && !verified) {
            showError('The two PIN code you typed do not match.')
        }
    }

    async function createWallet() {
        try {
            setInitializing(true);
        
            await wallet.create(password);
            await wallet.setRpcProviderType(AFWallet.RPCProviderType.AFWallet);    
            setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
            // setShouldConfig(false);
            if (status === WalletUIStatus.Choose) {
                wallet.disclosure.onClose();
                wallet.onRpcProviderTypeChanged(true);
            }
            setInitializing(false);
        } catch(e) {
            await wallet.setRpcProviderType(AFWallet.RPCProviderType.Unset);
            setRpcProviderType(AFWallet.RPCProviderType.Unset);
            setInitializing(false);
            console.error(e);
            showError(e);
        }
    }
    async function recoverWallet() {
        try {

            if (password.length > 500) {
                showError('The passphrase exceeds the length limit');
                return;
            }
            if (entropySalt.length > 500) {
                showError('The entropy salt exceeds the length limit');
                return;
            }

            setInitializing(true);
            await wallet.recover(entropySalt, password);
            // await wallet.setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
            // setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
            setInitializing(false);
        } catch(e) {
            // await wallet.setRpcProviderType(AFWallet.RPCProviderType.Unset);
            // setRpcProviderType(AFWallet.RPCProviderType.Unset);
            setInitializing(false);
            console.error(e);
            showError(e);
        }
    }

    // if (isChecking) {
    //     return (<VStack>
    //         <Heading
    //             color={'gray.800'}
    //             lineHeight={1.1}
    //             fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
    //                 {WalletStrings.ui.config.title}
    //         </Heading>
    //         <Flex width={"100%"} direction={"column"} align="left">
    //             <HStack>
    //                 <Spinner size={"sm"} color={"orange"} />
    //                 <Text color={"orange"}>
    //                 {"Checking..."}
    //                 </Text>
    //             </HStack>
    //         </Flex>
    //     </VStack>)
    // }
    // if (configStatus === 2 && !isRegistered) {
    //     return (<VStack>
    //         <Heading
    //             color={'gray.800'}
    //             lineHeight={1.1}
    //             fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
    //                 {"Unable to recover your PlexiMail"}
    //         </Heading>
    //         <Flex width={"100%"} direction={"column"} align="left">
    //         You have not registered the PlexiMail with the current Internet Identity.
    //         </Flex>

    //         <Flex width={"100%"} direction={"column"} align="left">
    //             <Button w={"100%"} mt={"20px"} colorScheme={"gray"} onClick={() => {
    //                 setRpcProviderType(AFWallet.RPCProviderType.Unset);
    //                 setShouldConfig(false);

    //                 // wallet.setRpcProviderType(AFWallet.RPCProviderType.Unset).then(() => {
    //                 //     setRpcProviderType(AFWallet.RPCProviderType.Unset);
    //                 //     setShouldConfig(false);
    //                 // }).catch(e => {
    //                 //     showError(e);
    //                 // });
    //             }}>{WalletStrings.ui.config.back}</Button>
    //         </Flex>
    //     </VStack>)
    // }
    return (
        <>
        {configStatus === 0 && // recovery or create new
        <VStack>
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    {WalletStrings.ui.config.title}
            </Heading>
            <Flex width={"100%"} direction={"column"} align="left">
                <Button w={"100%"} mt={"20px"} colorScheme={"green"} onClick={() => { 
                    setConfigStatus(3);
                }}>{WalletStrings.ui.config.create_new}</Button>
            </Flex>

            {window.plexiMailService && window.plexiMailService.alreadyRegistered &&
            <>
            <Flex width={"100%"} direction={"column"} align="left">
                <Text color="orange">
                Attention: The Internet Identity you have entered is already associated with a PlexiMail mailbox. Selecting "Create New" will erase all content in the existing mailbox.
                </Text>
            </Flex>
            <Flex width={"100%"} direction={"column"} align="left">
                <Button w={"100%"} mt={"20px"} colorScheme={"gray"} onClick={() => { 
                    setConfigStatus(2);
                }}>{WalletStrings.ui.config.recover}</Button>
            </Flex>
            </>
            }
            <Flex width={"100%"} direction={"column"} align="left">
                <Button w={"100%"} mt={"20px"} colorScheme={"gray"} onClick={() => {
                    setRpcProviderType(AFWallet.RPCProviderType.Unset);
                    setShouldConfig(false);

                    // wallet.setRpcProviderType(AFWallet.RPCProviderType.Unset).then(() => {
                    //     setRpcProviderType(AFWallet.RPCProviderType.Unset);
                    //     setShouldConfig(false);
                    // }).catch(e => {
                    //     showError(e);
                    // });
                }}>{WalletStrings.ui.config.back}</Button>
            </Flex>
        </VStack>
        }
        {configStatus === 1 &&  // select network
        <VStack>
        <Heading
            color={'gray.800'}
            lineHeight={1.1}
            fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                {WalletStrings.ui.config.network.title}
        </Heading>
        {WalletStrings.ui.config.network.description && WalletStrings.ui.config.network.description.length > 0 &&
        <Flex width={"100%"} direction={"column"} align="left">
            <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
            {WalletStrings.ui.config.network.description}
            </Text>
        </Flex>
        }
        <Flex width={"100%"} direction={"column"} align="left">
            <Button w={"100%"} mt={5} colorScheme={"green"} onClick={() => { 
                // window.appConfig.chainId = EthereumChains.Mainnet;
                window.appConfig.recentChainId = EthereumChains.Avalanche;
                window.chainId = EthereumChains.Avalanche;
                setChainId(window.chainId);
                setConfigStatus(3);
            }}>{WalletStrings.ui.config.network.mainnet}</Button>
            <Text mt={2} fontWeight="bold" fontSize={"xs"} color={"gray.600"}>
            {WalletStrings.ui.config.network.mainnet_note}
            </Text>
            
        </Flex>
        <Flex width={"100%"} direction={"column"} align="left">
            <Button w={"100%"} mt={3} colorScheme={"orange"} onClick={() => { 
                // window.appConfig.chainId = EthereumChains.Fuji;
                window.chainId = EthereumChains.Fuji;
                window.appConfig.recentChainId = EthereumChains.Fuji;
                setChainId(window.chainId);
                setConfigStatus(3);
            }}>{WalletStrings.ui.config.network.testnet}</Button>
            <Text mt={2} fontWeight="bold" fontSize={"xs"} color={"gray.600"}>
            {WalletStrings.ui.config.network.testnet_note}
            </Text>
        </Flex>
        <Flex width={"100%"} direction={"column"} align="left">
            <Button w={"100%"} mt={3} colorScheme={"red"} onClick={() => { 
                // window.appConfig.chainId = EthereumChains.Fuji;
                window.chainId = EthereumChains.Fuji;
                window.appConfig.recentChainId = EthereumChains.Fuji;
                setChainId(window.chainId);
                window.appConfig.mailAddressNeedToBeVerified = false;
                setConfigStatus(3);
            }}>{WalletStrings.ui.config.network.unverified}</Button>
            <Text mt={2} fontWeight="bold" fontSize={"xs"} color={"gray.600"}>
            {WalletStrings.ui.config.network.unverified_node}
            </Text>
        </Flex>
        <Flex width={"100%"} direction={"column"} align="left">
            <Button w={"100%"} mt={"20px"} colorScheme={"gray"} onClick={() => {
                window.appConfig.clearChainId();
                setConfigStatus(0);
            }}>{WalletStrings.ui.config.back}</Button>
        </Flex>
        </VStack>
        }
        {configStatus === 3 &&  // create new
        <VStack>
            <Image width={"72px"} src="screen-lock.png"  />
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    <Text mt={5}>{WalletStrings.ui.config.create.title}</Text>
            </Heading>
            {(password === null || password.length !== 6) &&
            <Flex width={"100%"} direction={"column"} align="center" hidden={ (password !== null && password.length === 6) }>
                <Text mt={5}>
                {WalletStrings.ui.config.create.pin_code}
                </Text>
                <HStack mt={5} align={"center"} justifyContent={"center"}>
                    <PinInput autoFocus={true} type='number' mask={true} onComplete={(e) => {
                        setPassword(e);
                    }}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack>
            </Flex>
            }
            {(password !== null && password.length === 6) &&
            <Flex width={"100%"} direction={"column"} align="center" hidden={ (password === null || password.length !== 6) }>
                <Text mt={5}>
                {WalletStrings.ui.config.create.confirm_pin_code}
                </Text>
                <HStack mt={5} align={"center"} justifyContent={"center"}>
                    {/* alphanumeric */}
                    <PinInput autoFocus={true} type='number' mask={true} onChange={(e) => {
                        const confirm = e || '';
                        const confirmed = confirm === password;
                        if (confirm.length === 6 && !confirmed) {
                            
                            setErrorMessage(WalletStrings.ui.config.create.pin_not_match)
                        } else {
                            setErrorMessage(null);
                        }
                    }} onComplete={(e) => {
                        const confirm = e || '';
                        const confirmed = confirm === password;
                        setConfirmed(confirmed);
                        
                        if (confirmed) {
                            createWallet();
                        }
                    }}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack>
            </Flex>
            }
            
            {(errorMessage && errorMessage.length > 0) &&
            <Flex width={"100%"} direction={"column"} align="center">
                <HStack mt={5} align="center">
                    <Text fontSize={"sm"} color="orange">
                    {errorMessage}
                    </Text>
                </HStack>
            </Flex>
            }

            {isInitializing &&
            <Flex width={"100%"} direction={"column"} align="center">
                <HStack mt={5} align="center">
                    <Spinner size={"sm"} color="orange" /> 
                    <Text fontSize={"sm"} color="orange">
                    {WalletStrings.ui.config.create.creating}
                    </Text>
                </HStack>
            </Flex>
            }

            <Flex width={"100%"} direction={"column"} align="center">
                <Text mt={10} fontSize={"10px"} color="gray.600">
                {WalletStrings.ui.config.create.note}
                </Text>
            </Flex>

            {/* <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={'white'}
                isDisabled={!confirmed}
                isLoading={isInitializing}
                _hover={{
                    bgGradient: 'linear(to-r, red.400,pink.400)',
                    boxShadow: 'xl',
                }}
                onClick={createWallet}>
                Create new Wallet
            </Button> */}

            <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgColor={"gray.200"}
                color={'black'}
                onClick={() => {
                    if (password === null || password.length !== 6) {
                        // window.appConfig.clearChainId();
                        // setConfigStatus(1);
                        setConfigStatus(0);
                    } else if (password !== null && password.length === 6) {
                        setPassword(null);
                    }
                }}>
            {WalletStrings.ui.config.create.back}
            </Button>
        </VStack>
        }

        {configStatus === 2 &&  // recovery
        <VStack>
            <Image width={"72px"} src="crypton-320.png"  />
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    <Text mt={5}>{WalletStrings.ui.config.recovery.title}</Text>
            </Heading>
            <Flex width={"100%"} direction={"column"} align="left">
                <FormLabel htmlFor='country'>
                {WalletStrings.ui.config.recovery.passphrase}
                </FormLabel>
                <InputGroup>
                <Input
                    autoComplete="off" type={showPassword ? 'text' : 'password'} onChange={verifyPassword} />
                <InputRightElement>
                {showPassword &&
                <IconButton icon={<FaEye />} onClick={togglePassword} />
                }
                {!showPassword &&
                <IconButton icon={<FaEyeSlash />} onClick={togglePassword} />
                }
                </InputRightElement>
                </InputGroup>
            </Flex>
            <Flex width={"100%"} direction={"column"} align="left">
                <FormLabel mt={4} htmlFor='country'>
                {WalletStrings.ui.config.recovery.entropy_salt}
                </FormLabel>
                <InputGroup>
                <Input
                    autoComplete="off" type={'text'} defaultValue={entropySalt} onChange={(e) => {
                    setEntropySalt(e.target.value);

                    setConfirmed(password && password.length > 0 && e.target.value && e.target.value.length > 0);
                }} />
                </InputGroup>
            </Flex>
            <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                // bgGradient="linear(to-r, red.400,pink.400)"
                // color={'white'}
                colorScheme={"green"} 
                isDisabled={!confirmed}
                isLoading={isInitializing}
                // _hover={{
                //     bgGradient: 'linear(to-r, red.400,pink.400)',
                //     boxShadow: 'xl',
                // }}
                onClick={recoverWallet}>
                {WalletStrings.ui.config.recovery.recover}
            </Button>

            <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgColor={"gray.200"}
                color={'black'}
                onClick={() => {
                    setConfigStatus(0);
                }}>
            {WalletStrings.ui.config.recovery.back}
            </Button>
        </VStack>
        }
        </>
    )
}

function ChangePINCodePanel({wallet, status, setRpcProviderType}) {
    const [isInitializing, setInitializing] = useState(false);
    const [password, setPassword] = useState(null); 
    // const confirmPINRef = React.createRef();

    async function changePINCode() {
        try {
            // console.log(confirmPINRef.current);
            setInitializing(true);
            if (status === WalletUIStatus.ResetPINCode) {
                await wallet.setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
                setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
            }
            await wallet.changePINCode(password, status === WalletUIStatus.ChangePINCode);
            if (window.isOneClickResume) {

                if (status === WalletUIStatus.ResetPINCode) {
                    const accounts = await wallet.getOrderedSelectedAddresses();
                    await wallet.confirmConnect(accounts, null);
                    wallet.disclosure.onClose();
                }
            } else {
                if (status === WalletUIStatus.ResetPINCode) {
                    await wallet.continueProcessingConnectRequest();
                }
            }
            setInitializing(false);
        } catch(e) {
            if (status === WalletUIStatus.ResetPINCode) {
                await wallet.setRpcProviderType(AFWallet.RPCProviderType.Unset);
                setRpcProviderType(AFWallet.RPCProviderType.Unset);
            }
            setInitializing(false);
            console.error(e);
            showError(e);
        }
    }

    return (
        <VStack>
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    {(status === WalletUIStatus.ResetPINCode) ? WalletStrings.ui.change_pin.reset_title : WalletStrings.ui.change_pin.title}
            </Heading>
            {(password === null || password.length !== 6) &&
            <Flex width={"100%"} direction={"column"} align="center" hidden={ (password !== null && password.length === 6) }>
                
                <Text mt={5}>
                {WalletStrings.ui.change_pin.pin_code}
                </Text>
                <HStack mt={5} align={"center"} justifyContent={"center"}>
                    <PinInput autoFocus={true} type='number' mask={true} onComplete={(e) => {
                        setPassword(e);
                    }}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack>
            </Flex>
            }
            {(password !== null && password.length === 6) &&
            <Flex width={"100%"} direction={"column"} align="center" hidden={ (password === null || password.length !== 6) }>
                
                <Text mt={5}>
                {WalletStrings.ui.change_pin.confirm_pin_code}
                </Text>
                <HStack mt={5} align={"center"} justifyContent={"center"}>
                    <PinInput autoFocus={true} type='number' mask={true} onComplete={(e) => {
                        const confirm = e || '';
                        const confirmed = confirm === password;
                        if (confirmed) {
                            changePINCode();
                        }
                    }}>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                    </PinInput>
                </HStack>
            </Flex>
            }
            {isInitializing &&
            <Flex width={"100%"} direction={"column"} align="center">
                <HStack mt={5} align="center">
                    <Spinner size={"sm"} color="orange" /> 
                    <Text fontSize={"sm"} color="orange">
                    {WalletStrings.ui.change_pin.changing}
                    </Text>
                </HStack>
            </Flex>
            }

            <Flex width={"100%"} direction={"column"} align="center">
                <Text mt={10} fontSize={"10px"} color="gray.600">
                {WalletStrings.ui.change_pin.note}
                </Text>
            </Flex>

            {(password !== null && password.length === 6) &&
            <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                bgColor={"gray.200"}
                color={'black'}
                onClick={() => {
                    setPassword(null);
                }}>
            {WalletStrings.ui.change_pin.back}
            </Button>
            }
        </VStack>
        
    )
}

function MigrationPanel({wallet}) {
    const [isLoading, setLoading] = useState(false);
    const [address, setAddress] = useState(null); 
    // const confirmPINRef = React.createRef();

    async function migrate() {
        try {
            setLoading(true);
            await wallet.migrate(address);
            setLoading(false);
        } catch(e) {
            setLoading(false);
            console.error(e);
            showError(e);
        }
    }

    return (
        <VStack>
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    { WalletStrings.ui.migrate.title }
            </Heading>
            <Flex width={"100%"} direction={"column"} align="center">
                <FormLabel mt={5} htmlFor='country'>{WalletStrings.ui.migrate.address}</FormLabel>
                <Input
                    autoComplete="off" fontSize={"sm"} type={'text'} onChange={(e) => {
                    const address = e.target.value || '';
                    if (address.length > 0 && ethers.isAddress(address)) {
                        setAddress(address);
                    } else {
                        setAddress(null);
                    }
                }} />
            </Flex>
            

            <Flex width={"100%"} direction={"column"} align="center">
                <Text mt={5} mb={5} fontSize={"10px"} color="gray.600">
                {WalletStrings.ui.migrate.note}
                </Text>
            </Flex>

            <Button
                fontFamily={'heading'}
                w={'full'}
                colorScheme={"green"}
                isDisabled={!address}
                isLoading={isLoading}
                onClick={() => {
                    migrate();
                }}>
            {WalletStrings.ui.migrate.migrate}
            </Button>
        </VStack>
        
    )
}
function ChooseWalletPanel({setChainId, wallet, status, setRpcProviderType}) {
    const [shouldConfig, setShouldConfig] = useState(false);
    return (
        <>
        {shouldConfig &&
            <ConfigWalletPanel setChainId={setChainId} wallet={wallet} status={status} setRpcProviderType={setRpcProviderType} setShouldConfig={setShouldConfig} />
        }
        {!shouldConfig &&
            <VStack>
                {/* <Flex>
                    <Text fontSize={"xl"} fontWeight={"bold"}>
                    {WalletStrings.ui.choose.title}
                    </Text>
                </Flex> */}

                {window.isOneClickResume &&
                <>
                <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                {WalletStrings.ui.one_click.title}
                </Heading>
                <Flex width={"100%"} direction={"column"} align="left">
                    <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                    {WalletStrings.ui.one_click.description}
                    </Text>
                    <Text mt={"10px"} fontSize={"xs"} color={"orange"}>
                    {WalletStrings.ui.one_click.note}
                    </Text>
                </Flex>
                </>
                }

                {!window.isOneClickResume &&
                <>
                <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                {WalletStrings.ui.choose.title}
                </Heading>
                <Flex width={"100%"} direction={"column"} align="left">
                    <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                    {WalletStrings.ui.choose.description_1}
                    </Text>
                    <Text mt={"10px"} fontSize={"xs"} color={"gray.600"}>
                    {WalletStrings.ui.choose.description_2}&nbsp;<Link href="https://ai-fi.cc/help.html#crypto-wallet" target="_blank" color="#00aaff">Learn more.</Link>
                        {/* 因此高度推荐你使用Ai-Fi Wallet，除非你已经拥有ETH Wallet，并且不在乎上述好处，请选择“Metamask”把它连接到PlexiMail。 */}
                    </Text>
                </Flex>
                </>
                }

                <Flex width={"100%"} direction={"column"} align="left">
                    <Button w={"100%"} mt={"20px"} colorScheme={"green"} onClick={() => { 
                        wallet.isConfigured().then(isConfigured => {
                            if (isConfigured) {
                                wallet.setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
                                setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
                                if (status === WalletUIStatus.Choose) {
                                    wallet.onRpcProviderTypeChanged(true);
                                    wallet.disclosure.onClose();
                                }
                            } else {
                                setShouldConfig(true);
                            }
                        }).catch(e => {
                            console.error(e);
                            showError(e);
                        });
                        /*
                        wallet.setRpcProviderType(AFWallet.RPCProviderType.AFWallet).then(() => {
                            return wallet.isConfigured();
                        }).then(isConfigured => {
                            if (isConfigured) {
                                setRpcProviderType(AFWallet.RPCProviderType.AFWallet);
                                if (status === WalletUIStatus.Choose) {
                                    wallet.onRpcProviderTypeChanged(true);
                                    wallet.disclosure.onClose();
                                }
                            } else {
                                setShouldConfig(true);
                            }
                        }).catch(e => {
                            showError(e);
                        });
                        */
                    }}>{WalletStrings.ui.choose.get_started}</Button>
                </Flex>
                <Flex width={"100%"} direction={"column"} align="center">
                    {/* <Button w={"100%"} mt={"20px"} colorScheme={"gray"} onClick={() => { 
                        wallet.setRpcProviderType(AFWallet.RPCProviderType.Other).then(() => {
                            setRpcProviderType(AFWallet.RPCProviderType.Other);
                            if (status === WalletUIStatus.Choose) {
                                wallet.onRpcProviderTypeChanged(true);
                                wallet.disclosure.onClose();
                            }
                        }).catch(e => {
                            showError(e);
                        });;
                    }}>Other Wallet</Button> */}

                    {/* <Link mt={5} href="#" onClick={() => {
                        wallet.setRpcProviderType(AFWallet.RPCProviderType.Other).then(() => {
                            setRpcProviderType(AFWallet.RPCProviderType.Other);
                            if (status === WalletUIStatus.Choose) {
                                wallet.onRpcProviderTypeChanged(true);
                                wallet.disclosure.onClose();
                            }
                        }).catch(e => {
                            showError(e);
                        });;
                        return false;
                    }}>
                        <MetaMaskIcon height={24} />
                    </Link> */}

                    {/* <IconButton alt={"Metamask wallet"} icon={<MetaMaskIcon />} onClick={() => {
                        wallet.setRpcProviderType(AFWallet.RPCProviderType.Other).then(() => {
                            setRpcProviderType(AFWallet.RPCProviderType.Other);
                            if (status === WalletUIStatus.Choose) {
                                wallet.onRpcProviderTypeChanged(true);
                                wallet.disclosure.onClose();
                            }
                        }).catch(e => {
                            showError(e);
                        });;
                    }} /> */}
                </Flex>
            </VStack>

        }
        </>)
}

function ConnectPanel({wallet}) {
    // const wallet = useContext(AFWalletContext); 
    const [wallets, setWallets] = useState([]);
    const [selectedAccounts, setSelectedAccounts] = useState(null);
    const [isConnecting, setConnecting] = useState(false);

    const {logout} = useAuth();
    // const [defaultChecked, setDefaultChecked] = useState(false);
    useEffect(()=>{
        console.log('ConnectPanel - wallet: ', wallet.instanceNo);
        wallet.getWalletList().then(wallets => {
            setWallets(wallets);
            if (wallets && wallets.length === 1) {
                setSelectedAccounts([wallets[0].address]);
                // setDefaultChecked(true);
            } else {
                // setDefaultChecked(false);
                wallet.getOrderedSelectedAddresses().then(selectedAddresses => {
                    setSelectedAccounts(selectedAddresses);
                }).catch(e => {
                    console.error(e);
                    setSelectedAccounts([]);
                });
            }
        }).catch(e => {
            console.error(e);
        })
    },[wallets.length]);// eslint-disable-line react-hooks/exhaustive-deps

    const isWalletSelected = (walletSummary) => {
        if (!selectedAccounts || selectedAccounts.length === 0) {
            return false;
            // return defaultChecked;
        }
        const w = walletSummary.address.toLowerCase();
        const accounts = selectedAccounts.map(a => { return a.toLowerCase()});
        const selected = accounts.includes(w);
        // return selected ? selected : defaultChecked;
        return selected;
    }
    const addSelectedAccount = (address, ahead) => {
        if (!selectedAccounts || selectedAccounts.length === 0) {
            setSelectedAccounts([address]);
            return;
        }
        address = address.toLowerCase();
        const accounts = selectedAccounts.map(a => { return a.toLowerCase()});
        if (!accounts.includes(address)) {
            if (ahead) {
                setSelectedAccounts([address, ...selectedAccounts]);
            } else {
                setSelectedAccounts([...selectedAccounts, address]);
            }
        }
    }
    const removeSelectedAccount = (address) => {
        if (!selectedAccounts || selectedAccounts.length === 0) {
            return;
        }
        address = address.toLowerCase();
        const accounts = selectedAccounts.filter(a => { return a.toLowerCase() !== address });
        setSelectedAccounts([...accounts]);
    }
    return (<VStack>
        {/* <Text>Coming soon...</Text> */}
        
        {/* <Flex>
            <Text fontSize={"xl"} fontWeight={"bold"}>
            {WalletStrings.ui.connect.title}
            </Text>
        </Flex> */}

        <Heading
            color={'gray.800'}
            lineHeight={1.1}
            fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
            {WalletStrings.ui.connect.title}
        </Heading>
        <Flex width={"100%"} direction={"column"} align="left">
            <Text fontSize={"xs"} color={"gray.600"}>
            {WalletStrings.ui.connect.description}&nbsp;<Link href="https://ai-fi.cc/help.html#crypto-wallet" target="_blank" color="#00aaff">Learn more.</Link>
            </Text>
        </Flex>
        {wallets && wallets.map((walletSummary) => {
           
            return (<HStack key={walletSummary}>
                <Checkbox onChange={(e) => {
                    if (e.target.checked) {
                        addSelectedAccount(walletSummary.address, walletSummary.activated);
                    } else {
                        removeSelectedAccount(walletSummary.address);
                    }
                }} defaultChecked={isWalletSelected(walletSummary)} ></Checkbox>
                &nbsp;<Text fontSize={"xs"}>{walletSummary.address}</Text>
            </HStack>)
        })}
        <Button colorScheme={"green"} isLoading={isConnecting} isDisabled={(!selectedAccounts || selectedAccounts.length === 0)} onClick={() => {
            setConnecting(true);

            wallet.confirmConnect(selectedAccounts, null).then(() => {
                setConnecting(false);
                wallet.disclosure.onClose();

            }).catch(e => {
                setConnecting(false);
                console.error(e);
                showError(e);
            })
           // wallet.onConnected(selectedAccounts, null);
           // wallet.disclosure.onClose();
        }}>{WalletStrings.ui.connect.connect}</Button>

        
        <Button mt={4} colorScheme={"gray"} onClick={() => {
            window.mailService.reset(true).then(() => {
                if (logout) {
                    return logout().then(() => {
                      return window.wallet.reset();
                    });
                  }
              return window.wallet.reset(true);
            }).then(() => {
              window.forceQuit = true;
              setTimeout(function () {
                window.location.reload(true);
              });
            }).catch((e) => {
              console.error(e);
              showError(e);
            });
        }}>{WalletStrings.ui.connect.deny}</Button>
    </VStack>)
}

function WalletPanel({wallet, version, onBuy, onMigrate}) {

    const menuBg = useColorModeValue('white', 'gray.900');
    const menuBorderColor = useColorModeValue('gray.200', 'gray.700');
    const [balance, setBalance] = useState(null);
    const [walletList, setWalletList] = useState(null);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [timerCancelled, setTimerCancelled] = useState(false);

    const createDisclosure = useDisclosure();
    const importDisclosure = useDisclosure();
    const [newName, setNewName] = useState(null);
    const [isEditing, setEditing] = useState(false);

    useEffect(() => {
        // wallet.getBalance().then(balance => {
        //     setBalance(balance);
        // }).catch(e => {
        //     console.log(e);
        //     showError(e);
        // })
        showWallets();
    }, [version]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => {
            setTimerCancelled(true);
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    const showWallets = () => {
        setTimerCancelled(false);
        wallet.getWalletList().then(wallets => {
            setWalletList(wallets);
            return getActivatedWallet();
        }).then(wallet => {
            setSelectedWallet(wallet);
            console.log('WalletPanel Selected Wallet:', wallet)
            monitorBalance(wallet);
        }).catch(e => {
            console.error(e);
            showError(e);
        });
    }
    const getActivatedWallet = async () => {

        const activatedWalletSummary = await wallet.getActivatedWallet();
        return activatedWalletSummary;

        // const promise = new Promise((resolve, reject) => {
        //     if (wallet.activatedWallet) {
        //         resolve(wallet.activatedWallet);
        //         return;
        //     }
        //     let counter = 0;
        //     const interval = setInterval(() => {
        //         ++counter;
        //         if (counter > 60) {
        //             reject(new Error('Timeout'));
        //             return;
        //         }
        //         if (wallet.activatedWallet) {
        //             clearInterval(interval);
        //             resolve(wallet.activatedWallet);
        //             return;
        //         }
        //     }, 1000);

        // })
        // return promise;
    }

    const monitorBalance = (aWallet = null) => {
        if (!aWallet) {
            aWallet = selectedWallet;
        }
        wallet.getBalance(aWallet.address).then(balance => {
            setBalance(balance);
            if (!timerCancelled && wallet.disclosure.isOpen) {
                setTimeout(() => {
                    monitorBalance(aWallet);
                }, 10000);
            }
        }).catch(e => {
            console.error(e);
            showError(e);
        })
    };

    const switchWallet= (address) => {//eslint-disable-line
        wallet.switchWallet(address,true).then(() => {

        })
    };
    const coinSymbol = () => {
        if (wallet.network === 'fuji' || wallet.network === 'avalanche' || wallet.network === 'mainnet') {
            return 'AVAX'
        }
        return 'ETH';
    }
    const changeWalletName = () => {
        if (walletList) {
            for(const w of walletList) {
                if (newName.toLowerCase() === w.name.toLowerCase()) {
                    return;
                }
            }
        }

        wallet.changeWalletName(selectedWallet.address, newName).then(() => {
            setSelectedWallet({...selectedWallet, name: newName})
            setEditing(false);
            return showWallets()
        }).catch(e => {
            console.error(e);
            showError(e);
        })
    }
    return (
        <>
        <CreateWalletAlert disclosure={createDisclosure} onComplete={(name) => {
            wallet.add(name).then(() => {
                
            }).catch(e => {
                console.error(e);
                showError(e);
            })
        }} />
        <ImportWalletAlert disclosure={importDisclosure} onComplete={(name) => {
            // wallet.add(name).then(() => {
                
            // }).catch(e => {
            //     console.error(e);
            //     showError(e);
            // })
        }} />
        <VStack>
            {/* <Flex minWidth='100%' justifyContent={"flex-end"}>
            <Heading
                flex={1}
                color={'gray.800'}
                lineHeight={1.1}
                align={"center"}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    {"Ai-Fi Wallet"}
            </Heading>
            <IconButton variant={"link"} icon={<FiPlus />} />
            <IconButton variant={"link"} icon={<FiSettings />} />
            <IconButton colorScheme={"dark"} variant={"link"} icon={<FiX />} />
            </Flex> */}
            <Toaster position='bottom-center' />
            {isEditing && 
            <Flex align="center">
                
                <Input autoFocus={true} onFocus={(e) => e.currentTarget.select()} fontSize="xs" color="gray.600" defaultValue={selectedWallet && selectedWallet.name} onChange={(e) => {
                    setNewName(e.target.value)

                }} onKeyDown={(e) => {

                    if (e.key === "Escape") {
                        setEditing(false);
                    } else if (e.key === "Enter") {
                        if (selectedWallet && newName && newName.length > 0 && newName.toLowerCase() !== selectedWallet.name.toLowerCase()) {
                            changeWalletName();
                        } else {
                            setEditing(false);
                        }
                    }
                }} /> 
                <IconButton ml={2} size={"sm"} isDisabled={!newName || newName.length === 0 || newName === selectedWallet.name} variant={'ghost'} colorScheme={'green'} icon={<FiSave />} onClick={() => {
                    changeWalletName();
                }} /><IconButton size={"sm"} variant={'ghost'} colorScheme={'red'} icon={<FiX />} onClick={() => {
                    setEditing(false);
                }} />
            </Flex>
            }
            {!isEditing && 
            <Flex w={"100%"}>
                <Center flex={1} boxSizing={"border-box"} pl={"48px"} align="center" cursor={"pointer"}>
                <Text fontSize="xs" color="gray.600">{selectedWallet && selectedWallet.name}</Text>
                <IconButton ml={1} size={"sm"} variant={'ghost'} colorScheme={'gray'} icon={<FiEdit3 />} onClick={() => {
                    
                    setEditing(true);
                }} />
                </Center>

                {/* <IconButton size={"sm"} variant={'ghost'} colorScheme={'red'} icon={<FiSettings />} onClick={() => {
                    
                }} /> */}
            </Flex>
            }
            {window.appConfig.privacyLevel === AppConfig.PrivacyLevel.High &&
            <Flex align="center" cursor={"pointer"}>
                <Text fontSize="xs" color="gray.600">{selectedWallet && selectedWallet.address}</Text>&nbsp; <FaRegCopy size={12} />
                
            </Flex>
            }
            {/* <Flex align="center" cursor={"pointer"}>
                <Text fontSize="xs" color="gray.600">{selectedWallet && selectedWallet.address}</Text>&nbsp; <FaRegCopy size={12} />
            </Flex> */}

            {window.appConfig.privacyLevel !== AppConfig.PrivacyLevel.High &&
            <Flex align="center" cursor={"pointer"} onClick={() => {}}>
            <Menu>
                    <MenuButton
                    // isDisabled={true}
                    // isDisabled={true}
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: 'none' }}>
                        <HStack>
                            <Box display={{ base: 'none', md: 'flex' }}>
                            <FaEthereum size={12} />
                            </Box>
                            <Text fontSize="xs" color="gray.600">{selectedWallet && selectedWallet.address}</Text>
                            <Box display={{ base: 'none', md: 'flex' }}>
                            <FiChevronDown />
                            </Box>
                        </HStack>
                    </MenuButton>
                    
                    <MenuList
                    bg={menuBg}
                    borderColor={menuBorderColor}>
                        {walletList && walletList.map(w => {
                            return (
                        <MenuItem key={w.address} color={"gray.600"} onClick={() => {
                            if (w.address.toLowerCase() === selectedWallet.address.toLowerCase()) {
                                return;
                            }
                            wallet.switchWallet(w.address, true).then(() => {}).catch(e => {
                                console.error(e);
                                showError(e);
                            })
                        }}>
                            <HStack>
                            <Text fontSize="xs" color="gray.600">
                                {`${w.name} - ${w.address}`}
                            </Text>
                            </HStack>
                        </MenuItem>
                        )
                        })}

                        <Divider orientation='horizontal' />
                        
                        <MenuItem color={"gray.600"} icon={<FiPlus />} onClick={() => {
                            /*
                            wallet.add().then(() => {
                                
                            }).catch(e => {
                                console.error(e);
                                showError(e);
                            })
                            */
                            createDisclosure.onOpen();
                        }}>
                            <HStack>
                            <Text fontSize="xs" color="gray.600">
                                {WalletStrings.ui.wallet.create}
                            </Text>
                            </HStack>
                        </MenuItem>
                    </MenuList>
                    
                </Menu> 

            {window.appConfig.privacyLevel === AppConfig.PrivacyLevel.High &&
            <CopyToClipboard text={selectedWallet && selectedWallet.address} onCopy={() => {
                // addToast('Copied!', {
                //     appearance: 'info',
                //     autoDismiss: true,
                // })
                toast('Copied');
            }}>
                <Box ml={2}>
                <FaRegCopy size={14} /></Box>
                <IconButton ml={1} size={"sm"} variant={'link'} color={'gray'} icon={<FaRegCopy />} />
</CopyToClipboard> 
            }
                {/* <CopyToClipboard text={selectedWallet && selectedWallet.address} onCopy={() => {
                                        // addToast('Copied!', {
                                        //     appearance: 'info',
                                        //     autoDismiss: true,
                                        // })
                                        toast('Copied');
                                    }}>
                                        <Box ml={2}>
                                        <FaRegCopy size={14} /></Box>
                                        <IconButton ml={1} size={"sm"} variant={'link'} color={'gray'} icon={<FaRegCopy />} />
            </CopyToClipboard> */}
            {/* <Box ml={2}>
                                        <FaRegCopy size={14} /></Box> */}

                <Menu>
                    <MenuButton
                    as={IconButton}
                    ml={2}
                    size={"xl"} p={1} colorScheme={"purple"} icon={<FiMoreHorizontal />}
                    // transition="all 0.3s"
                    // _focus={{ boxShadow: 'none' }}
                    >
                    </MenuButton>

                    <MenuList
                    bg={menuBg}
                    borderColor={menuBorderColor}>
                        <CopyToClipboard text={selectedWallet && selectedWallet.address} onCopy={() => { toast('Copied');}}>

                            <MenuItem color={"gray.600"} icon={<FaRegCopy />}>
                                <HStack>
                                <Text fontSize="xs" color="gray.600">
                                    {WalletStrings.ui.wallet.copy}
                                </Text>
                                </HStack>
                            </MenuItem>
                        </CopyToClipboard>
                        <MenuItem color={"gray.600"} icon={<FiPlus />} onClick={() => {
                            createDisclosure.onOpen();
                        }}>
                            <HStack>
                            <Text fontSize="xs" color="gray.600">
                                {WalletStrings.ui.wallet.create}
                            </Text>
                            </HStack>
                        </MenuItem>
                        <MenuItem color={"gray.600"} icon={<FiDownloadCloud />} onClick={() => {
                            importDisclosure.onOpen();
                        }}>
                            <HStack>
                            <Text fontSize="xs" color="gray.600">
                                {WalletStrings.ui.wallet.import}
                            </Text>
                            </HStack>
                        </MenuItem>
                        {/* <Divider orientation='horizontal' />
                        <MenuItem color={"gray.600"} icon={<FiSettings />} onClick={() => {
                            
                        }}>
                            <HStack>
                            <Text fontSize="xs" color="gray.600">
                                {WalletStrings.ui.wallet.advanced}
                            </Text>
                            </HStack>
                        </MenuItem> */}
                    </MenuList>
                </Menu>
            </Flex>
            }
            {balance &&
            <Flex width={"100%"} direction={"column"} align="center">
                <Text mt={"20px"} fontSize={"xl"} fontWeight={"bold"}>{balance.eth}&nbsp;{coinSymbol()}</Text>
                {balance.dollar > 0 &&
                <Text fontSize={"xl"} color={"gray.600"}>${balance.dollar}</Text>
                }   
            </Flex>
            }
            {!balance &&
            <Flex width={"100%"} direction={"column"} align="center">
                <Skeleton width={"50%"} >
                <Text mt={"20px"} fontSize={"xl"} fontWeight={"bold"}>-&nbsp;{coinSymbol()}</Text>
                <Text fontSize={"xl"} color={"gray.600"}>$-</Text>
                </Skeleton>
            </Flex>
            }
            <Flex>
                <Button variant={'link'} mt={20} fontSize={"sm"} leftIcon={<FiLifeBuoy />} colorScheme={"gray"} onClick={() => {
                    onBuy();
                }}>
                    {WalletStrings.ui.wallet.buy}
                </Button>
            </Flex>

            {parseFloat(balance ? balance.eth || '0.0' : '0.0') > 0 &&
            <Flex>
                <Link mt={"20px"} leftIcon={<AiOutlineTransaction />} colorScheme={"gray.600"} onClick={() => {
                    onMigrate();
                }}>
                    {WalletStrings.ui.wallet.migrate}
                </Link>
            </Flex>
            }
        </VStack>
        
        </>)
}

function SendTransactionPanel({wallet}) {
    const [isLoading, setLoading] = useState(false);
    const [functionData, setFunctionData] = useState(null);
    const [gas, setGas] = useState(null);
    useEffect(() => {
        if (wallet.pendingRequest) {
            const {params} = wallet.pendingRequest;
            const [tx] = params;
            const isSmartContractCall = tx.data && tx.data.length > 0;

            if (isSmartContractCall) {
                const fnData = wallet.decodeFunctionData('storeKeyBundle', tx.data);
                setFunctionData(fnData);
            }

            wallet.estimateGas(tx).then(gas => {

                return CurrencyUtils.getCurrentPrice().then(price => {
                    return {
                        gas: gas,
                        usd: {
                            base: (price * (parseFloat(gas.cost.eth.base))).toFixed(2) + '',
                            middle: (price * (parseFloat(gas.cost.eth.middle))).toFixed(2) + '',
                            high: (price * (parseFloat(gas.cost.eth.high))).toFixed(2) + ''
                        }
                    }
                });
            }).then(result => {
                setGas(result);
                console.log('SendTransactionPanel - estimateGas: ', result);
            });
        }
        // wallet.etherProvider.es
    }, []);// eslint-disable-line react-hooks/exhaustive-deps
    const coinSymbol = () => {
        if (wallet.network === 'fuji' || wallet.network === 'avalanche' || wallet.network === 'mainnet') {
            return 'AVAX'
        }
        return 'ETH';
    }
    return (
        <VStack>
                {/* <Flex>
                    <Text fontSize={"xl"} fontWeight={"bold"}>
                        {WalletStrings.ui.sign_tx.title}
                    </Text>
                </Flex> */}
                <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    {WalletStrings.ui.sign_tx.title}
                </Heading>
                <Flex width={"100%"} direction={"column"} align="left">
                    <Text fontSize={"xs"} color={"gray.600"}>
                    {WalletStrings.ui.sign_tx.description_1}
                    </Text>
                </Flex>
                {functionData &&
                <Flex width={"100%"} direction={"column"} align="left" border={"1px solid #ccc"} p={1}>
                    <Text fontSize={"xs"} fontWeight={"600"} color={"dark"}>
                    {WalletStrings.ui.sign_tx.address}
                    </Text>
                    <Text fontSize={"xs"} color={"gray.600"}>
                        {wallet.accounts[0]}
                    </Text>

                    <Text mt={2} fontSize={"xs"} fontWeight={"600"} color={"dark"}>
                    {WalletStrings.ui.sign_tx.identity_key}
                    </Text>
                    <Text fontSize={"xs"} color={"gray.600"}>
                        {functionData[0]}
                    </Text>

                    <Text mt={2} fontSize={"xs"} fontWeight={"600"} color={"dark"}>
                    {WalletStrings.ui.sign_tx.key_server}
                    </Text>
                    <Text fontSize={"xs"} color={"gray.600"}>
                        {functionData[1]}
                    </Text>

                </Flex>
                }
                <Flex mt={4} width={"100%"} direction={"column"} align="left">
                    <Text fontSize={"xs"} color={"gray.600"}>
                    {WalletStrings.ui.sign_tx.description_2}
                    </Text>
                    <Text fontSize={"xs"} color={"gray.600"}>
                    {WalletStrings.ui.sign_tx.description_3}
                    </Text>
                </Flex>
                
                <Flex width={"100%"} direction={"column"} align="left" border={"1px solid #ccc"} p={1}>
                    <Text fontSize={"xs"} color={"gray.600"}>{WalletStrings.ui.sign_tx.estimated_cost} {(gas && gas.gas) ? gas.gas.cost.eth.base.substring(0, 10) : '-'}&nbsp;{coinSymbol()}&nbsp;&#8776;&nbsp;{(gas && gas.usd) ? '$' + gas.usd.base : '$0' }</Text>
                    <Text fontSize={"xs"} color={"red.600"}>{WalletStrings.ui.sign_tx.max_cost} {(gas && gas.gas) ? gas.gas.cost.eth.high.substring(0, 10) : '-'}&nbsp;{coinSymbol()}&nbsp;&#8776;&nbsp;{(gas && gas.usd) ? '$' + gas.usd.high : '$0'}</Text>
                    <Text fontSize={"xs"} fontWeight={"600"} color={"green.600"}>{WalletStrings.ui.sign_tx.within_30_seconds}</Text>
                </Flex>
                <Flex>
                    <Button mt={"20px"} mr={4} isDisabled={isLoading} colorScheme={"gray"} onClick={ () => {
                        
                        wallet.onConfirmed(false).then(() => {
                            wallet.disclosure.onClose();
                        }).catch(e => {
                            console.error(e);
                            showError(e);
                        });
                    }}>{WalletStrings.ui.sign_tx.cancel}</Button>
                    
                    <Button mt={"20px"} leftIcon={<FaArrowAltCircleUp />} isLoading={isLoading} colorScheme={"green"} onClick={ () => {
                        
                        setLoading(true);
                        wallet.onConfirmed(true).then(() => {
                            setLoading(false);
                            wallet.disclosure.onClose();
                        }).catch(e => {
                            setLoading(false);
                            console.error(e);
                            showError(e);
                        });
                    }}>{WalletStrings.ui.sign_tx.publish}</Button>
                </Flex>
            </VStack>

    )
}

function SignPanel({wallet}) {

    const [isLoading, setLoading] = useState(false);
    const fromHexString = (hexString) => {
        return Uint8Array.from(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));
    }
    const formatMessage = () => {
        if (!wallet.pendingRequest) {
            return '';
        }
        const arr = fromHexString(wallet.pendingRequest.params[0]);
        const text = new TextDecoder().decode(arr);
        return text;
    }
    return (
        <VStack>
                {/* <Flex>
                    <Text fontSize={"xl"} fontWeight={"bold"}>{WalletStrings.ui.sign.title}</Text>
                </Flex> */}
                <Heading
                    color={'gray.800'}
                    lineHeight={1.1}
                    fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    {WalletStrings.ui.sign.title}
                </Heading>
                <Flex width={"100%"} direction={"column"} align="left">
                    <Text mt={"20px"} fontSize={"xs"} color={"gray.600"}>
                    {WalletStrings.ui.sign.description}
                    </Text>
                    <Text mt={"10px"} fontSize={"xs"} color={"gray.600"}>
                        {formatMessage()}
                    </Text>
                </Flex>
                <Flex>
                    <Button mt={"20px"} mr={4} isDisabled={isLoading} colorScheme={"gray"} onClick={() => {
                        wallet.onConfirmed(false).then(() => {
                            wallet.disclosure.onClose();
                        }).catch(e => {
                            if (e.code === 4001) {
                                wallet.disclosure.onClose();
                                return;
                            }
                            console.error(e);
                            showError(e);
                        });
                    }}>{WalletStrings.ui.sign.cancel}</Button>
                    <Button mt={"20px"} leftIcon={<FaFileSignature />} isLoading={isLoading} colorScheme={"green"} onClick={() => {
                        setLoading(true);
                        wallet.onConfirmed(true).then(() => {
                            setLoading(false);
                            wallet.disclosure.onClose();
                        }).catch(e => {
                            setLoading(false);
                            console.error(e);
                            showError(e);
                        });
                    }}>{WalletStrings.ui.sign.sign}</Button>
                </Flex>
            </VStack>

    )
}

function ChangeWeb3StorageAPITokenPanel({wallet}) {
    const [isLoading, setLoading] = useState(false);
    const [apiToken, setApiToken] = useState(null); 
    // const confirmPINRef = React.createRef();

    async function recover(apiToken) {
        window.appConfig.web3StorageApiToken = apiToken;
        await window.mailService.initWeb3StorageClient(window.appConfig.web3StorageApiToken);
    }

    async function changeWeb3StorageAPIToken() {
        const oldApiToken = window.appConfig.web3StorageApiToken;
        try {
            setLoading(true);

            window.appConfig.web3StorageApiToken = apiToken;

            await window.mailService.initWeb3StorageClient(window.appConfig.web3StorageApiToken);
            // await window.mailService.initSignalService(selectedAccount);
            const res = await window.mailService.initFoldersIfNeeded(true);
            await wallet.showBackup();
            console.log(res);
            setLoading(false);
        } catch(e) {
            await recover(oldApiToken);
            setLoading(false);
            console.error(e);
            showError(e);
        }
    }

    return (
        <VStack>
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    {WalletStrings.ui.change_w3s_api_token.title}
            </Heading>
            <Flex width={"100%"} direction={"column"} align="center">
                
                <Text mt={5}>
                {WalletStrings.ui.change_w3s_api_token.description}
                </Text>

                <Input
                    mt={5}
                    autoComplete="off"
                    placeholder={WalletStrings.ui.change_w3s_api_token.placeholder}
                    // defaultValue={window.appConfig.web3StorageApiToken}
                    bg={'gray.100'}
                    border={0}
                    w={'100%'}
                    color={'gray.500'}
                    disabled={isLoading}
                    onChange={(e) => {
                        setApiToken(e.target.value);
                    }}
                    _placeholder={{
                    color: 'gray.500',
                    }}
                    />
            </Flex>
            {isLoading &&
            <Flex width={"100%"} direction={"column"} align="center">
                <HStack mt={5} align="center">
                    <Spinner size={"sm"} color="orange" /> 
                    <Text fontSize={"sm"} color="orange">
                    {WalletStrings.ui.change_w3s_api_token.changing}
                    </Text>
                </HStack>
            </Flex>
            }

            <Flex width={"100%"} direction={"column"} align="center">
                <Text mt={5} color="gray.600">
                {WalletStrings.ui.change_w3s_api_token.note}
                </Text>
            </Flex>

            <Button
                fontFamily={'heading'}
                mt={8}
                w={'full'}
                colorScheme={"green"}
                isDisabled={apiToken === null || apiToken.length === 0}
                isLoading={isLoading}
                onClick={() => {
                    changeWeb3StorageAPIToken(null);
                }}>
            {WalletStrings.ui.change_w3s_api_token.apply}
            </Button>
        </VStack>
        
    )
}
function SwitchWalletPanel({wallet, version}) {

    const [walletList, setWalletList] = useState(null);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [isLoading, setLoading] = useState(false);
    useEffect(() => {
        showWallets();
    }, [version]);// eslint-disable-line react-hooks/exhaustive-deps
    
    const showWallets = () => {
        setLoading(true);
        wallet.getWalletList().then(wallets => {
            setWalletList(wallets);
            return getActivatedWallet();
        }).then(wallet => {
            setSelectedWallet(wallet);
            setLoading(false);
            console.log('WalletPanel Selected Wallet:', wallet);
        }).catch(e => {
            console.error(e);
            setLoading(false);
            showError(e);
        })
    }

    const getActivatedWallet = async () => {

        const activatedWalletSummary = await wallet.getActivatedWallet();
        return activatedWalletSummary;

        // const promise = new Promise((resolve, reject) => {
        //     if (wallet.activatedWallet) {
        //         resolve(wallet.activatedWallet);
        //         return;
        //     }
        //     let counter = 0;
        //     const interval = setInterval(() => {
        //         ++counter;
        //         if (counter > 60) {
        //             reject(new Error('Timeout'));
        //             return;
        //         }
        //         if (wallet.activatedWallet) {
        //             clearInterval(interval);
        //             resolve(wallet.activatedWallet);
        //             return;
        //         }
        //     }, 1000);

        // })
        // return promise;
    }

    const switchWallet= (address) => {//eslint-disable-line
        wallet.switchWallet(address,true).then(() => {
            wallet.disclosure.onClose();
        })
    };
    // const getFontWeight = (address) => {
    //     if (!address) {
    //         return false;
    //     }
    //     address = address.toLowerCase();
    //     if (selectedWallet && selectedWallet.address && selectedWallet.address.toLowerCase()  === address) {
    //         return "bold";
    //     }
    //     return "normal";
    // }
    // const getDisplayName = (w) => {
    //     if (!w) {
    //         return 'Unknown';
    //     }
    //     const address = w.address.toLowerCase();
    //     if (selectedWallet && selectedWallet.address && selectedWallet.address.toLowerCase()  === address) {
    //         return `${w.name} (Currently)`;
    //     }
    //     return w.name;
    // }
    const isCurrently = (w) => {
        if (!w) {
            return false;
        }
        const address = w.address.toLowerCase();
        if (selectedWallet && selectedWallet.address && selectedWallet.address.toLowerCase()  === address) {
            return true;
        }
        return false;
    }
    return (
        <VStack >
            <Heading
                color={'gray.800'}
                lineHeight={1.1}
                fontSize={{ base: 'xl', sm: 'xl', md: 'xl' }}>
                    {WalletStrings.ui.selector.title}
            </Heading>
            {isLoading &&
            <WalletListLoader  />
            }

            {!isLoading &&
            <List spacing={0} textColor="gray.600" fontSize={"12px"}>
                {walletList && walletList.map(w => {
                    const selected = isCurrently(w);
                    if (selected) {

                        return (
                            <ListItem key={`wallet-selector-item-${w.address}`} paddingTop={1} paddingBottom={1} borderBottom={"1px solid lightGray"}>
                                <HStack>
                                    <Avatar position={"unset"} name={w.name} textColor={"white"} />
                                    <VStack align={"left"}>
                                        <Text wordBreak={"break-all"} fontWeight={"bold"}>{w.name} (Current)</Text>
                                        <Text wordBreak={"break-all"} fontWeight={"bold"}>{w.address}</Text>
                                    </VStack>
                                </HStack>
                            </ListItem>)
                    }
                    return (
                        <ListItem key={`wallet-selector-item-${w.address}`} paddingTop={1} paddingBottom={1} borderBottom={"1px solid lightGray"} cursor={"pointer"} onClick={()=>{
                            switchWallet(w.address)
                        }}>
                            <HStack>
                                <Avatar position={"unset"} name={w.name} textColor={"white"} />
                                <VStack align={"left"}>
                                    <Text wordBreak={"break-all"} fontWeight={"normal"}>{w.name}</Text>
                                    <Text wordBreak={"break-all"} fontWeight={"normal"}>{w.address}</Text>
                                </VStack>
                            </HStack>
                        </ListItem>)
                })}
            </List>
            }
        </VStack>)
}
function WalletUI({ disclosure, version, status=WalletUIStatus.Choose, onBuy }) {
    // const wallet = useContext(AFWalletContext);
    const [rpcProviderType, setRpcProviderType] = useState(AFWallet.RPCProviderType.Unset);
    const [isUnlocked, setUnlocked] = useState(false);
    const [chainId, setChainId] = useState(window.appConfig.recentChainId);

    useEffect(() => {
        console.log('WalletUI - wallet: ', window.wallet.instanceNo);
        const type = window.wallet.rpcProviderType();
        setRpcProviderType(type);
        window.wallet.unlockIfNotExpired().then(() => {
            return window.wallet.isUnlocked();
        }).then(isUnlocked => {
            setUnlocked(isUnlocked);
        }).catch(e => {
            console.error(e);
        })
        // window.wallet.setUIRpcProviderType = setRpcProviderType;
    }, [status, version]);// eslint-disable-line react-hooks/exhaustive-deps
  
    const menuBg = useColorModeValue('white', 'gray.900');
    const menuBorderColor = useColorModeValue('gray.200', 'gray.700');
    const chainName = (chainId) => {
        if (chainId === '0x1') {
            return 'Mainnet';
        } else if (chainId === '0x5') {
            return 'Goerli';
        } else if (chainId === '0xaa36a7') {
            return 'Sepolia'
        } else if (chainId === '0xa869') {
            return 'Fuji'
        } else if (chainId === '0xa86a') {
            return 'Avalanche'
        } else {
            return 'Unspecified'
        }
    }
    return (
      <Modal
        size={'md'}
        // s
        isOpen={disclosure.isOpen}
        onClose={() => {
            disclosure.onClose()

            if (window.wallet.pendingRequest) {
                window.wallet.onConfirmed(false).then(() => {}).catch(e => {
                    // showError(e);
                    // disclosure.onClose()
                })
            } else {
                // disclosure.onClose();

            }
            if (window.wallet.pendingBackup) {
                window.wallet.pendingBackup.reject(AFWalletRPCError.userRejectedRequest);
                window.wallet.pendingBackup = null;
            }
        }}
        closeOnOverlayClick={false}
        // scrollBehavior={"inside"}
        // isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            
            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && (status === WalletUIStatus.SendTransaction || status === WalletUIStatus.Sign)) &&
                
            <Box align={"center"}><Menu>
                    <MenuButton
                    py={2}
                    transition="all 0.3s"
                    _focus={{ boxShadow: 'none' }}
                    disabled={true}
                    >
                        <HStack>
                            <Box display={{ base: 'none', md: 'flex' }}>
                            <FaEthereum size={12} />
                            </Box>
                            <Text fontSize="md" fontWeight={"bold"}> 
                                {chainName(chainId)}
                            </Text>
                            {/* <Box display={{ base: 'none', md: 'flex' }}>
                            <FiChevronDown />
                            </Box> */}
                        </HStack>
                    </MenuButton>
                    
                    <MenuList
                    bg={menuBg}
                    borderColor={menuBorderColor}>
                    
                        <MenuItem onClick={() => {
                        }}>
                            <HStack>
                            <Text fontSize="xs">
                                Mainnet
                            </Text>
                            </HStack>
                        </MenuItem>
                        <MenuItem onClick={() => {
                        }}>
                            <HStack>
                            <Text fontSize="xs">
                                Goerli
                            </Text>
                            </HStack>
                        </MenuItem>
                        
                        <MenuItem onClick={() => {
                        }}>
                            <HStack>
                            <Text fontSize="xs">
                                Sepolia
                            </Text>
                            </HStack>
                        </MenuItem>
                    </MenuList>
                </Menu>
            </Box>
            }
            {/* {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status !== WalletUIStatus.Backup) && */}
            
            {/* {!(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.Wallet) && */}
            <ModalCloseButton />
            {/* } */}
            {/* } */}
          </ModalHeader>
  
          <ModalBody>
            {((rpcProviderType === AFWallet.RPCProviderType.Unset) && (status !== WalletUIStatus.ResetPINCode)) &&
                <ChooseWalletPanel setChainId={setChainId} wallet={window.wallet} status={status} setRpcProviderType={setRpcProviderType} />
            }
            {((rpcProviderType === AFWallet.RPCProviderType.AFWallet) && (!isUnlocked || (status === WalletUIStatus.Unlock) || (status === WalletUIStatus.VerifyPinCode))) &&
                <UnlockPanel wallet={window.wallet} onComplete={ (pinCode, error) => {
                    if (error) {
                        console.error(error);
                        showError(error);
                        disclosure.onClose();
                        if (status === WalletUIStatus.VerifyPinCode) {
                            window.wallet.onPinCodeVerified(pinCode, error)
                        }
                    } else {
                        if (status === WalletUIStatus.Unlock) {
                            window.wallet.getOrderedSelectedAddresses().then(accounts => {
                                return window.wallet.confirmConnect(accounts, null);
                            }).then(() => {
                                disclosure.onClose();
                            }).catch(e => {
                                console.error(e);
                                showError(e);
                            });
                        } else if (status === WalletUIStatus.VerifyPinCode) {
                            window.wallet.onPinCodeVerified(pinCode, error);
                        } else {
                            setUnlocked(true);
                        }
                    }
                }} />
            }
            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.Connect) &&
                <ConnectPanel wallet={window.wallet} />
            }

            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.Wallet) &&
                <WalletPanel wallet={window.wallet} version={version} onBuy={() => {
                    onBuy();
                }} onMigrate={() => {
                    window.wallet.showUI(WalletUIStatus.Migrate);
                }} />
            }

            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.SwitchWallet) &&
                <SwitchWalletPanel wallet={window.wallet} version={version} />
            }

            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.SendTransaction) &&
                <SendTransactionPanel wallet={window.wallet} />
            }

            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.Sign) &&
                <SignPanel wallet={window.wallet} />
            }

            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.Backup) &&
                <BackupWalletPanel wallet={window.wallet} />
            }
            
            {/* {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && (status === WalletUIStatus.ChangePINCode || status === WalletUIStatus.ResetPINCode)) && */}
            {(status === WalletUIStatus.ChangePINCode || status === WalletUIStatus.ResetPINCode) &&
                <ChangePINCodePanel wallet={window.wallet} status={status} setRpcProviderType={setRpcProviderType} />
            }

            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.Migrate) &&
                <MigrationPanel wallet={window.wallet} />
            }
            
            {(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.ChangeWeb3StorageAPIToken) &&
                <ChangeWeb3StorageAPITokenPanel wallet={window.wallet} />
            }


          </ModalBody>
  
          <ModalFooter>
            {/* <Button
              colorScheme="red"
              onClick={() => {
                disclosure.onClose();
              }}
            >
            Close
            </Button> */}
            {/* {!(rpcProviderType === AFWallet.RPCProviderType.AFWallet && isUnlocked && status === WalletUIStatus.Wallet) &&
            <Button
            colorScheme="gray"
            onClick={() => {
              disclosure.onClose();
            }}
            >
            Close
            </Button>
            } */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
}
  
export default WalletUI;
  