import { USE_DAG_FILE } from "../../common/constants";

import lighthouse from '@lighthouse-web3/sdk';
import axios from 'axios';
import { StorageProvider } from "../../utils/Types";
import { BaseWeb3Storage } from "./BaseWeb3Storage";
import CID from "cids";

class LighthouseWeb3Storage extends BaseWeb3Storage {

    #apiKey = null;
    #backup = false;
    constructor(backup=false) {
        super();
        this.#backup = backup;
        console.log('LighthouseWeb3Storage - constructor')
    }
    get type() {
        return StorageProvider.LightHouse;
    }
    isApiKeyConfigured() {
        if (this.#backup) {
            return window.appConfig.isBackupLightHouseApiKeyConfigured;
        }
        return window.appConfig.isLightHouseApiKeyConfigured;
    }

    saveApiKey(apiKey) {
        if (this.#backup) {
            window.appConfig.backupLightHouseApiKey = apiKey;
        } else {
            window.appConfig.lightHouseApiKey = apiKey;
        }
    }
    loadApiKey() {
        if (this.#backup) {
            return window.appConfig.backupLightHouseApiKey;
        } 
        return window.appConfig.lightHouseApiKey;
    }
    getWallet() {
        if (this.#backup) {
            return window.wallet.backupWallet;
        } else {
            return window.wallet.activatedWallet;
        }
    }
    async authenticate() {

        if (this.isApiKeyConfigured()) {
            this.#apiKey = this.loadApiKey();
        } else {
            // const address = window.wallet.activatedWallet.address;
            const address = this.getWallet().address;

            const verificationMessage = (
                await axios.get(`https://api.lighthouse.storage/api/auth/get_message?publicKey=${address}`)
            ).data;

            // const signedMessage = await window.wallet.activatedWallet.signMessage(verificationMessage);
            const signedMessage = await this.getWallet().signMessage(verificationMessage);
            const response = await lighthouse.getApiKey(address, signedMessage);
            if (response && response.data && response.data.apiKey) {
                const apiKey = response.data.apiKey;

                this.#apiKey = apiKey;
                this.saveApiKey(apiKey)
                // window.appConfig.lightHouseApiKey = apiKey;
            }
        }
        return this.#apiKey;
    }

    async isAuthenticated() {
        // return window.appConfig.isLightHouseApiKeyConfigured;
        return this.isApiKeyConfigured();
    }

    async loadAgent() {
        // if (window.appConfig.isLightHouseApiKeyConfigured) {
        //     if (window.appConfig.lightHouseApiKey && window.appConfig.lightHouseApiKey.length > 0) {
        //         this.#apiKey = window.appConfig.lightHouseApiKey;
        //     }
        // }
        if (this.isApiKeyConfigured()) {
            const key = this.loadApiKey();
            if (key && key.length > 0) {
                // this.#apiKey = window.appConfig.lightHouseApiKey;
                this.#apiKey = this.loadApiKey();
            }
        }
    }

    async unloadAgent() {
        this.#apiKey = null;
    }

    async reset() {
        await this.unloadAgent();
        this.saveApiKey('');
        // window.appConfig.lightHouseApiKey = '';
    }

    async forceReset() {
        await this.unloadAgent();
        // window.appConfig.lightHouseApiKey = '';
        this.saveApiKey('');
    }

    async storageFreeSize() {
        // const address = window.wallet.activatedWallet.address;
        const res = await lighthouse.getBalance(this.#apiKey);
        if (!res || !res.data) {
            return 0;
        }
        return res.data.dataLimit - res.data.dataUsed;
    } 

    async hasEnoughSpace(file) {
        const freeSpace = await this.storageFreeSize();
        return freeSpace - file.size > 102400; 
    }

    async put(files, options) {
        if (USE_DAG_FILE) {
            throw new Error('unimplemented put files as a dig')
            // lighthouse.uploadBuffer(files[0]);
        } else {
            const file = files[0];
            // const a = new File();
            // const buffer = a.arrayBuffer();
            // const buffer = await file.arrayBuffer();
            const has = await this.hasEnoughSpace(file);
            if (!has) {
                throw new Error('No enough storage. You can get more storage from https://files.lighthouse.storage');
            }
            const result = await lighthouse.uploadBuffer(files[0], this.#apiKey);
            return result ? result.data.Hash : null;
        }
    }

    async list(options) {
        const list = await lighthouse.getUploads(this.#apiKey);
        return list;
    }

    async remove(cid, options) {
    }

    toCidV1(cid) {
        const cidV0 = new CID(cid);
        const cidV1 = new CID(1, 'dag-pb', cidV0.multihash, 'base32');
        // const cidV1 = cidV0.toV1();
        return cidV1;
    }
}

export default LighthouseWeb3Storage;
