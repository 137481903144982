export const INBOX_FOLDER = 'inbox';
export const SENT_FOLDER = 'sent';
export const TRASH_FOLDER = 'trash';
export const CONTRACT_FOLDER = 'contract';
export const BACKUP_FOLDER = 'backup';


// export const HELP_DOC_URL = 'https://ai-fi.cc/help.html';
export const HELP_DOC_URL = 'https://ai-fi.cc/blog-single.html?article=principles-of-operation';

export const CONTACT_NAME_ME = 'Me';
export const CONTACT_NAME_ME_LOWERCASED = 'me';


// export const isSafari = ((window.navigator.vendor && window.navigator.vendor.toLowerCase() === 'apple computer, inc.') && (window.navigator.userAgent && window.navigator.userAgent.toLowerCase().indexOf('safari') !== -1))
export const isSafari = false;
export const userAgent = window.navigator.userAgent;
export const isAndroid = /(Android)/i.test(userAgent)
export const isIphone = /(iPhone|iPod)/i.test(userAgent)
export const isIpad = /(iPad)/i.test(userAgent)
export const isMobile = isIphone || isIpad || isAndroid;
// A mobile browser with ethereum we assume it's Metamask Browser
export const isMobileWeb3Browser = isMobile && (window.ethereum ? true : false);
// const isDesktopSafari = !isMobile && isSafari;
export const isUnsupportedBrowser = (isMobile ? !isMobileWeb3Browser : isSafari) && !window.isBrave;
export const isSupportedBrowser = (isMobile ? isMobileWeb3Browser : !isSafari) || window.isBrave;

export const homeScreenHasMenu = false;

export function mailAddressDomain() {
    return 'ai-fi.cc';
}
export function mailAddressSuffix() {
    return '@' + mailAddressDomain();
    // const url = new URL(window.location);
    // return "@" + url.hostname;
}

export const web3 = () => {
    if (window.magicWeb3) {
        return window.magicWeb3;
    }
}

export const RECOVER_WEB3_CONFIG_AUTOMATICALLY = false;
export const USE_DAG_FILE = false;
export const PLEXI_MAIL_VERSION = '1';

/*
const isSafari = false;
const userAgent = window.navigator.userAgent;
const isAndroid = /(Android)/i.test(userAgent)
const isIphone = /(iPhone|iPod)/i.test(userAgent)
const isIpad = /(iPad)/i.test(userAgent)
const isMobile = isIphone || isIpad || isAndroid;
// A mobile browser with ethereum we assume it's Metamask Browser
const isMobileWeb3Browser = isMobile && (window.ethereum ? true : false);
// const isDesktopSafari = !isMobile && isSafari;
const isUnsupportedBrowser = (isMobile ? !isMobileWeb3Browser : isSafari) || !window.isBrave;
const isSupportedBrowser = (isMobile ? isMobileWeb3Browser : !isSafari) || window.isBrave;
*/