import {
  Box,
  Flex,
  Stack,
  Heading,
  Text,
  Container,
  Button,
  SimpleGrid,
  Link,
  Spinner,
  HStack,
  Avatar,
  Icon,
  AvatarGroup,
  // useColorModeValue,
  useBreakpointValue,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import {
  SetupWizardState,
  EthereumChains,
  PlexiMailStatus,
  SignalInitStep /*, MnemonicBackupMethod, RecoveryState*/,
} from "../../utils/Types";
// import { HELP_DOC_URL } from '../common/constants';
import { TutorialModalLink } from "../TutorialModalTrigger";
import { ClientError, showError } from "../../common/errors";

// import MailAddressUtils from '../../utils/MailAddressUtils';
// import { ConfirmationCloseButton } from '../ConfirmationButton';
// import jwt from 'jsonwebtoken';

import { BACKUP_FOLDER } from "../../common/constants";

import AFWallet from "../../wallet/AFWalletV2";

import { AiOutlineSound, AiFillSound } from "react-icons/ai";
import EnterpriseStrings from "./EnterpriseStrings";

const avatars = [
  {
    name: "Ryan Florence",
    url: "avatar/ryan-florence.jpeg",
  },
  {
    name: "Segun Adebayo",
    url: "avatar/prosper-baba.jpeg",
  },
  {
    name: "Kent Dodds",
    url: "avatar/kent-c-dodds.jpeg",
  },
  {
    name: "Prosper Otemuyiwa",
    url: "avatar/girl.jpeg",
  },
  {
    name: "Christian Nwamba",
    url: "avatar/code-beast.jpeg",
  },
];

export default function ResumeScreen({
  setSetupWizardState,
  setAccounts,
  setSelectedAccount,
  faucetDisclosure,
}) {
  // TODO: account
  // const selectedAccount = ''

  const [isPlaying, setPlaying] = useState(false);
  const [isRunning, setRunning] = useState(false);
  const [resendMessage, setResentMessage] = useState(null);
  const [verifyMessage, setVerifyMessage] = useState(null);
  const [progress, setProgress] = useState(null);
  var [resentInterval, setResentInterval] = useState(-1);
  const [createSpaceStep, setCreateSpaceStep] = useState(1);

  useEffect(() => {}, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function changeNetwork(newChainId) {
    window.chainId = newChainId;

    if (newChainId === EthereumChains.Goerli) {
      window.web3Helper.init("goerli");
    } else if (newChainId === EthereumChains.Mainnet) {
      window.web3Helper.init("mainnet");
    } else if (newChainId === EthereumChains.Fuji) {
      window.web3Helper.init("fuji");
    } else if (newChainId === EthereumChains.Avalanche) {
      window.web3Helper.init("mainnet");
    } else if (newChainId === EthereumChains.Sepolia) {
      // window.web3Helper.init('sepolia');

      const sepOpts = {
        // rpcUrl: 'https://rpc.sepolia.org', // your ethereum, polygon, or optimism mainnet/testnet rpc URL
        // rpcUrl: 'https://polygon-mumbai-infura.wallet.coinbase.com?targetName=ethereum-sepolia',
        rpcUrl:
          "https://eth-sepolia.g.alchemy.com/v2/tApGyKU4AgHcem9l6xFsuMCdVe6mah6P",
        // rpcUrl: 'https://sepolia.rpc.thirdweb.com',
        chainId: 11155111,
      };
      window.web3Helper.init(sepOpts);
    } else {
      window.web3Helper.init("fuji");
    }
  }

  async function prepareToken(account) {
    try {
      await window.mailService.checkToken(window.appConfig.pushApiToken);
    } catch (e) {
      console.error(e);
      await authenticate(account);
    }
  }

  async function getNonce(account) {
    // const nonce = randomBytes(32);
    // const nonceInHex = bufferToHex(nonce);
    // return 'Hi there! Your special nonce: ' + nonceInHex;
    // // return fetch()
    const nonce = await window.mailService.getNonce(account);
    return nonce;
  }

  async function authenticate(account) {
    const message = await getNonce(account);
    // const msg = `0x${Buffer.from(exampleMessage, 'utf8').toString('hex')}`;
    // personal_sign
    const pwd =
      window.wallet && window.wallet.asDefaultWallet
        ? "--sign-the-message-silently--"
        : "";
    const web3 = window.web3Helper.web3;
    const signatureHex = await web3.eth.personal.sign(message, account, pwd);
    // const signatureHex = await window.web3Helper.ethereum.request({
    //   method: "personal_sign",
    //   params: [
    //     message,                    // 待签名的数据
    //     account
    //   ]
    // });
    const recoveredAddr = window.web3Helper.ecRecover({
      data: message,
      signature: signatureHex,
    });
    if (recoveredAddr.toLowerCase() === account) {
      window.appConfig.pushApiToken = await window.mailService.login({
        account: account,
        signature: signatureHex,
      });
    } else {
      throw ClientError.invalidParameterError(
        EnterpriseStrings.error.client.invalid_signature
      );
    }
  }

  const resumeWallet = async () => {
    await window.wallet.recoverFromUrl(window.location.href);
  };

  const connectWallet = async () => {
    if (!window.web3Helper.ethereum) {
      throw ClientError.invalidParameterError("No account in your wallet");
      // return null;
    }

    // await changeNetwork(chainId);
    if (window.appConfig.chainId && window.appConfig.chainId.length >= 0) {
      if (window.appConfig.recentChainId !== window.appConfig.chainId) {
        window.appConfig.recentChainId = window.appConfig.chainId;
      }
    } else {
      if (
        window.appConfig.recentChainId &&
        window.appConfig.recentChainId.length > 0
      ) {
        window.appConfig.chainId = window.appConfig.recentChainId;
      }
    }
    // await changeNetwork(window.appConfig.recentChainId);

    console.log("HomeScreen - connectWallet");
    let accounts = null;
    let rpcProviderType = window.wallet.rpcProviderType();
    if (rpcProviderType === AFWallet.RPCProviderType.Unset) {
      rpcProviderType = await window.wallet.changeRpcProviderType();
    }
    if (rpcProviderType === AFWallet.RPCProviderType.AFWallet) {
      accounts = await window.web3Helper.web3.eth.requestAccounts();
    } else {
      accounts = await window.web3Helper.web3.eth.requestAccounts();
      // if (chainId === EthereumChains.Sepolia) {
      //   accounts = await window.magic.wallet.connectToMetaMask();
      // } else {
      //   accounts = await window.magic.wallet.connectWithUI();
      // }
    }

    // const accounts = await window.web3Helper.web3.eth.requestAccounts();

    const selectedAddress =
      accounts.length > 0 ? accounts[0].toLowerCase() : "";
    if (!selectedAddress || selectedAddress.length === 0) {
      throw ClientError.invalidParameterError("No account in your wallet");
    }

    window.appConfig.recentActiveAccount = selectedAddress;
    window.appConfig.migrateIfNeeded(selectedAddress);
    // window.appConfig.chainId = chainId;

    if (window.appConfig.recentChainId !== window.appConfig.chainId) {
      window.appConfig.chainId = window.appConfig.recentChainId;
      await changeNetwork(window.appConfig.recentChainId);
    }

    if (
      window.appConfig.tempMasterKey &&
      window.appConfig.tempMasterKey.length > 0 &&
      (!window.appConfig.masterKey || window.appConfig.masterKey === "")
    ) {
      window.appConfig.masterKey = window.appConfig.tempMasterKey;
    }
    if (
      window.appConfig.tempMnemonic &&
      window.appConfig.tempMnemonic.length > 0 &&
      (!window.appConfig.mnemonic || window.appConfig.mnemonic === "")
    ) {
      window.appConfig.mnemonic = window.appConfig.tempMnemonic;
    }

    const accountIndex = window.appConfig.assignAccountIndex(selectedAddress);
    window.mailService.selectedAccountIndex = accountIndex;
    window.mailService.selectedAccount = selectedAddress;

    if (window.appConfig.isPushApiTokenConfigured) {
      await prepareToken(selectedAddress);
    } else {
      await authenticate(selectedAddress);
    }

    setAccounts(accounts);
    setSelectedAccount(selectedAddress);
    // await window.mailService.initCryptServiceIfNeeded();
    // window.appConfig.tempMnemonic = window.appConfig.mnemonic;
    // await window.mailService.initWeb3StorageClient(window.appConfig.web3StorageApiToken);
  };
  const resume = async () => {
    await web3UIResume();
  };

  const web3UIResume = async () => {
    try {
      setRunning(true);
      setProgress("Resuming wallet...");

      // const isConfigured = await window.wallet.isConfigured();
      await resumeWallet();

      setProgress("Connecting wallet...");
      await connectWallet();

      await resumePlexiMail();
    } catch (e) {
      console.error(e);

      showError(e);

      await reset();
    }
  };

  const resumePlexiMail = async () => {
    setProgress(EnterpriseStrings.common.message.init_web3_storage);
    await initW3UI();

    await gotoMainScreen();
  };

  const initW3UI = async () => {
    await w3UIAuthenticate();
    if (resentInterval !== -1) {
      clearInterval(resentInterval);
      setResentInterval(-1);
    }
    setResentMessage(null);
    setVerifyMessage(null);

    await window.mailService.initWeb3StorageClient(
      window.appConfig.web3StorageApiToken
    );
    let folders = null;
    try {
      folders = await window.mailService.getFolders();
    } catch (e) {
      console.error(e);
    } finally {
      if (folders) {
        await w3UIResumeWeb3StorageConfig();
      } else {
        await window.mailService.initFoldersIfNeeded(false);
        await initSignal();
      }
    }
  };

  const initSignal = async () => {
    setProgress("Initializing Signal Runtime Environment...");
    // addBinding(foldersIpnsRecord, prekeysIpnsRecord, signedPrekeySignature)
    // if (window.mailService.signalIsInitialized) {
    //   await window.mailService.resetSignalServiceFor(localSelectedAddress);
    // }

    const isInitialized = window.mailService.signalIsInitialized(
      window.mailService.selectedAccount
    );
    if (isInitialized) {
      await window.mailService.resetSignalServiceFor(
        window.mailService.selectedAccount
      );
    }

    await window.mailService.initSignalService(
      window.appConfig.recentActiveAccount,
      (step) => {
        switch (step) {
          case SignalInitStep.CheckPreKeyCount:
            setProgress(EnterpriseStrings.common.message.supply_prekeys);
            break;
          case SignalInitStep.GenerateKeys:
            setProgress(EnterpriseStrings.common.message.generate_keys);
            break;
          case SignalInitStep.SavePreKeyBundle:
            setProgress(EnterpriseStrings.common.message.save_prekey);
            break;
          case SignalInitStep.SaveIdentityKey:
            setProgress(EnterpriseStrings.common.message.save_idkey);
            break;
          // case SignalInitStep.WaitTransactionReceipt:
          //     setProgress(Strings.common.message.wait_tx_receipt);
          //     break;
          case SignalInitStep.SaveSignalProtocolStore:
            setProgress(EnterpriseStrings.common.message.save_signal);
            break;
          case SignalInitStep.Completed:
            setProgress(EnterpriseStrings.common.message.completed);
            break;
          default:
            showError(ClientError.invalidParameterError("Invalid step"));
            break;
        }
      }
    );

    window.appConfig.removeTempMasterKey();
    window.appConfig.removeTempMnemonic();
    window.appConfig.removeTxHash();
    window.appConfig.removeCouponInfo();
    window.appConfig.removeTempCryptonInfo();
    window.appConfig.removeSetupFromRecovery();
    window.appConfig.isNewCreatedUser = false;
    window.appConfig.plexiMailStatus = PlexiMailStatus.Ready;
    window.wallet.removePinFromSessionStorageIfNeeded();
  };

  const gotoMainScreen = async () => {
    setSetupWizardState(SetupWizardState.Completed);
    window.appConfig.setupWizardState = SetupWizardState.Completed;
    window.isUnlocked = true;
  };

  async function w3UIResumeWeb3StorageConfig() {
    await window.mailService.initWeb3StorageClient(
      window.appConfig.web3StorageApiToken
    );
    const folders = await window.mailService.getFolders();

    const backup = folders[BACKUP_FOLDER];
    Object.keys(backup.localStorage).forEach(function (key) {
      if (key.lastIndexOf("app.private-level") !== -1) {
        return;
      }
      if (!window.appConfig.privacyLevelIsNormal) {
        if (key.lastIndexOf(".app.setup-wizard-state") !== -1) {
          return;
        }
      }
      localStorage.setItem(key, backup.localStorage[key]);
    });

    if (!window.appConfig.privacyLevelIsNormal) {
      window.appConfig.setupWizardState =
        backup.localStorage[
          window.appConfig.recentActiveAccount + ".app.setup-wizard-state"
        ];
    }

    await window.mailService.importIndexedDB(backup.indexedDB);

    await window.mailService.initSignalService(
      window.appConfig.recentActiveAccount
    );

    window.appConfig.removeTempMasterKey();
    window.appConfig.removeTempMnemonic();
    window.appConfig.removeTxHash();
    window.appConfig.removeCouponInfo();
    window.appConfig.removeTempCryptonInfo();
    window.appConfig.removeSetupFromRecovery();
    window.appConfig.isNewCreatedUser = false;
    window.appConfig.plexiMailStatus = PlexiMailStatus.Ready;

    window.wallet.removePinFromSessionStorageIfNeeded();
  }

  async function w3UIAuthenticate() {
    let interval = -1;
    let counter = 0;
    try {
      setVerifyMessage(
        `${EnterpriseStrings.resume.w3ui.verify.message_1} ${window.appConfig.web3StorageApiToken} ${EnterpriseStrings.resume.w3ui.verify.message_2} `
      );
      setCreateSpaceStep(1);
      const store = localStorage.getItem("app.shared-storage");

      let client = await window.mailService.web3StorageClient.loadAgent();
      console.log("w3up client:", client);

      const isAuthenticated =
        await window.mailService.web3StorageClient.isAuthenticated();
      if (isAuthenticated) {
        const isSpaceRegistered =
          window.mailService.web3StorageClient.isSpaceRegistered();
        if (!isSpaceRegistered) {
          const spaces = window.mailService.web3StorageClient.spaces();
          if (!spaces || spaces.length === 0) {
            const space =
              store && store.length > 0
                ? await window.mailService.web3StorageClient.addSpace(
                    store,
                    "base64"
                  )
                : await window.mailService.web3StorageClient.createSpace(
                    window.mailService.selectedAccount,
                    (step, msg) => {
                      if (step !== 1 && interval > 0) {
                        clearInterval(interval);
                        interval = -1;
                        setResentInterval(-1);
                        resentInterval = -1;
                      }
                      setCreateSpaceStep(step);
                      setResentMessage(msg);
                    }
                  );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          } else {
            const space =
              window.mailService.web3StorageClient.getSpaceByName(
                window.mailService.selectedAccount
              ) || spaces[0];
            await window.mailService.web3StorageClient.checkSpace(
              space,
              (step, msg) => {
                if (step !== 1 && interval > 0) {
                  clearInterval(interval);
                  interval = -1;
                  setResentInterval(-1);
                  resentInterval = -1;
                }
                setCreateSpaceStep(step);
                setResentMessage(msg);
              }
            );
            window.mailService.web3StorageClient.setCurrentSpace(space.did());
            await window.mailService.web3StorageClient.register(space);
          }
        }
        localStorage.removeItem("app.shared-storage");
        return;
      } else {
        await window.mailService.web3StorageClient.forceReset();
        client = await window.mailService.web3StorageClient.loadAgent();
        console.log("w3up client:", client);
      }

      setResentMessage(
        `${EnterpriseStrings.resume.w3ui.verify.message_3} ${60 - counter} ${
          EnterpriseStrings.resume.w3ui.verify.message_4
        }`
      );
      interval = setInterval(() => {
        counter += 5;
        if (counter >= 60) {
          clearInterval(interval);
          setResentInterval(-1);
          setResentMessage(
            <>
              <Link
                href="#"
                fontWeight={"bold"}
                color={"orange"}
                textDecoration={"underline"}
                onClick={() => {
                  resend();
                  return false;
                }}
              >
                {EnterpriseStrings.resume.w3ui.verify.message_5}
              </Link>
              &nbsp;{EnterpriseStrings.resume.w3ui.verify.message_6}
            </>
          );
        } else {
          setResentMessage(
            `${EnterpriseStrings.resume.w3ui.verify.message_3} ${
              60 - counter
            } ${EnterpriseStrings.resume.w3ui.verify.message_4}`
          );
        }
      }, 5000);
      resentInterval = interval;
      setResentInterval(interval);

      await window.mailService.web3StorageClient.authenticate(
        window.appConfig.web3StorageApiToken
      );

      const isSpaceRegistered =
        window.mailService.web3StorageClient.isSpaceRegistered();
      if (!isSpaceRegistered) {
        const spaces = window.mailService.web3StorageClient.spaces();
        if (!spaces || spaces.length === 0) {
          // const space = await window.mailService.web3StorageClient.createSpace(window.mailService.selectedAccount, (step, msg) => {
          //   if (step !== 1 && interval > 0) {
          //     clearInterval(interval);
          //     interval = -1;
          //     setResentInterval(-1);
          //     resentInterval = -1;
          //   }
          //   setCreateSpaceStep(step);
          //   setResentMessage(msg);
          // });

          // const space = await window.mailService.web3StorageClient.addSpace(store, 'base64');
          const space =
            store && store.length > 0
              ? await window.mailService.web3StorageClient.addSpace(
                  store,
                  "base64"
                )
              : await window.mailService.web3StorageClient.createSpace(
                  window.mailService.selectedAccount,
                  (step, msg) => {
                    if (step !== 1 && interval > 0) {
                      clearInterval(interval);
                      interval = -1;
                      setResentInterval(-1);
                      resentInterval = -1;
                    }
                    setCreateSpaceStep(step);
                    setResentMessage(msg);
                  }
                );

          window.mailService.web3StorageClient.setCurrentSpace(space.did());
          await window.mailService.web3StorageClient.register(space);
        } else {
          const space =
            window.mailService.web3StorageClient.getSpaceByName(
              window.mailService.selectedAccount
            ) || spaces[0];
          await window.mailService.web3StorageClient.checkSpace(
            space,
            (step, msg) => {
              if (step !== 1 && interval > 0) {
                clearInterval(interval);
                interval = -1;
                setResentInterval(-1);
                resentInterval = -1;
              }
              setCreateSpaceStep(step);
              setResentMessage(msg);
            }
          );
          window.mailService.web3StorageClient.setCurrentSpace(space.did());
          await window.mailService.web3StorageClient.register(space);
        }
        localStorage.removeItem("app.shared-storage");
      }
    } catch (err) {
      console.error(err);
      console.log("failed to register: ", err.message || err);
      throw err;
      // if (err.message === 'No space selected') {
      //   try {
      //     await actions.createSpace('pleximail')
      //     await actions.registerSpace(window.appConfig.web3StorageApiToken)
      //   } catch (err) {
      //     throw new Error('failed to register', { cause: err })
      //   }
      // } else if (err.message === 'Space already registered with web3.storage.') {
      //   console.log(err.message)
      // } else if (err.message.indexOf('no proofs available for resource') !== -1) {
      //   console.error(err);
      // } else {
      //   throw new Error('failed to register', { cause: err })
      // }
    } finally {
      if (interval !== -1) {
        clearTimeout(interval);
        interval = -1;
      }
    }
  }

  function reset() {
    setProgress(null);
    setRunning(false);
    setResentMessage(null);
    setVerifyMessage(null);

    window.isUnlocked = false;

    window.appConfig.mnemonic = null;
    window.appConfig.masterKey = null;

    window.mailService
      .reset(true)
      .then(() => {
        return window.wallet.reset(true);
      })
      .then(() => {
        setSetupWizardState(SetupWizardState.ConnectWallet);
        setTimeout(function () {
          window.location.reload(true);
        });
      })
      .catch((e) => {
        console.error(e);
        showError(e);
      });
  }

  const resend = () => {
    setResentMessage(null);
    setTimeout(() => {
      resumePlexiMail()
        .then(() => {})
        .catch((e) => {
          console.error(e);
          showError(e);
        });
    }, 300);
  };

  const endPlaying = () => {
    setPlaying(false);
  };

  const startPlaying = () => {
    setPlaying(true);
  };

  const play = () => {
    let audio = document.getElementById("aifi_audio");
    audio.play();
  };

  const size = useBreakpointValue({ base: "md", md: "lg" });

  return (
    <>
      <Box position={"relative"}>
        <Container
          as={SimpleGrid}
          maxW={"7xl"}
          columns={{ base: 1, lg: 2 }}
          spacing={{ base: 10, lg: 32 }}
          py={{ base: 10, sm: 20, lg: 32 }}
        >
          <Stack spacing={{ base: 10, md: 20 }}>
            <Heading
              lineHeight={1.1}
              fontSize={{ base: "3xl", sm: "4xl", md: "4xl", lg: "4xl" }}
            >
              <HStack alignItems="start">
                <Text>{EnterpriseStrings.resume.promotion.title}</Text>{" "}
                <Link onClick={play} disabled={isPlaying}>
                  {isPlaying && <AiFillSound size={24} />}
                  {!isPlaying && <AiOutlineSound size={24} />}
                </Link>
                <audio
                  id="aifi_audio"
                  onEnded={endPlaying}
                  onPlay={startPlaying}
                >
                  <source src="pronounce.mp3" type="audio/mp3" />
                </audio>
              </HStack>
              <Text
                mt={4}
                fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}
              >
                {EnterpriseStrings.resume.promotion.content.paragraph1}
              </Text>
              <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
                {EnterpriseStrings.resume.promotion.content.paragraph2}
              </Text>
              <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
                {EnterpriseStrings.resume.promotion.content.paragraph3}
              </Text>
              <Text fontSize={{ base: "3xl", sm: "3xl", md: "3xl", lg: "3xl" }}>
                {EnterpriseStrings.resume.promotion.content.paragraph4}
              </Text>
            </Heading>
            <Stack direction={"row"} spacing={4} align={"center"}>
              <AvatarGroup>
                {avatars.map((avatar) => (
                  <Avatar
                    key={avatar.name}
                    name={avatar.name}
                    src={avatar.url}
                    size={size}
                    position={"relative"}
                    zIndex={2}
                    _before={{
                      content: '""',
                      width: "full",
                      height: "full",
                      rounded: "full",
                      transform: "scale(1.125)",
                      bgGradient: "linear(to-bl, red.400, pink.400)",
                      position: "absolute",
                      zIndex: -1,
                      top: 0,
                      left: 0,
                    }}
                  />
                ))}
              </AvatarGroup>
              <Text
                fontFamily={"heading"}
                fontSize={{ base: "4xl", md: "6xl" }}
              >
                +
              </Text>
              <Flex
                align={"center"}
                justify={"center"}
                fontFamily={"heading"}
                fontSize={{ base: "sm", md: "lg" }}
                bg={"gray.800"}
                color={"white"}
                rounded={"full"}
                width={useBreakpointValue({ base: "44px", md: "60px" })}
                height={useBreakpointValue({ base: "44px", md: "60px" })}
                position={"relative"}
                _before={{
                  content: '""',
                  width: "full",
                  height: "full",
                  rounded: "full",
                  transform: "scale(1.125)",
                  bgGradient: "linear(to-bl, orange.400,yellow.400)",
                  position: "absolute",
                  zIndex: -1,
                  top: 0,
                  left: 0,
                }}
              >
                YOU
              </Flex>
            </Stack>
          </Stack>

          <Stack
            bg={"gray.50"}
            rounded={"xl"}
            p={{ base: 4, sm: 6, md: 8 }}
            spacing={{ base: 8 }}
            maxW={{ lg: "lg" }}
          >
            <Stack spacing={4}>
              <Heading
                color={"gray.800"}
                lineHeight={1.1}
                fontSize={{ base: "2xl", sm: "3xl", md: "4xl" }}
              >
                {EnterpriseStrings.resume.signIn.title_1}
                <Text
                  as={"span"}
                  bgGradient="linear(to-r, red.400,pink.400)"
                  bgClip="text"
                >
                  &nbsp;Zero&nbsp;
                </Text>
                {EnterpriseStrings.resume.signIn.title_2}
              </Heading>
              <Text fontSize={{ base: "md", sm: "lg" }} fontWeight="bold">
                {EnterpriseStrings.resume.signIn.description_1}
              </Text>

              <Text color={"gray.500"} fontSize={{ base: "sm", sm: "md" }}>
                {EnterpriseStrings.resume.signIn.description_3}{" "}
                <TutorialModalLink
                  title={"Learn more"}
                  anchor={"pleximail-address"}
                />
              </Text>

              <Text color={"black"} fontSize={{ base: "sm", sm: "md" }}>
                {EnterpriseStrings.resume.signIn.note}
              </Text>
            </Stack>
            <Box as={"form"} mt={10}>
              <Button
                fontFamily={"heading"}
                mt={4}
                w={"full"}
                bgGradient="linear(to-r, red.400,pink.400)"
                color={"white"}
                // onClick={connectWallet}
                onClick={() => {
                  resume()
                    .then(() => {})
                    .catch((e) => {});
                }}
                isLoading={false}
                _hover={{
                  bgGradient: "linear(to-r, red.400,pink.400)",
                  boxShadow: "xl",
                }}
              >
                <Text>{EnterpriseStrings.resume.signIn.continue}</Text>
              </Button>
            </Box>
            {isRunning && (
              <Box as={"form"} mt={10}>
                <HStack>
                  <Spinner color={"#00aaff"} size={"sm"} />
                  <Text
                    color={"#00aaff"}
                    fontWeight={"bold"}
                    fontSize={{ base: "lg", sm: "lg" }}
                  >
                    {progress}
                  </Text>
                </HStack>

                {verifyMessage && verifyMessage.length > 0 && (
                  <>
                    {createSpaceStep === 1 && (
                      <Text
                        color={"#00aaff"}
                        fontSize={{ base: "sm", sm: "sm" }}
                      >
                        {verifyMessage}&nbsp;{resendMessage || ""}
                      </Text>
                    )}
                    {createSpaceStep !== 1 && (
                      <Text
                        color={"#00aaff"}
                        fontSize={{ base: "sm", sm: "sm" }}
                      >
                        {resendMessage || ""}
                      </Text>
                    )}
                  </>
                )}
              </Box>
            )}
          </Stack>
        </Container>
        <Blur
          position={"absolute"}
          top={-10}
          left={-10}
          style={{ filter: "blur(70px)" }}
        />
      </Box>
    </>
  );
}

export const Blur = (props) => {
  return (
    <Icon
      width={useBreakpointValue({ base: "100%", md: "40vw", lg: "30vw" })}
      zIndex={useBreakpointValue({ base: -1, md: -1, lg: 0 })}
      height="560px"
      viewBox="0 0 528 560"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="71" cy="61" r="111" fill="#F56565" />
      <circle cx="244" cy="106" r="139" fill="#ED64A6" />
      <circle cy="291" r="139" fill="#ED64A6" />
      <circle cx="80.5" cy="189.5" r="101.5" fill="#ED8936" />
      <circle cx="196.5" cy="317.5" r="101.5" fill="#ECC94B" />
      <circle cx="70.5" cy="458.5" r="101.5" fill="#48BB78" />
      <circle cx="426.5" cy="-0.5" r="101.5" fill="#4299E1" />
    </Icon>
  );
};
