export const idlFactory = ({ IDL }) => {
  const CandyShared = IDL.Rec();
  const Tree = IDL.Rec();
  const Tree_1 = IDL.Rec();
  const AutoScalingCanisterSharedFunctionHook = IDL.Func(
      [IDL.Text],
      [IDL.Text],
      [],
    );
  const ScalingLimitType = IDL.Variant({
    'heapSize' : IDL.Nat,
    'count' : IDL.Nat,
  });
  const ScalingOptions = IDL.Record({
    'autoScalingHook' : AutoScalingCanisterSharedFunctionHook,
    'sizeLimit' : ScalingLimitType,
  });
  const SK = IDL.Text;
  const Color = IDL.Variant({ 'B' : IDL.Null, 'R' : IDL.Null });
  const AttributeKey = IDL.Text;
  const AttributeValuePrimitive = IDL.Variant({
    'int' : IDL.Int,
    'float' : IDL.Float64,
    'bool' : IDL.Bool,
    'text' : IDL.Text,
  });
  const PropertyShared = IDL.Record({
    'value' : CandyShared,
    'name' : IDL.Text,
    'immutable' : IDL.Bool,
  });
  CandyShared.fill(
    IDL.Variant({
      'Int' : IDL.Int,
      'Map' : IDL.Vec(IDL.Tuple(IDL.Text, CandyShared)),
      'Nat' : IDL.Nat,
      'Set' : IDL.Vec(CandyShared),
      'Nat16' : IDL.Nat16,
      'Nat32' : IDL.Nat32,
      'Nat64' : IDL.Nat64,
      'Blob' : IDL.Vec(IDL.Nat8),
      'Bool' : IDL.Bool,
      'Int8' : IDL.Int8,
      'Ints' : IDL.Vec(IDL.Int),
      'Nat8' : IDL.Nat8,
      'Nats' : IDL.Vec(IDL.Nat),
      'Text' : IDL.Text,
      'Bytes' : IDL.Vec(IDL.Nat8),
      'Int16' : IDL.Int16,
      'Int32' : IDL.Int32,
      'Int64' : IDL.Int64,
      'Option' : IDL.Opt(CandyShared),
      'Floats' : IDL.Vec(IDL.Float64),
      'Float' : IDL.Float64,
      'Principal' : IDL.Principal,
      'Array' : IDL.Vec(CandyShared),
      'ValueMap' : IDL.Vec(IDL.Tuple(CandyShared, CandyShared)),
      'Class' : IDL.Vec(PropertyShared),
    })
  );
  const AttributeValueRBTreeValue = IDL.Variant({
    'int' : IDL.Int,
    'float' : IDL.Float64,
    'tuple' : IDL.Vec(AttributeValuePrimitive),
    'blob' : IDL.Vec(IDL.Nat8),
    'bool' : IDL.Bool,
    'candy' : CandyShared,
    'text' : IDL.Text,
    'arrayBool' : IDL.Vec(IDL.Bool),
    'arrayText' : IDL.Vec(IDL.Text),
    'arrayInt' : IDL.Vec(IDL.Int),
    'arrayFloat' : IDL.Vec(IDL.Float64),
  });
  Tree_1.fill(
    IDL.Variant({
      'leaf' : IDL.Null,
      'node' : IDL.Tuple(
        Color,
        Tree_1,
        IDL.Tuple(IDL.Text, IDL.Opt(AttributeValueRBTreeValue)),
        Tree_1,
      ),
    })
  );
  const AttributeValue = IDL.Variant({
    'int' : IDL.Int,
    'float' : IDL.Float64,
    'tuple' : IDL.Vec(AttributeValuePrimitive),
    'blob' : IDL.Vec(IDL.Nat8),
    'bool' : IDL.Bool,
    'candy' : CandyShared,
    'text' : IDL.Text,
    'tree' : Tree_1,
    'arrayBool' : IDL.Vec(IDL.Bool),
    'arrayText' : IDL.Vec(IDL.Text),
    'arrayInt' : IDL.Vec(IDL.Int),
    'arrayFloat' : IDL.Vec(IDL.Float64),
  });
  Tree.fill(
    IDL.Variant({
      'leaf' : IDL.Null,
      'node' : IDL.Tuple(
        Color,
        Tree,
        IDL.Tuple(AttributeKey, IDL.Opt(AttributeValue)),
        Tree,
      ),
    })
  );
  const AttributeMap = IDL.Variant({
    'leaf' : IDL.Null,
    'node' : IDL.Tuple(
      Color,
      Tree,
      IDL.Tuple(AttributeKey, IDL.Opt(AttributeValue)),
      Tree,
    ),
  });
  const TimeInMs = IDL.Int;
  const SignalDeviceID = IDL.Nat8;
  const SignalName = IDL.Text;
  const RegistrationID = IDL.Int32;
  const SignalCiphertext = IDL.Record({
    'body' : IDL.Text,
    'type' : IDL.Int32,
    'registrationId' : RegistrationID,
  });
  const SignalDevice = IDL.Record({
    'ciphertext' : SignalCiphertext,
    'deviceId' : SignalDeviceID,
  });
  const SignalAccount = IDL.Record({
    'account' : SignalName,
    'devices' : IDL.Vec(SignalDevice),
  });
  const SignaHeader = IDL.Record({
    'fromAddress' : SignalName,
    'fromDeviceId' : SignalDeviceID,
    'accounts' : IDL.Vec(SignalAccount),
  });
  const PlexiEnvelope = IDL.Record({
    'am' : IDL.Nat8,
    'ct' : IDL.Opt(IDL.Text),
    'to' : IDL.Opt(IDL.Vec(IDL.Text)),
    'mid' : IDL.Nat32,
    'ref' : IDL.Opt(IDL.Text),
    'uid' : IDL.Text,
    'expires' : TimeInMs,
    'from' : IDL.Text,
    'salt' : IDL.Opt(IDL.Text),
    'type' : IDL.Text,
    'encryptedHeader' : IDL.Text,
    'address' : IDL.Opt(IDL.Text),
    'deviceId' : IDL.Opt(SignalDeviceID),
    'signal' : IDL.Opt(SignaHeader),
    'sendDate' : TimeInMs,
  });
  const WsHelloMessage = IDL.Record({
    'account' : SignalName,
    'deviceId' : SignalDeviceID,
  });
  const WsNotifyMessage = IDL.Record({
    'sender' : IDL.Record({
      'account' : SignalName,
      'deviceId' : SignalDeviceID,
    }),
    'envelopes' : IDL.Vec(PlexiEnvelope),
  });
  const WsAckNumber = IDL.Nat32;
  const WsEchoMessage = IDL.Record({ 'expectedAckNumber' : WsAckNumber });
  const WsErrorMessage = IDL.Record({
    'code' : IDL.Int32,
    'message' : IDL.Text,
  });
  const WsCopiedMessage = IDL.Record({
    'sender' : IDL.Record({
      'account' : SignalName,
      'deviceId' : SignalDeviceID,
    }),
    'envelopes' : IDL.Vec(IDL.Record({ 'uid' : IDL.Text, 'type' : IDL.Text })),
  });
  const WsSentFeebackMessage = IDL.Record({ 'envelope' : PlexiEnvelope });
  const PlexiAppWsMessage = IDL.Variant({
    'hello' : WsHelloMessage,
    'notify' : WsNotifyMessage,
    'echo' : WsEchoMessage,
    'error' : WsErrorMessage,
    'copied' : WsCopiedMessage,
    'sentFeedback' : WsSentFeebackMessage,
  });
  const SignalKeyID = IDL.Int32;
  const SignedPreKeyEntry = IDL.Record({
    'signature' : IDL.Text,
    'publicKey' : IDL.Text,
    'keyId' : SignalKeyID,
  });
  const MonitorAccountResult = IDL.Record({
    'signature' : IDL.Text,
    'signedPreKey' : SignedPreKeyEntry,
    'keyStoreUrl' : IDL.Text,
    'deviceId' : SignalDeviceID,
    'identityKey' : IDL.Text,
    'registrationId' : RegistrationID,
  });
  const QueryAccountResult = IDL.Record({
    'signature' : IDL.Text,
    'keyStoreUrl' : IDL.Text,
    'identityKey' : IDL.Text,
    'devices' : IDL.Vec(IDL.Record({ 'id' : SignalDeviceID })),
  });
  const QueryPreKeyCountResult = IDL.Record({ 'count' : IDL.Nat32 });
  const OneTimePreKeyEntry = IDL.Record({
    'publicKey' : IDL.Text,
    'keyId' : SignalKeyID,
  });
  const RegisterForm = IDL.Record({
    'signature' : IDL.Opt(IDL.Text),
    'signedPreKey' : IDL.Opt(SignedPreKeyEntry),
    'address' : SignalName,
    'deviceId' : SignalDeviceID,
    'identityKey' : IDL.Opt(IDL.Text),
    'oneTimePreKeys' : IDL.Vec(OneTimePreKeyEntry),
    'registrationId' : RegistrationID,
  });
  const PrincipalName = IDL.Text;
  const RetrievePrekeyBundleResult = IDL.Record({
    'signature' : IDL.Text,
    'signedPreKey' : SignedPreKeyEntry,
    'deviceId' : SignalDeviceID,
    'preKey' : OneTimePreKeyEntry,
    'identityKey' : IDL.Text,
    'registrationId' : RegistrationID,
  });
  const SaveCryptonForm = IDL.Record({
    'signature' : IDL.Text,
    'publicKey' : IDL.Text,
    'name' : IDL.Text,
    'crypton' : IDL.Text,
  });
  const Statistics = IDL.Record({
    'bridge' : IDL.Record({ 'incoming' : IDL.Nat64, 'outgoing' : IDL.Nat64 }),
    'prim' : IDL.Record({
      'rts_max_live_size' : IDL.Nat,
      'rts_stable_memory_size' : IDL.Nat,
      'rts_memory_size' : IDL.Nat,
      'rts_total_allocation' : IDL.Nat,
      'rts_max_stack_size' : IDL.Nat,
      'rts_logical_stable_memory_size' : IDL.Nat,
      'rts_heap_size' : IDL.Nat,
      'rts_reclaimed' : IDL.Nat,
      'rts_version' : IDL.Text,
    }),
    'pleximail' : IDL.Record({
      'incoming' : IDL.Nat64,
      'outgoing' : IDL.Nat64,
    }),
    'account' : IDL.Record({
      'premium' : IDL.Int,
      'free' : IDL.Int,
      'lite' : IDL.Int,
      'count' : IDL.Int,
    }),
  });
  const HttpHeader = IDL.Record({ 'value' : IDL.Text, 'name' : IDL.Text });
  const HttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const TransformArgs = IDL.Record({
    'context' : IDL.Vec(IDL.Nat8),
    'response' : HttpResponsePayload,
  });
  const CanisterHttpResponsePayload = IDL.Record({
    'status' : IDL.Nat,
    'body' : IDL.Vec(IDL.Nat8),
    'headers' : IDL.Vec(HttpHeader),
  });
  const PlexiMailService = IDL.Service({
    'addAddress' : IDL.Func([IDL.Text, IDL.Text], [IDL.Bool], []),
    'allEntries' : IDL.Func([], [IDL.Vec(IDL.Tuple(SK, AttributeMap))], []),
    'c2p' : IDL.Func([CandyShared], [PlexiEnvelope], []),
    'charge' : IDL.Func([IDL.Nat], [IDL.Nat], []),
    'deleteContractArchives' : IDL.Func([SignalDeviceID], [IDL.Bool], []),
    'deleteCrypton' : IDL.Func([IDL.Text], [IDL.Bool], []),
    'deleteMailList' : IDL.Func([SignalDeviceID], [IDL.Bool], []),
    'getAddresseEntities' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))],
        [],
      ),
    'getAddresses' : IDL.Func([], [IDL.Vec(IDL.Text)], []),
    'getBridgedEnvelopes' : IDL.Func(
        [],
        [IDL.Vec(IDL.Tuple(IDL.Text, PlexiEnvelope))],
        [],
      ),
    'getContractArchives' : IDL.Func([SignalDeviceID], [IDL.Vec(IDL.Text)], []),
    'getCrypton' : IDL.Func([IDL.Text], [IDL.Opt(IDL.Text)], []),
    'getCryptons' : IDL.Func([], [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))], []),
    'getEnvelope' : IDL.Func(
        [IDL.Text, IDL.Text],
        [IDL.Opt(PlexiEnvelope)],
        [],
      ),
    'getExpirationTimerTriggerCounter' : IDL.Func([], [IDL.Int], []),
    'getMailList' : IDL.Func([SignalDeviceID], [IDL.Opt(IDL.Text)], []),
    'getMailLists' : IDL.Func([], [IDL.Vec(IDL.Tuple(IDL.Text, IDL.Text))], []),
    'getMails' : IDL.Func([SignalDeviceID], [PlexiAppWsMessage], ['query']),
    'getPK' : IDL.Func([], [IDL.Text], ['query']),
    'getTimestamp' : IDL.Func([], [TimeInMs], ['query']),
    'getUsers' : IDL.Func([], [IDL.Vec(IDL.Principal)], []),
    'isRegistered' : IDL.Func([], [IDL.Bool], ['query']),
    'monitorAccount' : IDL.Func(
        [IDL.Text],
        [IDL.Vec(MonitorAccountResult)],
        ['query'],
      ),
    'p2c' : IDL.Func([PlexiEnvelope], [CandyShared], []),
    'queryAccount' : IDL.Func([IDL.Text], [QueryAccountResult], ['query']),
    'queryPrekeyCount' : IDL.Func(
        [SignalDeviceID],
        [QueryPreKeyCountResult],
        ['query'],
      ),
    'registerDevice' : IDL.Func([RegisterForm], [IDL.Bool], []),
    'removeAddress' : IDL.Func([IDL.Text], [IDL.Bool], []),
    'removeDevice' : IDL.Func([SignalDeviceID], [], []),
    'removeMails' : IDL.Func([WsCopiedMessage], [PlexiAppWsMessage], []),
    'removeUser' : IDL.Func([PrincipalName], [IDL.Bool], []),
    'replyMail' : IDL.Func([IDL.Text, PlexiEnvelope], [IDL.Bool], []),
    'resetBridgeDB' : IDL.Func([], [IDL.Bool], []),
    'resetCryptonDB' : IDL.Func([], [IDL.Bool], []),
    'resetEnvelopeDB' : IDL.Func([], [IDL.Bool], []),
    'resetMailListDB' : IDL.Func([], [IDL.Bool], []),
    'resetUserStoreDB' : IDL.Func([], [IDL.Bool], []),
    'retrievePreKey' : IDL.Func(
        [IDL.Text, SignalDeviceID],
        [RetrievePrekeyBundleResult],
        [],
      ),
    'saveContractArchive' : IDL.Func(
        [SignalDeviceID, IDL.Text],
        [IDL.Bool],
        [],
      ),
    'saveCrypton' : IDL.Func([SaveCryptonForm], [IDL.Bool], []),
    'saveMailList' : IDL.Func([SignalDeviceID, IDL.Text], [IDL.Bool], []),
    'sendMail' : IDL.Func([PlexiAppWsMessage], [PlexiAppWsMessage], []),
    'skExists' : IDL.Func([IDL.Text], [IDL.Bool], ['query']),
    'statistics' : IDL.Func([], [Statistics], ['composite_query']),
    'summary' : IDL.Func([], [IDL.Text], []),
    'transferCycles' : IDL.Func([], [], []),
    'transform' : IDL.Func(
        [TransformArgs],
        [CanisterHttpResponsePayload],
        ['query'],
      ),
    'userExists' : IDL.Func([IDL.Text], [IDL.Bool], []),
    'whoami' : IDL.Func([], [IDL.Text], []),
  });
  return PlexiMailService;
};
export const init = ({ IDL }) => {
  const AutoScalingCanisterSharedFunctionHook = IDL.Func(
      [IDL.Text],
      [IDL.Text],
      [],
    );
  const ScalingLimitType = IDL.Variant({
    'heapSize' : IDL.Nat,
    'count' : IDL.Nat,
  });
  const ScalingOptions = IDL.Record({
    'autoScalingHook' : AutoScalingCanisterSharedFunctionHook,
    'sizeLimit' : ScalingLimitType,
  });
  return [
    IDL.Record({
      'owners' : IDL.Opt(IDL.Vec(IDL.Principal)),
      'partitionKey' : IDL.Text,
      'scalingOptions' : ScalingOptions,
    }),
  ];
};
