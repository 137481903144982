// import { homeScreenHasMenu } from "../common/constants";
import { EthereumChains, MnemonicBackupMethod, SetupWizardState, PlexiMailStatus, StorageProvider} from "../utils/Types";

class AppConfig {
    static PrivacyLevel = {
        Normal: 0,
        High: 1,
        Default: 0,
    };
    
    static get #ACK_TempMasterKey() {
        return "app.temp-master-key";
    }
    static get #ACK_TempMnemonic() {
        return "app.temp-mnemonic";
    }
    static get #ACK_PlexiMailStatus() {
        return "mail.status";
    }
    static get #ACK_NewCreatedUser() {
        return "mail.is-new-created";
    }
    static get #ACK_MnemonicBackupMethod() {
        return "app.mnemonic-backup-method";
    }
    static get #ACK_Web3StorageApiToken() {
        return "web3.storage-token";
    }

    static get #ACK_MailStorageProvider() {
        return "mail.storage-type";
    }
    static get #ACK_MailFoldersIpnsSignKey() {
        return "mail.folders-sign-key";
    }
    static get #ACK_MailFoldersCidKey() {
        return "mail.folders-cid";
    }

    static get #ACK_MailLightHouseApiKey() {
        return "mail.lighthouse-api-key";
    }
    static get #ACK_MailBackupLightHouseApiKey() {
        return "mail.backup.lighthouse-api-key";
    }
    static get #ACK_EnterpriseContactsIpnsNameKey() {
        return "enterprise.contacts-name-key";
    }
    static get #ACK_MailPushApiTokenKey() {
        return "mail.push-api-token";
    }
    static get #ACK_MailShowComposeTips() {
        return "mail.compose.tips.show";
    }

    static get #ACK_MailTransactionHash() {
        return "mail.tx-hash";
    }
    static get #ACK_SetupWizardState() {
        return "app.setup-wizard-state";
    }
    static get #ACK_ChainID() {
        return 'app.chain-id'
    }
    static get #ACK_RecentActiveAccount() {
        return 'app.recent-active-account'
    }
    static get #ACK_RecentChainId() {
        return 'app.recent-chain-id'
    }
    static get #ACK_PrioritizeUseAiFiGateway() {
        return 'app.prioritize-use-aifigateway'
    }
    static get #ACK_IsNewUser() {
        return 'app.is-new-user'
    }
    static get #ACK_ShowInstallAlert() {
        return 'app.show-install-alert'
    }
    static get #ACK_EncryptedMnemonic() {
        return 'app.encrypted-mnemonic'
    }
    static get #ACK_AccountIndex() {
        return 'app.account-index-db'
    }
    static get #ACK_PrivacyLevel() {
        return 'app.private-level'
    }
    static get #ACK_CryptonEntropySalt() {
        return 'app.crypton.entropy-salt'
    }
    static get #ACK_CryptonPassphrase() {
        return 'app.crypton.passphrase'
    }
    static get #ACK_SetupFromRecovery() {
        return "app.setup-from-recovery";
    }

    static get #ACK_MailCouponStorage() {
        return "mail.coupon.storage";
    }
    
    static get #ACK_MailCouponTx() {
        return "mail.coupon.tx";
    }
    
    static get #ACK_MailAddressNeedToBeVerified() {
        return "mail.address.verify";
    }
    static get #ACK_MailSenderNeedToBeVerified() {
        return "mail.sender.verify";
    }
    static get #ACK_UnverifiedAlertIsHiddenOnEditorIsReady() {
        return "mail.editor.unverified.hidden";
    }
    static get #ACK_IdleTimeout() {
        return "mail.idle.timeout";
    }
    
    static get #ACK_ContractBackupStorageProvider() {
        return "mail.contract.backup.storage.provider";
    }
    
    get encryptedMnemonicKey() {
        return AppConfig.#ACK_EncryptedMnemonic;
    }
    accountIndexDB=null;
    // constructor() {
    // }

    #setItem(k, v) {
        localStorage.setItem(this.recentActiveAccount + '.' + k, v);
    }

    #getItem(k) {
        const v = localStorage.getItem(this.recentActiveAccount + '.' + k);
        return v;
    }
    #removeItem(k) {
        localStorage.removeItem(this.recentActiveAccount + '.' + k);
    }

    assignAccountIndex(account) {
        return 0;
        // let idx = this.getAccountIndex(account);
        // if (idx >= 0) {
        //     return idx;
        // }

        // if (!this.accountIndexDB) {
        //     this.accountIndexDB = {v: 1, next: 0};
        // } 
        
        // idx = this.accountIndexDB.next++;
        // this.accountIndexDB[account] = idx;
        // const json = JSON.stringify(this.accountIndexDB);
        // localStorage.setItem(AppConfig.#ACK_AccountIndex, json);
        // return idx;
    }
    
    getAccountIndex(account) {
        return 0;
        // if (!this.accountIndexDB) {
        //     const item = localStorage.getItem(AppConfig.#ACK_AccountIndex);
        //     if (!item || item === '') {
        //         return -1;
        //     }
        //     this.accountIndexDB = JSON.parse(item);
        // }

        // const idx = this.accountIndexDB[account];
        // return idx;
    }   
    set isNewCreatedUser(value) {
        this.#setItem(AppConfig.#ACK_NewCreatedUser, value)
    }

    get isNewCreatedUser() {
        const value = this.#getItem(AppConfig.#ACK_NewCreatedUser);
        if (value === 'true') {
            return true;
        }
        return false;
    }
    
    set plexiMailStatus(value) {
        if (value < PlexiMailStatus.WaitForNew && value > PlexiMailStatus.Ready) {
            return;
        }
        this.#setItem(AppConfig.#ACK_PlexiMailStatus, value)
    }

    get plexiMailStatus() {
        const value = this.#getItem(AppConfig.#ACK_PlexiMailStatus) || '0';
        if (!value || value.length === 0) {
            return PlexiMailStatus.WaitForNew;
        }
        try {
            const status = parseInt(value);
            if (value < PlexiMailStatus.WaitForNew && value > PlexiMailStatus.Ready) {
                return PlexiMailStatus.WaitForNew;
            }
            return status
        } catch (e) {
            console.error(e);
            return PlexiMailStatus.WaitForNew;
        }
    }

    set isNewUser(value) {
        localStorage.setItem(AppConfig.#ACK_IsNewUser, value)
    }

    get isNewUser() {
        const value = localStorage.getItem(AppConfig.#ACK_IsNewUser) || 'true';
        if (value === 'true') {
            return true;
        }
        return false;
    }
    set recentActiveAccount(account) {
        localStorage.setItem(AppConfig.#ACK_RecentActiveAccount, account)
    }
    get recentActiveAccount() {
        const account = localStorage.getItem(AppConfig.#ACK_RecentActiveAccount) || '';
        return account;
    }
    set recentChainId(chainId) {
        localStorage.setItem(AppConfig.#ACK_RecentChainId, chainId);
    }

    get recentChainId() {
        const value = localStorage.getItem(AppConfig.#ACK_RecentChainId);
        if (!value || value === '') {
            // return homeScreenHasMenu ? EthereumChains.Sepolia : EthereumChains.Mainnet;
            return EthereumChains.Default;
        }
        return value
    }
    
    set prioritizeUseAiFiGateway(value) {
        localStorage.setItem(AppConfig.#ACK_PrioritizeUseAiFiGateway, value)

    }
    get prioritizeUseAiFiGateway() {
        const value = localStorage.getItem(AppConfig.#ACK_PrioritizeUseAiFiGateway) || 'false';
        if (value === 'true') {
            return true;
        }
        return false;
    }

    get showInstallAlert() {
        // if (!window.deferredPrompt) {
        //     return false;
        // }
        if (window.matchMedia('(display-mode: standalone)').matches) {
            return false;
        }

        const value = localStorage.getItem(AppConfig.#ACK_ShowInstallAlert) || 'true';
        if (value === 'true') {
            return true;
        }
        return false;
    }

    set showInstallAlert(value) {
        localStorage.setItem(AppConfig.#ACK_ShowInstallAlert, value)
    }

    set encryptedMnemonic(value) {
        this.#setItem(AppConfig.#ACK_EncryptedMnemonic, value)

    }
    get encryptedMnemonic() {
        const value = this.#getItem(AppConfig.#ACK_EncryptedMnemonic);
        return value;
    }
    mnemonic = null;
    masterKey = null;
    
    get isConfigured() {
        // this.isPushApiTokenConfigured && 
        // const isConfigured = this.isWeb3StorageConfigured && this.isMailFoldersIpnsSignKeyGenerated && this.isMailPrekeysIpnsSignKeyGenerated && this.isMailSignalContextIpnsSignKeyGenerated;
        // return isConfigured;
        const res = (this.setupWizardState === SetupWizardState.Completed);
        return res;
    }

    get mnemonicBackupMethod() {
        const value = this.#getItem(AppConfig.#ACK_MnemonicBackupMethod);
        if (!value || value === '') {
            return MnemonicBackupMethod.Web3Storage;
        }
        
        try {
            const method = parseInt(value);
            return method; 
        } catch(e) {
            console.error(e);
            return MnemonicBackupMethod.Web3Storage;
        }
    }
    set mnemonicBackupMethod(method) {

        this.#setItem(AppConfig.#ACK_MnemonicBackupMethod, method + '');
    }
    get tempMnemonic() {
        const value = this.#getItem(AppConfig.#ACK_TempMnemonic);
        if (!value || value === '') {
            return null;
        }
        return value;
    }

    set tempMnemonic(mnemonic) {
        this.mnemonic = mnemonic;
        this.#setItem(AppConfig.#ACK_TempMnemonic, mnemonic);
    }

    removeTempMnemonic() {
        // this.masterKey = null;
        this.#removeItem(AppConfig.#ACK_TempMnemonic);
    }

    get tempMasterKey() {
        const value = this.#getItem(AppConfig.#ACK_TempMasterKey);
        if (!value || value === '') {
            return null;
        }
        return value;
    }

    set tempMasterKey(masterKey) {
        this.masterKey = masterKey;
        this.#setItem(AppConfig.#ACK_TempMasterKey, masterKey);
    }

    removeTempMasterKey() {
        // this.masterKey = null;
        this.#removeItem(AppConfig.#ACK_TempMasterKey);
    }

    get txHash() {
        const value = this.#getItem(AppConfig.#ACK_MailTransactionHash);
        if (!value || value === '') {
            return null;
        }
        return value;
    }

    set txHash(hash) {
        this.#setItem(AppConfig.#ACK_MailTransactionHash, hash);
    }
    
    removeTxHash() {
        this.#removeItem(AppConfig.#ACK_MailTransactionHash);
    }

    get chainId() {
        const value = this.#getItem(AppConfig.#ACK_ChainID);
        if (!value || value === '') {
            // return homeScreenHasMenu ? EthereumChains.Sepolia : EthereumChains.Mainnet;
            return EthereumChains.Default;
        }
        return value;
    }
    
    set chainId(id) {
        this.#setItem(AppConfig.#ACK_ChainID, id);
    }
    
    clearChainId() {
        window.chainId = EthereumChains.Default;
        localStorage.removeItem(AppConfig.#ACK_RecentChainId);
        this.#removeItem(AppConfig.#ACK_ChainID);
        localStorage.removeItem('.' + AppConfig.#ACK_ChainID);
    }

    get setupWizardState() {
        if (!this.recentActiveAccount || this.recentActiveAccount === '') {
            return SetupWizardState.ConnectWallet;
        }
        if (this.privacyLevelIsNormal) {
            const value = this.#getItem(AppConfig.#ACK_SetupWizardState);
            if (!value) {
                return SetupWizardState.ConnectWallet
            }
            try {
                const state = parseInt(value);
                return state; 
            } catch(e) {
                console.error(e);
                return SetupWizardState.ConnectWallet;
            }
        } else {
            const value = window.setupWizardState;
            if (!value) {
                return SetupWizardState.ConnectWallet
            }
            try {
                const state = parseInt(value);
                return state; 
            } catch(e) {
                console.error(e);
                return SetupWizardState.ConnectWallet;
            }
        }
    }
    set setupWizardState(state) {
        if (!this.recentActiveAccount || this.recentActiveAccount === '') {
            return;
        }
        if (this.privacyLevelIsNormal) {
            this.#setItem(AppConfig.#ACK_SetupWizardState, state + '');
        } else {
            window.setupWizardState = state;
        }
    }
    removeSetupWizardState() {
        this.#removeItem(AppConfig.#ACK_SetupWizardState);
    }



    get pushApiToken() {
        return this.#getItem(AppConfig.#ACK_MailPushApiTokenKey);
    }
    set pushApiToken(token) {
        this.#setItem(AppConfig.#ACK_MailPushApiTokenKey, token);
    }
    get isPushApiTokenConfigured() {
        const isPushApiTokenConfigured = Boolean(this.pushApiToken);
        return isPushApiTokenConfigured;
    }

    get lightHouseApiKey() {
        return this.#getItem(AppConfig.#ACK_MailLightHouseApiKey);
    }
    set lightHouseApiKey(apiKey) {
        this.#setItem(AppConfig.#ACK_MailLightHouseApiKey, apiKey);
    }
    lightHouseApiKeyFor(account) {
        const apiKey = localStorage.getItem(`${account}.${AppConfig.#ACK_MailLightHouseApiKey}`);
        return apiKey;
    }
    storageProviderFor(account) {
        const type = localStorage.getItem(`${account}.${AppConfig.#ACK_MailStorageProvider}`);
        if (!type || type.length === 0) {
            return StorageProvider.Unspecified;
        }
        try {
            return parseInt(type);
        } catch (e) {
            return StorageProvider.Unspecified;
        }
    }

    get isLightHouseApiKeyConfigured() {
        const isConfigured = Boolean(this.lightHouseApiKey);
        return isConfigured;
    }

    get storageProvider() {
        const type = this.#getItem(AppConfig.#ACK_MailStorageProvider);
        if (!type || type.length === 0) {
            return StorageProvider.Unspecified;
        }
        try {
            return parseInt(type);
        } catch (e) {
            return StorageProvider.Unspecified;
        }
    }
    
    set storageProvider(type) {
        this.#setItem(AppConfig.#ACK_MailStorageProvider, type);
    }

    

    
    web3StorageApiTokenFor(account) {
        const token = localStorage.getItem('w3up-account-email');
        return token;
    }
    get web3StorageApiToken() {
        return this.#getItem(AppConfig.#ACK_Web3StorageApiToken);
    }
    set web3StorageApiToken(token) {
        return this.#setItem(AppConfig.#ACK_Web3StorageApiToken, token);
    }

    get defaultWeb3Storage() {
        return localStorage.getItem('w3up-account-email');
    }
    
    get isWeb3StorageConfigured() {
        const isWeb3StorageConfigured = Boolean(this.web3StorageApiToken);
        return isWeb3StorageConfigured;
    }

    get mailFoldersCid() {
        return this.#getItem(AppConfig.#ACK_MailFoldersCidKey);

    }
    set mailFoldersCid(cid) {
        this.#setItem(AppConfig.#ACK_MailFoldersCidKey, cid);
    }

    set mailAddressNeedToBeVerified(verified) {
        this.#setItem(AppConfig.#ACK_MailAddressNeedToBeVerified, verified);
    }
    
    get mailAddressNeedToBeVerified() {
        const value = this.#getItem(AppConfig.#ACK_MailAddressNeedToBeVerified);
        if (!value || value === '') {
            return false;
        }
        if (value === 'true') {
            return true;
        }
        return false;
    }

    set mailSenderNeedToBeVerified(verified) {
        this.#setItem(AppConfig.#ACK_MailSenderNeedToBeVerified, verified);
    }
    get mailSenderNeedToBeVerified() {
        const value = this.#getItem(AppConfig.#ACK_MailSenderNeedToBeVerified);
        if (!value || value === '') {
            return true;
        }
        if (value === 'true') {
            return true;
        }
        return false;
    }
    get showComposeTips() {
        const value = this.#getItem(AppConfig.#ACK_MailShowComposeTips);
        if (!value || value === '') {
            return true;
        }
        if (value === 'true') {
            return true;
        }
        return false;

    }
    set showComposeTips(value) {
        this.#setItem(AppConfig.#ACK_MailShowComposeTips, value);
    }
    
    set couponStorage(value) {
        this.#setItem(AppConfig.#ACK_MailCouponStorage, value);

    }
    
    get couponStorage() {
        const value = this.#getItem(AppConfig.#ACK_MailCouponStorage);
        if (!value || value === '') {
            return null;
        }
        return value;
    }
    set couponTx(value) {
        this.#setItem(AppConfig.#ACK_MailCouponTx, value);
    } 
    get couponTx() {
        const value = this.#getItem(AppConfig.#ACK_MailCouponTx);
        if (!value || value === '') {
            return null;
        }
        return value;
    }

    _privacyLevel = -1;
    set privacyLevel(value) {
        this._privacyLevel = value;
        try {
            localStorage.setItem(AppConfig.#ACK_PrivacyLevel, value);
        } catch (e) {
            console.error(e);
        }
    } 

    get privacyLevel() {
        if (this._privacyLevel !== -1) {
            return this._privacyLevel;
        }
        try {
            const value = localStorage.getItem(AppConfig.#ACK_PrivacyLevel);
            if (!value || value === '') {
                this._privacyLevel = AppConfig.PrivacyLevel.Default;
                return AppConfig.PrivacyLevel.Default;
            }
            this._privacyLevel = parseInt(value);
            return value;
        } catch (e) {
            console.error(e);
            this._privacyLevel = AppConfig.PrivacyLevel.Default;
            return AppConfig.PrivacyLevel.Default;
        }
    }

    get privacyLevelIsNormal() {
        return this.privacyLevel === AppConfig.PrivacyLevel.Normal
    }

    removeCouponInfo() {
        this.#removeItem(AppConfig.#ACK_MailCouponStorage);
        this.#removeItem(AppConfig.#ACK_MailCouponTx);
    }

    get cryptonEntropySalt() {

        const salt = this.#getItem(AppConfig.#ACK_CryptonEntropySalt);
        if (salt !== null && salt.length === 0) {
            return null;
        }
        return salt;
    }
    set cryptonEntropySalt(v) {
        this.#setItem(AppConfig.#ACK_CryptonEntropySalt, v);
    }
    
    get cryptonPassphrase() {
        const pass = this.#getItem(AppConfig.#ACK_CryptonPassphrase);
        if (pass !== null && pass.length === 0) {
            return null;
        }
        return pass;
    }
    set cryptonPassphrase(v) {
        this.#setItem(AppConfig.#ACK_CryptonPassphrase, v);
    }
    
    removeTempCryptonInfo() {
        this.#removeItem(AppConfig.#ACK_CryptonEntropySalt);
        this.#removeItem(AppConfig.#ACK_CryptonPassphrase);
    }
    get setupFromRecovery() {
        const val = this.#getItem(AppConfig.#ACK_SetupFromRecovery);
        if (val === 'true') {
            return true;
        }
        return false;
    }
    set setupFromRecovery(v) {
        this.#setItem(AppConfig.#ACK_SetupFromRecovery, v);
    }
    removeSetupFromRecovery() {
        this.#removeItem(AppConfig.#ACK_SetupFromRecovery);
    }

    get unverifiedAlertIsHiddenOnEditorIsReady() {
        const val = this.#getItem(AppConfig.#ACK_UnverifiedAlertIsHiddenOnEditorIsReady);
        if (val === 'true') {
            return true;
        }
        return false;
    }
    set unverifiedAlertIsHiddenOnEditorIsReady(v) {
        this.#setItem(AppConfig.#ACK_UnverifiedAlertIsHiddenOnEditorIsReady, v);
    }

    get idleTimeout() {
        const val = this.#getItem(AppConfig.#ACK_IdleTimeout);
        if (val === 'true') {
            return true;
        }
        return false;
    }

    set idleTimeout(v) {
        this.#setItem(AppConfig.#ACK_IdleTimeout, v);
    }

    get enterpriseContactsIpnsName() {
        const val = this.#getItem(AppConfig.#ACK_EnterpriseContactsIpnsNameKey);
        
        if (val && val.length > 0) {
            return val;
        }
        return null;
        
    }
    
    set enterpriseContactsIpnsName(v) {
        if (v && v.length > 0) {
            this.#setItem(AppConfig.#ACK_EnterpriseContactsIpnsNameKey, v);
        }
    }

    get backupStorageProvider() {
        const val = this.#getItem(AppConfig.#ACK_ContractBackupStorageProvider);
        if (!val) {
            return 0;
        }
        try {
            return parseInt(val);
        } catch (e) {
            return 0;
        }
    }

    set backupStorageProvider(v) {
        if (v !== 0 && v !== 1) {
            return;
        }
        this.#setItem(AppConfig.#ACK_ContractBackupStorageProvider, v);
    }

    get backupLightHouseApiKey() {
        return this.#getItem(AppConfig.#ACK_MailBackupLightHouseApiKey);
    }
    
    set backupLightHouseApiKey(apiKey) {
        this.#setItem(AppConfig.#ACK_MailBackupLightHouseApiKey, apiKey);
    }

    get isBackupLightHouseApiKeyConfigured() {
        const isConfigured = Boolean(this.backupLightHouseApiKey);
        return isConfigured;
    }
    
    exportConfig(asString = true, lite=false) {
        // const config = {
        //     web3: {
        //         apiToken: this.web3StorageApiToken,
        //         foldersIpnsSignKey: this.mailFoldersIpnsSignKey,
        //         pushApiToken: this.pushApiToken
        //     }
            
        // }

        const config = JSON.stringify(localStorage);
        const configJSON = JSON.parse(config);

        const keysToRemove = Object.keys(configJSON).filter((key) => {
            if (!lite) {
                return false;
            }
            if (key.startsWith('0x') && !key.startsWith(`${this.recentActiveAccount}.`)) {
                return true;
            }
            let needRemove = (key.lastIndexOf('.' + AppConfig.#ACK_MailFoldersCidKey) !== -1)
            if (needRemove) {
                return needRemove;
            }
            needRemove = (key.lastIndexOf('.' + AppConfig.#ACK_Web3StorageApiToken) !== -1)
            if (needRemove) {
                return needRemove;
            }

            needRemove = (key === AppConfig.#ACK_EncryptedMnemonic);
            if (needRemove) {
                return needRemove;
            }
            needRemove = (key.lastIndexOf('.' + AppConfig.#ACK_MailTransactionHash) !== -1)
            if (needRemove) {
                return needRemove;
            }
            needRemove = (key.lastIndexOf(AppConfig.#ACK_PrivacyLevel) !== -1)
            return needRemove;
        });

        for(const k of keysToRemove) {
            delete configJSON[k];
        }
        if (!this.privacyLevelIsNormal) {
            const key = this.recentActiveAccount + '.' + AppConfig.#ACK_SetupWizardState
            configJSON[key] = this.setupWizardState;
        }
        
        if (!asString) {
            return configJSON;
        }

        const configToBackup = JSON.stringify(configJSON);
        return configToBackup;
    }

    importConfig(json) {
        if (typeof json === 'string') {
            json = JSON.parse(json);
        }

        this.apiToken = json[AppConfig.#ACK_Web3StorageApiToken] || '';
        this.mailFoldersIpnsSignKey = json[AppConfig.#ACK_MailFoldersIpnsSignKey] || '';
        this.pushApiToken = json[AppConfig.#ACK_MailPushApiTokenKey] || '';

        this.enterpriseContactsIpnsName = json[AppConfig.#ACK_EnterpriseContactsIpnsNameKey] || '';
        
    }

    migrateIfNeeded(selectedAccount) {
        if (!this.recentActiveAccount || window.appConfig.recentActiveAccount === '') {
            Object.keys(localStorage).forEach(key => {
                localStorage[selectedAccount + '.' + key] = localStorage[key];
            }) 

            // this.recentActiveAccount = selectedAccount;            
        } else if (this.recentActiveAccount !== selectedAccount) {
            // this.recentActiveAccount = selectedAccount;
        }
    }
}

// const appConfig = new AppConfig();
// export default appConfig;
export default AppConfig;