// import './App.css';
import React, { useState, useCallback, useEffect } from "react";

import {
  IconButton,
  Avatar,
  CloseButton,
  Flex,
  HStack,
  VStack,
  Text,
  Button,
  List,
  ListItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
  FormLabel,
  Tooltip,
  Box,
  Stack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  FormControl,
  // InputGroup,
  // InputRightElement,
  // Progress,
  useDisclosure,
  // Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Checkbox,
  Progress,
  Center,
  Select,
} from "@chakra-ui/react";

import { createRef } from "react";

import * as ReactIcons from "react-icons/fa";
import * as ReactAIIcons from "react-icons/ai";
import { GoVerified, GoUnverified } from "react-icons/go";
// import MailService from '../services/MailService';

import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import ClassicEditor from "myckeditor";

import { CopyToClipboard } from "react-copy-to-clipboard";
import toast, { Toaster } from "react-hot-toast";
// import TokenInput from 'react-customize-token-input';
// import { TokenField } from 'react-tokenfield';
// import '../react-customize-token-input.css';

// import {ReactTags} from 'react-tag-autocomplete';
import {ReactTags} from "react-tag-autocomplete";
import "./react-tags.css";
// import { TutorialModalLink } from './TutorialModalTrigger';

import { MailEncryptionType } from "../utils/Types";

// import { CUIAutoComplete } from 'chakra-ui-autocomplete';

// import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";

import { ClientError, ServerError, showError } from "../common/errors";
import MailAddressUtils from "../utils/MailAddressUtils";
import Strings from "../config/Strings";
import {
  BACKUP_FOLDER,
  mailAddressDomain,
  mailAddressSuffix /*CONTACT_NAME_ME, CONTACT_NAME_ME_LOWERCASED*/,
} from "../common/constants";
import { QRCodeSourceChooseConfirmationButton } from "./QRCodeReaderModal";
// import zxcvbn from 'zxcvbn';
import { NormalEmailAddressTips } from "./NormalEmailAddressTips";
import UnverifiedAlert from "./UnverifiedAlert";
import { LoadDraftAlert, SaveDraftAlert } from "./DraftAlerts";
import { ethers } from "ethers";
import TypeUtils from "../utils/TypeUtils";

import AccountManager from "./enterprise/AccountManager";
// import { pid } from 'process';
//import './MailEditor.css';

// const EDIT_MODE_NEW = 'new';
export const EDIT_MODE_NEW = "new";
export const EDIT_MODE_REPLY = "reply";
export const EDIT_MODE_REPLY_ALL = "reply";
export const EDIT_MODE_FORWARD = "forward";

const prefixesOfSubject = {
  new: "",
  reply: "re: ",
  "reply-all": "re: ",
  forward: "fwd: ",
};

const SendMailPharse = {
  ComposeMessage: 1,
  CheckRecipients: 2,
  ConfirmRecipients: 3,
  Sending: 4,
};

// function TagComponent1 (props) {
//   const [selected, setSelected] = useState(true);
//   return (
//     <button type='button' className={props.classNames.selectedTag} title={props.removeButtonText} onClick={(e) => {
//       if (selected) {
//         props.onDelete(e)
//       } else {
//         setSelected(true);
//       }
//     }} onBlur={() => {
//       setSelected(false);
//     }}>
//       <span className={props.classNames.selectedTagName}>{props.tag.name || props.tag.id}</span>
//     </button>
//   )
// }

function TagComponent({classNames, tag, ...tagProps}) {
  // const truncateString = (s, limit=0) => {
  //   if (limit !== 0 && (s.length > limit)) {
  //     s = s.substring(0, limit) + '...'
  //   }
  //   return s;
  // };
  const formatAddress = (tag) => {
    if (!tag) {
      return null;
    }

    if (tag.label && tag.label.length > 0) {
      return tag.label;
    }

    if (TypeUtils.isMobile()) {
      const idx = tag.value.indexOf("@");
      if (idx > 0) {
        const local = tag.value.substring(0, idx);
        const domain = tag.value.substring(idx);
        if (local.length > 20) {
          const name =
            local.substring(0, 16) +
            "..." +
            local.substring(local.length - 4) +
            domain;
          return name;
        }
      }
    }
    return tag.value;
  };
  return (
    <button
      type="button"
      className={classNames.tag}
      // title={removeButtonText}
      // onClick={props.onDelete}
      {...tagProps}
    >
      <span className={classNames.selectedTagName}>
        {formatAddress(tag)}
      </span>
    </button>
  );
}

// const RenderOption = ({ children, classNames, option, ...optionProps }) => {
//   const classes = [
//     classNames.option,
//     option.active ? 'is-active' : '',
//     option.selected ? 'is-selected' : '',
//   ]

//   return (
//     <div className={classes.join(' ')} {...optionProps}>
//       {children}asdasdas
//     </div>)
// }

// const SuggestionComponent = ({ item, query }) => {
//   // console.log('SuggestionComponent: ', item)

//   const formatAddress = (address) => {
//     if (!address) {
//       return "";
//     }
//     if (address.length <= 36) {
//       return address;
//     }
//     const idx = address.indexOf("@");
//     const domain = address.substring(idx);
//     const nameLen = 36 - domain.length - 4;
//     const name = address.substring(0, nameLen);
//     return name + "..." + domain;
//   };
//   return (
//     <span style={{ fontSize: "12px", lineHeight: "16px" }}>
//       <strong>{item.name}</strong>
//       <br />
//       {formatAddress(item.id)}
//     </span>
//   );
// };

// const AddressInput = ({ selectedAccount, ...rest }) => {
//   // const [tags, setTags] = useState([])
//   const [contacts, setContacts] = useState([]);

//   const {
//     onDelete,
//     // onInput,
//     // onFocus,
//     // onBlur,
//     onAddition,
//     onValidate,
//     toValues,
//     disabled,
//   } = rest;

//   const tags = toValues.map((to) => {
//     return { id: to.address, name: to.name };
//   });
//   const [errorMessage, setErrorMessage] = useState(null);

//   useEffect(() => {
//     window.mailService.getAllContacts().then((contacts) => {
//       // contacts.unshift({email: selectedAccount + mailAddressSuffix(), name: CONTACT_NAME_ME, uuid: CONTACT_NAME_ME_LOWERCASED})
//       setContacts(
//         contacts.map((o) => {
//           return { id: o.email, name: o.name };
//         })
//       );
//     });
//   }, []); // eslint-disable-line react-hooks/exhaustive-deps

//   const reactTags = React.useRef();

//   // const onDelete = useCallback((tagIndex) => {
//   //   setTags(tags.filter((_, i) => i !== tagIndex))
//   // }, [tags])

//   // const onAddition = useCallback((newTag) => {
//   //   console.log('onAddition: ', newTag);
//   //   setTags([...tags, newTag])
//   // }, [tags]);

//   const suggestionsFilter = (item, query) => {
//     const contains =
//       item.id.toLowerCase().startsWith(query.toLowerCase()) ||
//       item.name.toLowerCase().startsWith(query.toLowerCase());
//     return contains;
//   };
//   const suggestionsTransform = (value, suggestions) => {
//     return suggestions.filter((item) => {

//       const contains =
//       item.id.toLowerCase().startsWith(value.toLowerCase()) ||
//       item.name.toLowerCase().startsWith(value.toLowerCase());
//       return contains;
//     })
//     // const contains =
//     //   item.id.toLowerCase().startsWith(query.toLowerCase()) ||
//     //   item.name.toLowerCase().startsWith(query.toLowerCase());
//     // return contains;
//   }
//   // return <></>
//   return (
//     <Stack>
//       <ReactTags
//         placeholderText={
//           toValues && toValues.length > 0
//             ? Strings.editor.content.editor.to.placeholder_more
//             : Strings.editor.content.editor.to.placeholder
//         }
//         removeButtonText={"Click to remove recipient"}
//         isDisabled={disabled}
//         ref={reactTags}
//         minQueryLength={1}
//         suggestions={contacts}
//         suggestionsTransform={suggestionsTransform}
//         // suggestionsFilter={suggestionsFilter}
//         // suggestionComponent={SuggestionComponent}
//         tagComponent={TagComponent}
//         tags={tags}
//         addOnBlur={true}
//         onInput={(value) => {
//           if (!value || value.length === 0) {
//             setErrorMessage(null);
//           }
//         }}
//         // onFocus={onFocus}
//         // onBlur={onBlur}
//         onDelete={onDelete}
//         onAddition={onAddition}
//         onValidate={(tag) => {
//           const result = onValidate(tag);
//           if (result === "valid") {
//             setErrorMessage(null);
//             return true;
//           } else if (result === "invalid-addr") {
//             // setErrorMessage(null);
//             setErrorMessage(
//               `${Strings.editor.message.address.invalid_addr_1} "${tag.name}" ${Strings.editor.message.address.invalid_addr_2}`
//             );
//             return false;
//           } else if (result === "invalid-eth") {
//             setErrorMessage(Strings.editor.message.address.invalid_eth);
//             return false;
//           } else if (result === "invalid-normal") {
//             setErrorMessage(Strings.editor.message.address.invalid_normal);
//             return false;
//           } else if (result === "invalid-normal-multi-recipients") {
//             setErrorMessage(Strings.editor.message.address.multi_recps);
//             return false;
//           } else {
//             setErrorMessage(null);
//             return false;
//           }
//         }}
//         allowNew={true}
//       />
//       {errorMessage && (
//         <Text color="red" fontSize={"sm"}>
//           {errorMessage}
//         </Text>
//       )}
//     </Stack>
//   );
// };
// function CustomInput(options) {
//   const { classNames, inputWidth, ...inputProps } = options;
//   return <input className={classNames.input} style={{ width: inputWidth }} {...inputProps} />
// }
const AddressInput = ({ selectedAccount, ...rest }) => {
  // const [tags, setTags] = useState([])
  const [contacts, setContacts] = useState([]);

  const {
    onDelete,
    // onInput,
    // onFocus,
    // onBlur,
    onAddition,
    onValidate,
    toValues,
    disabled,
  } = rest;

  const tags = toValues.map((to) => {
    return { value: to.address, label: to.name };
  });
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    window.mailService.getAllContacts().then((contacts) => {
      // contacts.unshift({email: selectedAccount + mailAddressSuffix(), name: CONTACT_NAME_ME, uuid: CONTACT_NAME_ME_LOWERCASED})
      setContacts(
        contacts.map((o) => {
          return { value: o.email, label: o.name };
        })
      );
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reactTags = React.useRef();

  // const onDelete = useCallback((tagIndex) => {
  //   setTags(tags.filter((_, i) => i !== tagIndex))
  // }, [tags])

  // const onAddition = useCallback((newTag) => {
  //   console.log('onAddition: ', newTag);
  //   setTags([...tags, newTag])
  // }, [tags]);

  const suggestionsTransform = (value, suggestions) => {
    return suggestions.filter((item) => {

      const contains =
      item.value.toLowerCase().startsWith(value.toLowerCase()) ||
      item.label.toLowerCase().startsWith(value.toLowerCase());
      return contains;
    })
  }
  // const onAdd = useCallback(
  //   (newTag) => {
  //     setSelected([...selected, newTag])
  //   },
  //   [selected]
  // )

  // const onDelete1 = useCallback(
  //   (index) => {
  //     setSelected(selected.filter((_, i) => i !== index))
  //   },
  //   [selected]
  // )

  return (
    <Stack>
      <ReactTags

        selected={tags}
        activateFirstOption={true}
        allowNew={true}
        addOnBlur={true}
        collapseOnSelect={true}
        onInput={(value) => {
          if (!value || value.length === 0) {
            setErrorMessage(null);
          }
        }}

        onAdd={onAddition}

        onDelete={onDelete}
        suggestions={contacts}
        suggestionsTransform={suggestionsTransform}
        placeholderText={
          toValues && toValues.length > 0
            ? Strings.editor.content.editor.to.placeholder_more
            : Strings.editor.content.editor.to.placeholder
        }
        deleteButtonText={"Click to remove recipient"}
        isDisabled={disabled}
        ref={reactTags}
        renderTag={TagComponent}
        // renderInput={CustomInput}
        
        // RenderOption={RenderOption}
        onValidate={(tag) => {
          const result = onValidate(tag);
          if (result === "valid") {
            setErrorMessage(null);
            return true;
          } else if (result === "invalid-addr") {
            // setErrorMessage(null);
            setErrorMessage(
              `${Strings.editor.message.address.invalid_addr_1} "${tag.label}" ${Strings.editor.message.address.invalid_addr_2}`
            );
            return false;
          } else if (result === "invalid-eth") {
            setErrorMessage(Strings.editor.message.address.invalid_eth);
            return false;
          } else if (result === "invalid-normal") {
            setErrorMessage(Strings.editor.message.address.invalid_normal);
            return false;
          } else if (result === "invalid-normal-multi-recipients") {
            setErrorMessage(Strings.editor.message.address.multi_recps);
            return false;
          } else {
            setErrorMessage(null);
            return false;
          }
        }}
        // onFocus={onFocus}
        // onBlur={onBlur}
        // onDelete={onDelete}
        // onAddition={onAddition}
        
      />
      {errorMessage && (
        <Text color="red" fontSize={"sm"}>
          {errorMessage}
        </Text>
      )}
    </Stack>
  );
};
function PasswordAlert({
  encryptionPassword,
  assignable,
  disclosure,
  selectedAccount,
  onCompleted,
}) {
  /*
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState(null);
  const [password, setPassword] = useState(null); 
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [confirmed, setConfirmed] = useState(false); 
  
  const colors = ['gray', 'red', 'pink', 'orange', 'green'];
  */
  const [newPassword, setNewPassword] = useState(encryptionPassword);
  const [shouldAssignAccount, setShouldAssignAccount] = useState(false);
  const [canReply, setCanReply] = useState(false);
  const [isCopied, setCopied] = useState(false);
  useEffect(() => {
    setNewPassword(encryptionPassword);
    setCanReply(assignable);
  }, [encryptionPassword, assignable]);
  /*
  const togglePassword = () => {
      setShowPassword(!showPassword);
  }

  function verifyPassword(e) {
    const password = e.target.value || '';
    if (password === '') {
        setPasswordStrength(null);
        setPassword(null)
        return;
    }
    // if (setupWizardState === SetupWizardState.EnterPassword) {
    //     setPassword(password);
    //     return;
    // }
    const r = zxcvbn(password);
    setPasswordStrength(r);
    setPassword(password);
      
    setConfirmed(confirmPassword === password);
  }

  function verifyConfirmPassword(e) {
    const confirmPassword = e.target.value || '';
    setConfirmPassword(confirmPassword);
    setConfirmed(confirmPassword === password);
  }
  */

  return (
    <AlertDialog
      isOpen={disclosure.isOpen}
      onClose={() => {
        setCopied(false);
        disclosure.onClose();
      }}
      closeOnOverlayClick={false}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {Strings.editor.content.pwd.title}
          </AlertDialogHeader>
          {/* <AlertDialogHeader fontSize='lg' fontWeight='bold'>
            Please send the passcode to the recipients through another channel
            </AlertDialogHeader> */}

          <AlertDialogBody>
            {/* <Box>
            <Text mb={5}>
            This passcode is non-modifiable and intended to be copied and pasted for sending to your recipients. It's generated randomly and designed to be lengthy to mitigate the risks associated with insecure passcodes.
            </Text>
            </Box> */}
            <Box>
              <Text mb={5}>{Strings.editor.content.pwd.description}</Text>
            </Box>
            {/* <FormControl>
                <FormLabel htmlFor='me-password'>Passcode</FormLabel>
                <InputGroup>
                <Input id="me-password" type={showPassword ? 'text' : 'password'} onChange={verifyPassword} />
                <InputRightElement>
                {showPassword &&
                <IconButton icon={<ReactIcons.FaEye />} onClick={togglePassword} />
                }
                {!showPassword &&
                <IconButton icon={<ReactIcons.FaEyeSlash />} onClick={togglePassword} />
                }
                </InputRightElement>
                </InputGroup>
                {(passwordStrength) &&
                <>
                    <Progress mt={1} value={(passwordStrength.score + 1) * 20} colorScheme={colors[passwordStrength.score]} size='xs' />
                    <Text fontSize={"12px"} align={"right"} color={colors[passwordStrength.score]}>{passwordStrength.feedback.warning}</Text>
                </>
                }
              </FormControl>


              <FormControl hidden={ (passwordStrength === null ? true : (passwordStrength.score < 4)) }>
                  <FormLabel htmlFor='country'>Confirm Passcode</FormLabel>
                  <InputGroup>
                  <Input type={showPassword ? 'text' : 'password'} onChange={verifyConfirmPassword} />
                  <InputRightElement>
                  {showPassword &&
                  <IconButton icon={<ReactIcons.FaEye />} onClick={togglePassword} />
                  }
                  {!showPassword &&
                  <IconButton icon={<ReactIcons.FaEyeSlash />} onClick={togglePassword} />
                  }
                  </InputRightElement>
                  </InputGroup>
                </FormControl> */}

            <FormControl>
              <FormLabel htmlFor="me-password">
                {Strings.editor.content.pwd.passcode}
              </FormLabel>
              {/* <FormLabel htmlFor='country'>Click to Copy the passcode</FormLabel> */}
              {/* <CopyToClipboard text={encryptionPassword} cursor={"pointer"} onCopy={() => {
                  // toast(`Passcode is copied`);
                                    // setUpdateCounter(updateCounter + 1)
                                    setCopied(true);
                }}> */}

              <Input
                /*readOnly={true}*/ type={"text"}
                value={newPassword}
                onChange={(e) => {
                  // if (e.target.value && e.target.value.length > 0) {
                  //   setNewPassword(e.target.value);
                  // }
                  setNewPassword(e.target.value);
                }}
              />
              {/* </CopyToClipboard> */}
            </FormControl>
            {isCopied && <Text color={"#00aaff"}>Copied</Text>}

            <FormControl>
              <Checkbox
                isDisabled={!assignable}
                isChecked={canReply}
                mt={5}
                onChange={(e) => {
                  setCanReply(e.target.checked);
                }}
              >{`${Strings.editor.content.pwd.allow_reply}${
                assignable ? "" : Strings.editor.content.pwd.allow_reply_explain
              }.`}</Checkbox>
            </FormControl>

            {assignable && (
              <FormControl>
                <Checkbox
                  defaultChecked={false}
                  mt={5}
                  onChange={(e) => {
                    setShouldAssignAccount(e.target.checked);
                  }}
                >
                  {Strings.editor.content.pwd.assist_account_creation}
                </Checkbox>
              </FormControl>
            )}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button
              onClick={() => {
                disclosure.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              colorScheme={"green"}
              isDisabled={!newPassword || newPassword.length === 0}
              onClick={() => {
                disclosure.onClose();
                setCopied(false);
                onCompleted(newPassword, shouldAssignAccount, canReply);
              }}
              ml={3}
            >
              Next
            </Button>

            {/* <Button colorScheme={"gray"} mr={4} onClick={() => {
                disclosure.onClose();
                onCompleted(null)
              }} ml={3}>
                Cancel
              </Button>
              <Button colorScheme={"green"} disabled={!confirmed} onClick={() => {
                disclosure.onClose();
                onCompleted(password)
              }} ml={3}>
                Use
              </Button> */}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
}

const MailEditor = ({
  selectedAccount,
  setFolders,
  composeDisclosure,
  editMode,
  selectedRecipients,
  contentOfMessage,
  idleTimeout
}) => {
  // console.log(contentOfMessage ? contentOfMessage.header.to : 'xx');
  // var ckeditor = null;
  // const [files, setFiles] = React.useState([]);
  // const [imageSrc, setImageSrc] = useState(undefined);
  // const contactsDisclosure = useDisclosure({
  //   onClose: () => {

  //   }
  // });
  const passwordDisclosure = useDisclosure({
    onOpen: () => {},
  });
  const normalAddressDisclosure = useDisclosure({
    defaultIsOpen: false, //window.appConfig.showComposeTips,
    onOpen: () => {},
    onClose: () => {
      // if (isGotIt) {
      //   sendMessage().then(() => {}).catch(e => {
      //     console.error(e);
      //     showError(e);
      //   })
      // }
    },
  });
  const unverifiedDisclosure = useDisclosure({
    // defaultIsOpen: (!TypeUtils.isMobileSafari() && !window.appConfig.mailAddressNeedToBeVerified && !window.appConfig.unverifiedAlertIsHiddenOnEditorIsReady),//false
    defaultIsOpen:
      !window.appConfig.mailAddressNeedToBeVerified &&
      !window.appConfig.unverifiedAlertIsHiddenOnEditorIsReady,
    onOpen: () => {},
    onClose: () => {},
  });
  const loadDraftDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {},
    onClose: () => {},
  });
  const saveDraftDisclosure = useDisclosure({
    defaultIsOpen: false,
    onOpen: () => {},
    onClose: () => {},
  });

  const [encryptionType, setEncryptionType] = useState(MailEncryptionType.signal);
  const [encryptionKey, setEncryptionKey] = useState(null);

  // const [encryptionPasswordKey, setEncryptionPasswordKey] = useState(null);
  const [encryptionPassword, setEncryptionPassword] = useState(null);

  const [attachmentEncryptionKeys, setAttachmentEncryptionKeys] = useState({});
  const [messageUUID, setMessageUUID] = useState(null);
  // const [attachmentID, setAttachmentID] = useState(1);

  // const [messageType, setMessageType] = useState(MailEncryptionType.plaintext);

  const [sizeOfMailWriter, setSizeOfMailWriter] = useState("xl");
  const [subject, setSubject] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [draftBody, setDraftBody] = useState(null);

  // var toRef = createRef();;
  // var toRef = createRef();
  var subjectRef = createRef();
  var ckeditorRef = createRef();
  // const [isSending, setSending] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [updateCounter, setUpdateCounter] = useState(0);

  // const composeDisclosure = useDisclosure()
  const [toValues, setToValues1] = useState([]);
  const [toPlaceholder, setToPlaceholder] = useState(
    Strings.editor.content.editor.to.placeholder
  );
  const [froms, setFroms] = useState([window.appConfig.recentActiveAccount]);
  const [selectedFrom, setSelectedFrom] = useState(window.appConfig.recentActiveAccount);
  // const [isGotIt, setGotIt] = useState(false);
  const setToValues = (tos) => {
    const encryptionType = detectEncryptionType(tos);
    console.log('Encryption Type: ', encryptionType);
    setToValues1(tos);
  };

  const [untrustedRecipients, setUntrustedRecipients] = useState([]);
  function needTOFU() {
    return untrustedRecipients && untrustedRecipients.length > 0;
  }
  const [sendMailPharse, setSendMailPharse] = useState(
    SendMailPharse.ComposeMessage
  );
  const [messageBody, setMessageBody] = useState(null);
  function isSending() {
    if (
      sendMailPharse === SendMailPharse.ComposeMessage ||
      sendMailPharse === SendMailPharse.ConfirmRecipients
    ) {
      return false;
    }
    return true;
  }

  const S = Strings.editor;
  // console.log('editor texts: ', S);
  const [title, setTitle] = useState(S.title.new_message);

  // const [pickerItems, setPickerItems] = React.useState(countries);
  // const [selectedItems, setSelectedItems] = React.useState([]);

  // const handleCreateItem = (item) => {
  //   setPickerItems((curr) => [...curr, item]);
  //   setSelectedItems((curr) => [...curr, item]);
  // };

  // const handleSelectedItemsChange = (selectedItems) => {
  //   if (selectedItems) {
  //     setSelectedItems(selectedItems);
  //   }
  // };

  /*
    async function detectMailboxType(address) {
      try {
        const bundle = await window.mailService.getIdentityKeyBundle(address);
        await window.mailService.checkIdentityKey(address, bundle);
        return MailboxType.plexi;
      } catch (e) {
        console.error(e);
        if (e.name === 'ServerError' && e.code === ServerError.CODE_NOT_FOUND) {
          return MailboxType.normal;
        }
        throw e;
      }
    }

    async function detectMailEncryptionType(addresses) {
      
      for(let i=0; i<addresses.length; i++) {
        const address = addresses[i];
        const type = await detectMailboxType(address);
        if (type === MailboxType.normal) {
          return MailEncryptionType.password;
        }
      }

      return MailEncryptionType.signal;
    }
    */

  async function establishSessionsIfNeeded(addresses, localAddress=null) {
    const identityKeys = {};
    for (const address of addresses) {
      identityKeys[address] = await window.mailService.getIdentityKeyBundle(
        address
      );
    }
    await window.mailService.startSessionsIfNeeded(identityKeys, localAddress);
  }

  async function preSendMail(recipients, localAddress=null) {
    try {
      const addresses = recipients.filter(rcp => {
        // const isEthereumAddress = MailAddressUtils.isValidEthereumAddress(
        //   rcp.local
        // );
        // return isEthereumAddress;
        return rcp.domain.toLowerCase() === mailAddressDomain();
      }).map((rcp) => {
        // const index = rcp.indexOf('@');
        // if (index === -1) {
        //   return rcp;
        // }
        // const address = rcp.substring(0, index);

        // return address;
        return rcp.local.toLowerCase();
      });

      await establishSessionsIfNeeded(addresses, localAddress);

      // return MailEncryptionType.signal;
      return encryptionType;
    } catch (e) {
      console.error(e);
      throw e;
    }

    // const method = await detectMailEncryptionType(addresses);
    // if (method === MailEncryptionType.password) {
    //   await askForPassword()
    // } else if (method === MailEncryptionType.signal) {
    //   await establishSessionsIfNeeded(addresses);
    // } else {
    //   throw ClientError.invalidParameterError('Unsupported Encryption Method');
    // }
  }

  useEffect(() => {
    // console.log('MailEditor');
  }); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (idleTimeout) {
      saveDraft().then(() => {
        if (window.onDraftSaved) {
          window.onDraftSaved();
        }
      });
    }
  }, [idleTimeout])// eslint-disable-line react-hooks/exhaustive-deps
  function formatPassword(password) {
    if (!password || password.length === 0) {
      return password;
    }
    const arr = [];
    for (let i = 0; i < password.length; i += 4) {
      if (i + 4 >= password.length) {
        const chunk = password.substring(i);
        arr.push(chunk);
      } else {
        const chunk = password.substring(i, i + 4);
        arr.push(chunk + " ");
      }
    }
    return arr.join("");
  }
  async function initialNewMessageIfNeeded() {
    if (encryptionKey === null) {
      if (await window.mailService.cryptoService.isInitialized) {
        const nextMessageEncryptionKey =
          await window.mailService.createEncryptionKey();
        setEncryptionKey(nextMessageEncryptionKey);
      }
    }
    if (encryptionPassword === null) {
      let password = await window.mailService.generatePassword(16);
      password = formatPassword(password);
      setEncryptionPassword(password);
    }

    if (messageUUID === null) {
      const nextMessageUUID = await window.mailService.generateUUID();
      setMessageUUID(nextMessageUUID);
    }
  }
  
  
  const isMe = (address) => {
    if (!froms) {
      return false;
    }
    for(const from of froms) {
      if (from.address === address) {
        return true;
      }
    }
    return false;
  };

  const initiaContentOfEditor = () => {

    if (editMode === "new") {
      setTitle(S.title.new_message);
      if (selectedRecipients && selectedRecipients.length > 0) {
        setToValues(selectedRecipients);
      }
    }
    if (contentOfMessage && contentOfMessage.header) {
      if (editMode === "new") {
        setTitle(S.title.new_message);
        if (selectedRecipients && selectedRecipients.length > 0) {
          setToValues(selectedRecipients);
        }
      } else if (editMode === "reply") {
        setTitle(S.title.reply);
        if (contentOfMessage.header && contentOfMessage.header.to) {
          const to = MailAddressUtils.parseAddressArray(contentOfMessage.header.to);
          for(const addr of to) {
            if (isMe(addr.local.toLowerCase())) {
              setSelectedFrom(addr.local.toLowerCase());
              break;
            }
          }
        }
        if (contentOfMessage.header && contentOfMessage.header.from) {
          try {
            const fromAddress = window.mailService.mapContact(
              contentOfMessage.header.from,
              selectedAccount
            );
            // const fromAddress = MailAddressUtils.parseOneAddress(contentOfMessage.header.from);
            setToValues([fromAddress]);

            // setToValues([contentOfMessage.header.from]);

            if (toPlaceholder !== "") {
              setToPlaceholder("");
            }
          } catch (e) {
            console.error(e);
            showError(e);
          }
        }
        if (contentOfMessage.header && contentOfMessage.header.attachments) {
          window.mailService
            .getDecodedEncryptionKeyBundle(contentOfMessage.header.uid)
            .then((keyBundle) => {
              setAttachmentEncryptionKeys(keyBundle.parties || {});
              setAttachments([...contentOfMessage.header.attachments]);
            });
          // setAttachments([...contentOfMessage.header.attachments]);
        }
      } else if (editMode === "reply-all") {
        setTitle(S.title.reply_all);
        if (contentOfMessage.header && contentOfMessage.header.to) {
          const fromAddObj = contentOfMessage.header.from
            ? MailAddressUtils.parseOneAddress(contentOfMessage.header.from)
            : null;

          var to = [
            ...contentOfMessage.header.to.filter((addr) => {
              const addrObj = MailAddressUtils.parseOneAddress(addr);
              if (!addrObj || !addrObj.local || addrObj.local === "") {
                return false;
              }

              if (fromAddObj && fromAddObj.local === addrObj.local) {
                return false;
              }

              // return addrObj.local.toLowerCase() !== selectedAccount;
              const isMyAddress = isMe(addrObj.local.toLowerCase());
              if (isMyAddress) {
                setSelectedFrom(addrObj.local.toLowerCase());
              } 
              return !isMyAddress;
              // let index = addr.indexOf('@');
              // if (index === -1) {
              //   return false;
              // }
              // let account = addr.substring(0, index);
              // return account !== selectedAccount
            }),
          ];
          if (contentOfMessage.header.from) {
            const fromObj = MailAddressUtils.parseOneAddress(
              contentOfMessage.header.from
            );
            if (fromObj) {
            }
          }
          if (!to.includes(contentOfMessage.header.from)) {
            to.unshift(contentOfMessage.header.from);
          }

          try {
            to = to.map((a) => {
              // const toAddr = MailAddressUtils.parseOneAddress(a);
              // if (toAddr.name && toAddr.name !== '') {
              //   return toAddr;
              // }
              return window.mailService.mapContact(a, selectedAccount);
            });
            setToValues(to);

            if (toPlaceholder !== "") {
              setToPlaceholder("");
            }
          } catch (e) {
            console.error(e);
            showError(e);
          }
        }
        if (contentOfMessage.header && contentOfMessage.header.attachments) {
          window.mailService
            .getDecodedEncryptionKeyBundle(contentOfMessage.header.uid)
            .then((keyBundle) => {
              setAttachmentEncryptionKeys(keyBundle.parties || {});
              setAttachments([...contentOfMessage.header.attachments]);
            });
          // setAttachments([...contentOfMessage.header.attachments]);
        }
      } else if (editMode === "forward") {
        setTitle(S.title.forward);
        if (contentOfMessage.header && contentOfMessage.header.to) {
          const to = MailAddressUtils.parseAddressArray(contentOfMessage.header.to);
          for(const addr of to) {
            if (isMe(addr.local.toLowerCase())) {
              setSelectedFrom(addr.local.toLowerCase());
              break;
            }
          }
        }
        if (contentOfMessage.header && contentOfMessage.header.attachments) {
          window.mailService
            .getDecodedEncryptionKeyBundle(contentOfMessage.header.uid)
            .then((keyBundle) => {
              setAttachmentEncryptionKeys(keyBundle.parties || {});
              setAttachments([...contentOfMessage.header.attachments]);
            });
          // setAttachments([...contentOfMessage.header.attachments]);
        }
      }
      setSubject(newSubject(editMode, contentOfMessage.header.subject));
      //subjectRef.current.value = contentOfMessage.header.subject;
      //ckeditorRef.current.editor.setData(contentOfMessage.body);
    }

    if (editMode === "new" && !unverifiedDisclosure.isOpen) {
      hasDraft().then((has) => {
        if (has) {
          loadDraftDisclosure.onOpen();
        }
      });
    }
  };

  useEffect(() => {
    console.log("editMode...................");
    if (!composeDisclosure.isOpen) {
      return;
    }
    window.addEventListener("beforeunload", (ev) => {
      if (attachments && attachments.length > 0) {
        attachments.forEach((att) => {
          if (att.uploading && att.abortController) {
            att.abortController.abort();
            att.abortController = null;
          }
        });
      }
    });
    window.mailService.getOrderedAddresses(true).then((addresses) => {
      setFroms(addresses);
      return initialNewMessageIfNeeded()
    }).then(() => {
      initiaContentOfEditor();
    }).catch(e => {
      console.error(e);
      showError(e);
    })

  }, [composeDisclosure.isOpen]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {

    if (editMode === "reply" || editMode === 'reply-all' || editMode === 'forward') {
      if (contentOfMessage.header.to) {
        const to = MailAddressUtils.parseAddressArray(contentOfMessage.header.to);
        for(const addr of to) {
          if (isMe(addr.local.toLowerCase())) {
            setSelectedFrom(addr.local.toLowerCase());
            break;
          }
        }
      }
    }
    // initiaContentOfEditor();

  }, [froms.length])


  // if (contentOfMessage && contentOfMessage.header) {
  //     if (contentOfMessage.header && contentOfMessage.header.to && toValues.length === 0) {
  //         setToValues(contentOfMessage.header.to);
  //     }
  //     subjectRef.current.value = contentOfMessage.header.subject;
  //     ckeditorRef.current.editor.setData(contentOfMessage.body);
  // }
  // const composeDisclosure = useDisclosure({defaultIsOpen: true});

  // const updateFiles = (incommingFiles) => {
  //   setFiles(incommingFiles);
  // };
  // const onDelete = (id) => {
  //   setFiles(files.filter((x) => x.id !== id));
  // };
  // const handleSee = (imageSource) => {
  //   setImageSrc(imageSource);
  // };
  // const handleClean = (files) => {
  //   console.log("list cleaned", files);
  //   setFiles([]);
  // };

  const toggleMailWriterFullScreen = () => {
    if (sizeOfMailWriter === "xl") {
      setSizeOfMailWriter("full");
    } else if (sizeOfMailWriter === "full") {
      setSizeOfMailWriter("xl");
    }
  };

  function hasTraditionalMailRecipient() {
    if (!toValues || toValues.length === 0) {
      return false;
    }
    for (const to of toValues) {
      // const isEthereumAddress = MailAddressUtils.isValidEthereumAddress(
      //   to.local
      // );
      const isEthereumAddress = to.domain.toLowerCase() === mailAddressDomain();
      if (!isEthereumAddress) {
        return true;
      }
    }
    return false;
  }


  function detectEncryptionTypeV1(toValues) {

    if (!toValues || toValues.length === 0) {
      setEncryptionType(MailEncryptionType.signal);
      return MailEncryptionType.signal;
    }

    let signalCount = 0;
    let passwordCount = 0;
    for (const to of toValues) {
      // const isEthereumAddress = MailAddressUtils.isValidEthereumAddress(
      //   to.local
      // );
      const isEthereumAddress = to.domain.toLowerCase() === mailAddressDomain();
      if (isEthereumAddress) {
        signalCount += 1;
      } else {
        passwordCount += 1;
      }
    }
    if (passwordCount === toValues.length) {
      setEncryptionType(MailEncryptionType.password);
      return MailEncryptionType.password;
    }
    if (signalCount === toValues.length) {
      setEncryptionType(MailEncryptionType.signal);
      return MailEncryptionType.signal;
    }
    setEncryptionType(MailEncryptionType.mixed);
    return MailEncryptionType.mixed;
  }

  function detectEncryptionType(toValues) {

    if (!toValues || toValues.length === 0) {
      setEncryptionType(MailEncryptionType.signal);
      return MailEncryptionType.signal;
    }

    let signalCount = 0;
    let passwordCount = 0;
    for (const to of toValues) {
      if (to.domain.toLowerCase() === mailAddressDomain()) {
        signalCount += 1;
      } else {
        passwordCount += 1;
      }
      // const isEthereumAddress = MailAddressUtils.isValidEthereumAddress(
      //   to.local
      // );
      // if (isEthereumAddress) {
      //   signalCount += 1;
      // } else {
      //   passwordCount += 1;
      // }
    }
    if (passwordCount === toValues.length) {
      setEncryptionType(MailEncryptionType.password);
      return MailEncryptionType.password;
    }
    if (signalCount === toValues.length) {
      setEncryptionType(MailEncryptionType.signal);
      return MailEncryptionType.signal;
    }
    setEncryptionType(MailEncryptionType.mixed);
    return MailEncryptionType.mixed;
  }

  async function hasDraft() {
    const has = await window.mailService.hasDraft('mail');
    return has;
  }
  async function removeDraft() {
    await window.mailService.removeDraft('mail');
  }
  async function loadDraft() {
    const draft = await window.mailService.getDraft('mail');
    if (!draft) {
      return;
    }
    if (draft.password && draft.password.length > 0) {
      // const passKey = await window.mailService.createEncryptionKey(0, encryptionPassword);
      setEncryptionPassword(draft.password);
    }

    if (draft.to && draft.to.length > 0) {
      setToValues([...draft.to]);
      detectEncryptionType(draft.to);
    }
    if (draft.attachments && draft.attachments.length > 0) {
      setAttachments([...draft.attachments]);
    }
    if (draft.subject && draft.subject.length > 0) {
      setSubject(draft.subject);
    }
    if (draft.from && draft.from.length > 0) {
      const address = MailAddressUtils.parseOneAddress(draft.from)
      setSelectedFrom(address.local.toLowerCase());
    }

    // if (await window.mailService.cryptoService.isInitialized) {
    //   const nextMessageEncryptionKey =
    //     await window.mailService.createEncryptionKey();
    //   setEncryptionKey(nextMessageEncryptionKey);
    // }

    if (draft.password && draft.password.length > 0 && draft.to.length > 0) {
      // const isEthereumAddress = MailAddressUtils.isValidEthereumAddress(draft.to[0].local);
      // if (!isEthereumAddress) {
      //   const nextMessageEncryptionKey = await window.mailService.createEncryptionKey(0, draft.password);
      //   setEncryptionKey(nextMessageEncryptionKey);
      // }
      setEncryptionPassword(draft.password);
    }

    if (draft.uid && draft.uid.length > 0) {
      setMessageUUID(draft.uid);
    }
    if (draft.partKeys) {
      setAttachmentEncryptionKeys(draft.partKeys);
    }

    setDraftBody(draft.body);
    await removeDraft();
  }
  function needSaveDraft() {
    const to = toValues;
    const subject = subjectRef.current.value;
    const body = ckeditorRef.current.editor.getData();
    if (to && to.length > 0) {
      return true;
    }
    if (subject && subject.length > 0) {
      return true;
    }
    if (body) {
      const rawBody = body
        .replaceAll("<p>", "")
        .replaceAll("</p>", "")
        .replaceAll("<br>", "");
      if (rawBody !== "Sent by PlexiMail") {
        return true;
      }
    }
    return false;
  }
  async function saveDraft() {
    // const from = selectedAccount + mailAddressSuffix();
    const from = selectedFrom + mailAddressSuffix();
    const to = toValues;
    const subject = subjectRef.current.value;
    const body = ckeditorRef.current.editor.getData();

    const partKeys = structuredClone(attachmentEncryptionKeys);
    const draft = {
      uid: messageUUID,
      // encryptionKey: encryptionKey,
      from,
      to,
      subject,
      body,
      password: encryptionPassword,
      // partKeys: attachmentEncryptionKeys,
      partKeys: partKeys,
      attachments,
    };
    try {
      await window.mailService.saveDraft(draft, 'mail');
    } catch (e) {
      console.error(e);
      showError(e);
    }
  }

  async function sendTraditionalMail() {
    passwordDisclosure.onOpen();
  }

  async function assignPlexiMailAccount(password, email) {
    const accountManager = new AccountManager(window.mailService);
    const account = await accountManager.createAccount("Unknown", email);
    const encoded = await accountManager.encodeAccount(account);
    const content = await accountManager.createBody({
      selectedAccount,
      account,
      encoded,
    });
    account.password = password;

    // await accountManager.saveAccount(account);
    return { account, encoded, content };
  }

  async function shareSpace(email) {
    const accountManager = new AccountManager(window.mailService);
    const res = await accountManager.shareSpace(email);
    return res;
  }

  async function doSendTraditionalMail(
    password,
    shouldAssignAccount = false,
    canReply = true
  ) {
    // const from = selectedAccount + mailAddressSuffix();
    const from = selectedFrom + mailAddressSuffix();

    //const rfc822String = toRef.current.value;
    // const to = rfc822StringToAddresses(rfc822String);
    const to = toValues;
    // const to = MailAddressUtils.parseAddressArray(toValues);
    const subject = subjectRef.current.value;
    // const subject = document.getElementById('txtSubject').value;
    let body = ckeditorRef.current.editor.getData();
    if (shouldAssignAccount) {
      try {
        const email = to[0].address;
        const { content } = await assignPlexiMailAccount(password, email);
        if (content && content.length > 0) {
          body += content;
        }
      } catch (e) {
        console.error(e);
        // closeEditor();
        setUploading(false);
        setSendMailPharse(SendMailPharse.ComposeMessage);
        setUntrustedRecipients([]);
        showError(e);
        return;
      }
    }

    const sharedSpace = canReply ? await shareSpace(to[0].address) : null;

    let messageType = MailEncryptionType.password;

    const message = {
      uid: messageUUID,
      pid: 0,
      type: messageType,
      from,
      to,
      subject,
      sharedSpace,
      body,
      attachments: attachments,
    };

    setSendMailPharse(SendMailPharse.Sending);
    setUntrustedRecipients([]);

    try {
      // let nextMessageEncryptionKey = await window.mailService.createEncryptionKey(0, password);
      // setEncryptionKey(nextMessageEncryptionKey);

      // let nextMessageEncryptionKey = encryptionPasswordKey;
      // message.salt = nextMessageEncryptionKey.salt;

      // let nextMessageEncryptionKey = encryptionKey ? {...encryptionKey} : null;
      let nextMessageEncryptionKey = encryptionKey ? structuredClone(encryptionKey) : null
      // setEncryptionKey(nextMessageEncryptionKey);

      if (!nextMessageEncryptionKey) {
        throw new Error('Encryption Key not ready');
      }
      const partKeys = structuredClone(attachmentEncryptionKeys);
      const keyBundle = {
        pwd: password,
        main: nextMessageEncryptionKey,
        // parties: {...attachmentEncryptionKeys},
        parties: partKeys,
      };
      await window.mailService.sendMessage(keyBundle, message);

      const folders = { ...window.mailService.folders };
      delete folders[BACKUP_FOLDER];
      setFolders(folders);
      closeEditor();
      // showSuccess(Strings.editor.message.sent, 'bottom-right')
    } catch (e) {
      console.error(e);
      // closeEditor();
      setUploading(false);
      if (
        e.name === "ServerError" &&
        e.code === ServerError.CODE_NOTIFY_ERROR
      ) {
        setSendMailPharse(SendMailPharse.ComposeMessage);
        setUntrustedRecipients([]);
        showError(e);
      } else {
        setSendMailPharse(SendMailPharse.ComposeMessage);
        setUntrustedRecipients([]);
        showError(e);
      }
      // showError(e);
    }
  }
  async function sendMessage() {
    // if (!window.appConfig.mailAddressNeedToBeVerified && !window.appConfig.unverifiedAlertIsHiddenOnEditorIsReady) {
    //   unverifiedDisclosure.onOpen();
    // } else {
    //   await realSendMessage();
    // }

    await realSendMessage();
  }

  const [confirmPasswordTask, setConfirmPasswordTask] = useState(null);
  async function confirmPasswordIfNeeded() {
    if (encryptionType === MailEncryptionType.signal) {
      return null;
    }
    

    const task = {
      promise: null,
      resolve: null,
      reject: null
    };

    const promise = new Promise((resolve, reject) => {
      task.resolve = resolve;
      task.reject = reject;
    });
    task.promise = promise;
    setConfirmPasswordTask(task);
    passwordDisclosure.onOpen();
    return await promise;
  }

  async function realSendMessage() {
    if (encryptionType === MailEncryptionType.password) {//isTraditionalMailRecipient()
      // if (isGotIt) {
      //   setGotIt(false);
      //   await sendTraditionalMail();
      // } else {
      //   setGotIt(true);
      //   normalAddressDisclosure.onOpen();
      // }

      await sendTraditionalMail();
      return;
    }

    if (sendMailPharse === SendMailPharse.ComposeMessage) {
      try {
        await confirmPasswordIfNeeded();
        await checkRecipients();
      } catch (e) {
        console.error(e);
        showError(e);
      }
    } else if (sendMailPharse === SendMailPharse.ConfirmRecipients) {
      await doSendMessage();
    }
  }
  async function verifyIdentitySignature(address, identityKey, signature) {
    const keyBuf = ethers.decodeBase64(identityKey);
    const keyHex = ethers.hexlify(keyBuf);

    const recoveredAddr = window.web3Helper.ecRecover({
      data: keyHex,
      signature: signature,
    });
    return recoveredAddr.toLowerCase() === address;
  }
  async function checkRecipients() {
    try {
      console.info("send phase 1: check recipients");

      // const from = selectedAccount + mailAddressSuffix();
      const from = selectedFrom + mailAddressSuffix();
    
      //const rfc822String = toRef.current.value;
      // const to = rfc822StringToAddresses(rfc822String);

      const to = toValues;
      // const to = MailAddressUtils.parseAddressArray(toValues);
      // const subject = subjectRef.current.value;
      // const subject = document.getElementById('txtSubject').value;
      // const body = ckeditorRef.current.editor.getData();
      // const subject = document.getElementById('txtSubject').value;
      const body = messageBody;// ckeditorRef.current.editor.getData();

      let messageType = MailEncryptionType.password;
      const sharedSpace = encryptionType === MailEncryptionType.mixed ? await shareSpace(to[0].address) : null;

      const message = {
        uid: messageUUID,
        pid: 0,
        type: messageType,
        from,
        to,
        subject,
        body,
        sharedSpace,
        attachments,
      };

      setSendMailPharse(SendMailPharse.CheckRecipients);
      const recipients = to;
      // const recipients = toValues;
      const untrustedRecipients = [];
      for (const rcp of recipients) {

        if (encryptionType === MailEncryptionType.mixed) {

          const isEthereumAddress = MailAddressUtils.isValidEthereumAddress(
            rcp.local
          );
          if (!isEthereumAddress) {
            continue;
          }
        }

        const address = rcp.local.toLowerCase();
        if (isMe(address)) {
        // if (address === selectedAccount) {
          continue;
        }
        // const index = rcp.indexOf('@');
        // const address = (index === -1) ? rcp: rcp.substring(0, index);
        const trustedIdentityKey =
          await window.mailService.getTrustedIdentityKey(address);

        if (trustedIdentityKey) {
          const identityKeyBundle =
            await window.mailService.getIdentityKeyBundle(address);
          const identityKey = identityKeyBundle.identityKey;
          if (!identityKey || identityKey === "") {
            throw ClientError.invalidParameterError(
              address + Strings.error.client.not_in_blockchain
            );
          }
          if (identityKey !== trustedIdentityKey) {
            let signed = identityKeyBundle.identityKeyIsVerified;
            if (
              !identityKeyBundle.identityKeyIsVerified &&
              identityKeyBundle.signature
            ) {
              signed = await verifyIdentitySignature(
                address,
                identityKeyBundle.identityKey,
                identityKeyBundle.signature
              );
            }
            // untrustedRecipients.push({address: rcp, identityKey: trustedIdentityKey, type: 'changed'});
            untrustedRecipients.push({
              address: rcp.address,
              local: address,
              identityKey: identityKey,
              signed: signed,
              verified: identityKeyBundle.identityKeyIsVerified,
              verifiedBy: identityKeyBundle.verifiedBy,
              signature: identityKeyBundle.signature,
              type: "changed",
            });
          }
        } else {
          const identityKeyBundle =
            await window.mailService.getIdentityKeyBundle(address);
          const identityKey = identityKeyBundle.identityKey;
          if (!identityKey || identityKey === "") {
            throw ClientError.invalidParameterError(
              address + Strings.error.client.not_in_blockchain
            );
          }
          let signed = identityKeyBundle.identityKeyIsVerified;
          if (
            !identityKeyBundle.identityKeyIsVerified &&
            identityKeyBundle.signature
          ) {
            signed = await verifyIdentitySignature(
              address,
              identityKeyBundle.identityKey,
              identityKeyBundle.signature
            );
          }
          // untrustedRecipients.push({address: rcp, identityKey: identityKey, type: 'new'})
          untrustedRecipients.push({
            address: rcp.address,
            local: address,
            identityKey: identityKey,
            signed: signed,
            verified: identityKeyBundle.identityKeyIsVerified,
            verifiedBy: identityKeyBundle.verifiedBy,
            signature: identityKeyBundle.signature,
            type: "new",
          });
        }
      }

      if (untrustedRecipients.length > 0) {
        setUntrustedRecipients(untrustedRecipients);
        setSendMailPharse(SendMailPharse.ConfirmRecipients);
      } else {
        // setUntrustedRecipients(untrustedRecipients);
        // setSendMailPharse(SendMailPharse.Sending);
        await doSendMessage(message);
      }
    } catch (e) {
      console.error(e);
      setSendMailPharse(SendMailPharse.ComposeMessage);
      setUntrustedRecipients([]);
      showError(e);
    }
  }

  async function doSendMessage(message) {
    console.info("send phase 2: store message and notify recipients");

    if (!message) {
      // const from = selectedAccount + mailAddressSuffix();
      const from = selectedFrom + mailAddressSuffix();
    
      //const rfc822String = toRef.current.value;
      // const to = rfc822StringToAddresses(rfc822String);
      const to = toValues;
      // const to = MailAddressUtils.parseAddressArray(toValues);
      const subject = subjectRef.current.value;
      // const subject = document.getElementById('txtSubject').value;
      const body = ckeditorRef.current.editor.getData();
      let messageType = MailEncryptionType.password;

      const sharedSpace = encryptionType === MailEncryptionType.mixed ? await shareSpace(to[0].address) : null;

      message = {
        uid: messageUUID,
        pid: 0,
        type: messageType,
        from,
        to,
        subject,
        body,
        sharedSpace,
        attachments,
      };
    }

    setSendMailPharse(SendMailPharse.Sending);

    const changedRecpipients = untrustedRecipients.filter((rcp) => {
      return rcp.type === "changed";
    });
    if (changedRecpipients && changedRecpipients.length > 0) {
      await window.mailService.removeIdentityKeys(changedRecpipients);
    }

    setUntrustedRecipients([]);
    try {
      message.type = await preSendMail(message.to, selectedFrom);
    } catch (e) {
      console.error(e);
      setSendMailPharse(SendMailPharse.ComposeMessage);
      setUntrustedRecipients([]);
      showError(e);
      return;
    }

    // console.log(toRef);
    // setSending(true)
    // const attachments = files.map((file) => {return file.file;}) || [];

    // mailService.uploadAttachments(files);
    // let mailService = new MailService();
    // mailService.folders = folders;
    try {
      // let nextMessageEncryptionKey = encryptionKey ? {...encryptionKey} : null;
      let nextMessageEncryptionKey = encryptionKey ? structuredClone(encryptionKey) : null
      // if (encryptionKey === null) {
      //   nextMessageEncryptionKey =
      //     await window.mailService.createEncryptionKey();
      //   setEncryptionKey(nextMessageEncryptionKey);
      // }
      if (!nextMessageEncryptionKey) {
        throw new Error('Encryption Key not ready');
      }

      const pwd = (encryptionType === MailEncryptionType.mixed) ? encryptionPassword : null;
      if (encryptionPassword === null) {
        let password = await window.mailService.generatePassword(16);
        password = formatPassword(password);
        setEncryptionPassword(password);
      }

      const partKeys = structuredClone(attachmentEncryptionKeys);
      const keyBundle = {
        pwd,
        main: nextMessageEncryptionKey,
        // parties: {...attachmentEncryptionKeys},
        parties: partKeys,
      };

      await window.mailService.sendMessage(keyBundle, message);

      const folders = { ...window.mailService.folders };
      delete folders[BACKUP_FOLDER];
      setFolders(folders);
      closeEditor();
      // showSuccess(Strings.editor.message.sent, 'bottom-right')
    } catch (e) {
      console.error(e);
      // closeEditor();
      setUploading(false);
      if (
        e.name === "ServerError" &&
        e.code === ServerError.CODE_NOTIFY_ERROR
      ) {
        setSendMailPharse(SendMailPharse.ComposeMessage);
        setUntrustedRecipients([]);
        showError(e);
      } else {
        setSendMailPharse(SendMailPharse.ComposeMessage);
        setUntrustedRecipients([]);
        showError(e);
      }
      // showError(e);
    }
  }

  async function resetEditor() {
    // setGotIt(false);
    setToValues([]);
    setToPlaceholder(Strings.editor.content.editor.to.placeholder);

    setSubject("");
    if (ckeditorRef && ckeditorRef.current && ckeditorRef.current.editor) {
      ckeditorRef.current.editor.setData(
        "<br /><br /><br />" +
          Strings.editor.content.editor.content.initial_content
      );
    }
    setAttachments([]);

    setUploading(false);
    setSendMailPharse(SendMailPharse.ComposeMessage);
    setUntrustedRecipients([]);
    setDraftBody(null);
    setMessageBody(null);

    // setEncryptionKey(null);
    // setToValues(['xxxx']);
    // setFiles([]);
    // setImageSrc(undefined);
    const uuid = await window.mailService.generateUUID();
    const key = await window.mailService.createEncryptionKey();
    setMessageUUID(uuid);
    setEncryptionKey(key);

    let password = await window.mailService.generatePassword(16);
    password = formatPassword(password);
    setEncryptionPassword(password);
    // setEncryptionPasswordKey(null);

    setAttachmentEncryptionKeys({});
  }

  async function closeEditor() {
    await resetEditor();
    composeDisclosure.onClose();
  }

  function newSubject(editMode, subject) {
    let prefix = prefixesOfSubject[editMode];
    if (prefix) {
      if (subject.indexOf(prefix) === 0) {
        return subject;
      } else {
        return prefix + subject;
      }
    }
    //
    return subject;
  }
  function newBody(editMode, date, from, body) {
    if (editMode === "new") {
      if (draftBody) {
        // const v = draftBody;
        // setDraftBody(null);
        // return v;
        return draftBody;
      }
      return body;
    }
    const _newBody = `<br />${from} wrote:<br /><blockquote>${body}</blockquote>`;
    return _newBody;
  }
  /*
    const hasUnverifiedRecpipients = () => {
      if (!untrustedRecipients || untrustedRecipients.length === 0) {
        return false;
      }

      const unverifiedRecipients = untrustedRecipients.filter((r) => {
        return !r.verified;
      });
      return (unverifiedRecipients && unverifiedRecipients.length > 0);
    };
    */
  const handleFileUpload = (event) => {
    setUploading(true);

    console.log(event.target.files);

    const baseIndex = attachments.length;

    const nextAttachmentID =
      !attachments || attachments.length === 0
        ? 1
        : parseInt(attachments[attachments.length - 1].id) + 1;

    // window.mailService.uploadAttachments(nextAttachmentID, event.target.files, encryptionPasswordKey, {
    window.mailService
      .uploadAttachments(nextAttachmentID, event.target.files, null, {
        // signal: (controllerFetchDownload ? controllerFetchDownload.signal : null),
        preEncrypt: (idx, file, attID, key) => {
          attachments.push({
            id: attID,
            name: file.name,
            size: file.size,
            progress: 0,
            encrypting: true,
          });
          setAttachments([...attachments]);

          attachmentEncryptionKeys[attID] = key;
          setAttachmentEncryptionKeys({ ...attachmentEncryptionKeys });
        },
        postEncrypt: (idx, abortController) => {
          delete attachments[baseIndex + idx].encrypting;
          attachments[baseIndex + idx].uploading = true;
          attachments[baseIndex + idx].abortController = abortController;
          setAttachments([...attachments]);
        },
        onRootCidReady: (idx, file, cid, attID, key) => {
          // if ()
          if (attachments[baseIndex + idx]) {
            attachments[baseIndex + idx].cid = cid;
          } else {
            attachments.push({
              id: attID,
              cid: cid,
              name: file.name,
              size: file.size,
              progress: 0,
            });
          }

          // attachments.push({
          //   id: attID,
          //   cid: cid,
          //   name: file.name,
          //   size: file.size,
          //   progress: 0
          // });

          // setAttachments([...attachments]);
          // attachmentEncryptionKeys[attID] = key;
          // setAttachmentEncryptionKeys({...attachmentEncryptionKeys});
        },
        onUploadProgress: (idx, file, event) => {
          if (
            attachments[baseIndex + idx] &&
            !attachments[baseIndex + idx].abortController
          ) {
            return;
          }
          attachments[baseIndex + idx].size = event.total;
          attachments[baseIndex + idx].progress = event.loaded;
          setAttachments([...attachments]);
        },
        onStoredChunk: (idx, file, size) => {
          if (
            attachments[baseIndex + idx] &&
            !attachments[baseIndex + idx].abortController
          ) {
            return;
          }
          attachments[baseIndex + idx].progress += size;
          setAttachments([...attachments]);
        },
        onStoredFile: (idx, file, cid) => {
          if (
            attachments[baseIndex + idx] &&
            !attachments[baseIndex + idx].abortController
          ) {
            return;
          }
          if (cid && cid.length > 0) {
            attachments[baseIndex + idx].cid = cid;
          }
          attachments[baseIndex + idx].progress =
            attachments[baseIndex + idx].size;
          delete attachments[baseIndex + idx].uploading;
          delete attachments[baseIndex + idx].abortController;
          setAttachments([...attachments]);
        },
      })
      .then((cid) => {
        setUploading(false);
        console.log("is uploaded: ", cid);
        document.getElementById("attachment-file").value = "";
        // setAttachmentID(attachments.length + 1);
      })
      .catch((e) => {
        setUploading(false);
        // alert(reason.toString());
        console.error(e);

        if (attachments.length > 0) {
          const atts = attachments.slice(0, attachments.length - 1);
          setAttachments([...atts]);
          // setAttachmentID(atts.length + 1);
        }

        document.getElementById("attachment-file").value = "";
        showError(e);
      });
  };
  const addAttachment = () => {
    document.getElementById("attachment-file").click();
  };
  function niceBytes(x) {
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let l = 0,
      n = parseInt(x, 10) || 0;
    while (n >= 1024 && ++l) {
      n = n / 1024;
    }
    return n.toFixed(n < 10 && l > 0 ? 1 : 0) + " " + units[l];
  }

  const formatFilename = (filename) => {
    if (!filename) {
      return "";
    }
    if (filename.length <= 48) {
      return filename;
    }
    const idx = filename.lastIndexOf(".");
    if (idx <= 0) {
      return filename.substring(0, 46) + "..";
    }
    const ext = filename.substring(idx);
    return filename.substring(0, 46 - ext.length) + ".." + ext;
  };

  /*
  const onInput = (e) => {
    console.log('*** onInput: ', e)
  }
  const onFocus = (e) => {
    console.log('*** onFocus: ', e)
  }
  const onBlur = (e) => {
    console.log('*** onBlur: ', e)

  }
  */

  const onDelete = useCallback(
    (tagIndex) => {
      setToValues(toValues.filter((_, i) => i !== tagIndex));
    },
    [toValues]
  );

  const onAddition = useCallback(
    (newTag) => {
      // console.log('onAddition: ', newTag);
      // if (!newTag.value) {
        if (newTag.label === newTag.value) {
        const address1 = MailAddressUtils.parseOneAddress(newTag.value);
        if (address1.name && address1.name.length > 0) {
          setToValues([...toValues, address1]);
        } else {
          const address = window.mailService.mapContact(
            newTag.label,
            selectedAccount
          );
          // const address = MailAddressUtils.parseOneAddress(newTag.name);
          setToValues([...toValues, address]);
        }
      } else {
        const email = '"' + newTag.label + '" <' + newTag.value.toLowerCase() + ">";
        const address = MailAddressUtils.parseOneAddress(email);
        setToValues([...toValues, address]);
      }
    },
    [toValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const onValidate = (newTag) => {
    let isValid = false;
    let address = null;
    if (typeof newTag === 'string') {
      isValid = MailAddressUtils.isValidEmailAddress(newTag, false);
      if (isValid) {
        address = MailAddressUtils.parseOneAddress(newTag);
      }
    } else {
      if (!newTag.value) {
        isValid = MailAddressUtils.isValidEmailAddress(newTag.label, false);
        if (isValid) {
          address = MailAddressUtils.parseOneAddress(newTag.label);
        }
      } else {
        const email = '"' + newTag.label + '" <' + newTag.value.toLowerCase() + ">";
        isValid = MailAddressUtils.isValidEmailAddress(email, false);
        if (isValid) {
          address = MailAddressUtils.parseOneAddress(email);
        }
      }
    }

    if (!isValid) {
      return "invalid-addr";
    }

    if (address) {
      return "valid";
    }
    return "invalid-addr";
  };
  // const isValidPlexiMailAddress = () => {

  //   if (!toValues || toValues.length === 0) {
  //     return true;
  //   }
  //   const allowsEthereumAddress = MailAddressUtils.isValidEthereumAddress(toValues[0].local);
  //   return allowsEthereumAddress;
  // }

  const isReadyToUploadAttachment = () => {
    if (!toValues || toValues.length === 0) {
      return false;
    }
    return true;
  };

  const onScanned = (result) => {
    try {
      const address1 = MailAddressUtils.parseOneAddress(result);
      if (!address1) {
        return;
      }
      if (address1.name && address1.name.length > 0) {
        setToValues([...toValues, address1]);
      } else {
        const address = window.mailService.mapContact(result, selectedAccount);
        setToValues([...toValues, address]);
      }
    } catch (e) {
      console.error(e);
      showError(e);
    }
  };
  const truncateString = (s, limit = 0) => {
    if (limit !== 0 && s.length > limit) {
      s = s.substring(0, limit) + "...";
    }
    return s;
  };

  const b64ToHex = (s, limit = 0) => {
    const a = ethers.decodeBase64(s);
    const h = ethers.hexlify(a);
    return truncateString(h, limit);
  };

  return (
    <Modal
      size={sizeOfMailWriter}
      isOpen={composeDisclosure.isOpen}
      onClose={() => {}}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize={16}>
          {
            sizeOfMailWriter === "xl" && (
              <Tooltip label={"Full screen"}>
                <IconButton
                  background={"none"}
                  icon={<ReactAIIcons.AiOutlineFullscreen />}
                  onClick={toggleMailWriterFullScreen}
                  mr={1}
                />
              </Tooltip>
            )
            // <Button leftIcon={<ReactAIIcons.AiOutlineFullscreenExit />} onClick={toggleMailWriterFullScreen} >Back</Button>  bg="none"
          }
          {
            sizeOfMailWriter === "full" && (
              <Tooltip label={"Exit full screen"}>
                <IconButton
                  background={"none"}
                  icon={<ReactAIIcons.AiOutlineFullscreenExit />}
                  onClick={toggleMailWriterFullScreen}
                  mr={1}
                />
              </Tooltip>
            )
            // <Button leftIcon={<ReactAIIcons.AiOutlineFullscreenExit />} onClick={toggleMailWriterFullScreen}>Back</Button>
          }
          {title}
          <ModalCloseButton
            onClick={(e) => {
              e.preventDefault();
              if (needSaveDraft()) {
                saveDraftDisclosure.onOpen();
              } else {
                closeEditor();
              }
            }}
            disabled={isUploading || isSending()}
          />
        </ModalHeader>

        <ModalBody>
          <Toaster position="bottom-center" />
          <Box hidden={!needTOFU()}>
            <Text fontSize={"sm"} mt={3}>
              {S.content.tofu.message}
            </Text>
            {/* <Text fontSize={"sm"} mt={3} >{S.content.tofu.verify}</Text> */}
            {S.content.tofu.verify}
            {/* <Text fontSize={"sm"} mt={3} >{S.content.tofu.steps}</Text> */}
            {/* {S.content.tofu.steps} */}
            {/* {hasUnverifiedRecpipients() && 
                      <>
                      <Text fontSize={"sm"} mt={3} >{S.content.tofu.verify}</Text>
                      <Text fontSize={"sm"} mt={3} >{S.content.tofu.steps}</Text>
                      </>
                      } */}
            <Text mt={3} fontSize={"sm"}>
              {S.content.tofu.title}
            </Text>

            <List mt={3} spacing={3} textColor="gray.600" fontSize={"12px"}>
              {untrustedRecipients.length > 0 &&
                untrustedRecipients.map((recipient, index) => (
                  <ListItem id={recipient.address} key={recipient.address}>
                    <HStack
                      py={1}
                      borderBottom={"1px solid lightGray"}
                      justifyContent={"space-between"}
                    >
                      <Flex>
                        <Avatar name={recipient.address} textColor={"white"} />
                        <VStack ml={2} verticalAlign={"middle"} align={"left"}>
                          <CopyToClipboard
                            text={recipient.local}
                            cursor={"pointer"}
                            onCopy={() => {
                              toast(
                                `${S.content.tofu.address} is ${S.content.tofu.copied}`
                              );
                              setUpdateCounter(updateCounter + 1);
                            }}
                          >
                            <Text wordBreak={"break-all"} fontWeight={"bold"}>
                              {recipient.address}
                            </Text>
                          </CopyToClipboard>

                          {!recipient.verified && (
                            <CopyToClipboard
                              text={b64ToHex(recipient.identityKey)}
                              cursor={"pointer"}
                              onCopy={() => {
                                toast(
                                  `${S.content.tofu.idkey} is ${S.content.tofu.copied}`
                                );
                                setUpdateCounter(updateCounter + 1);
                              }}
                            >
                              <Text wordBreak={"break-all"}>
                                <strong>{S.content.tofu.idkey}:</strong>&nbsp;
                                {b64ToHex(recipient.identityKey, 32)}
                              </Text>
                            </CopyToClipboard>
                          )}

                          {recipient.signature && (
                            <CopyToClipboard
                              text={recipient.signature}
                              cursor={"pointer"}
                              onCopy={() => {
                                toast(
                                  `${S.content.tofu.signature} is ${S.content.tofu.copied}`
                                );
                                setUpdateCounter(updateCounter + 1);
                              }}
                            >
                              <Text wordBreak={"break-all"}>
                                <strong>{S.content.tofu.signature}:</strong>
                                &nbsp;{truncateString(recipient.signature, 32)}
                                &nbsp;
                                {recipient.signed
                                  ? S.content.tofu.correct
                                  : S.content.tofu.incorrect}
                              </Text>
                            </CopyToClipboard>
                          )}
                          {!recipient.signed &&
                            (!recipient.signature ||
                              recipient.signature.length === 0) && (
                              <Text wordBreak={"break-all"}>
                                <strong>{S.content.tofu.signature}:</strong>
                                &nbsp;{S.content.tofu.miss}
                              </Text>
                            )}
                          {/* <Text>{recipient.identityKey}</Text> */}
                        </VStack>
                      </Flex>
                      {recipient.verified && (
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              icon={<GoVerified size={"20"} />}
                              background={"none"}
                              color="green"
                            />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader color={"green"} fontWeight={"bold"}>
                              {
                                Strings.app.dialog.address_verify_popup.verified
                                  .title
                              }
                              {/* &nbsp;by&nbsp;{recipient.verifiedBy} */}
                            </PopoverHeader>
                            <PopoverBody color={"green"}>
                              {
                                Strings.app.dialog.address_verify_popup.verified
                                  .message
                              }
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      )}

                      {!recipient.verified && (
                        <Popover>
                          <PopoverTrigger>
                            <IconButton
                              icon={<GoUnverified size={"20"} />}
                              background={"none"}
                              color="orange"
                            />
                          </PopoverTrigger>
                          <PopoverContent>
                            <PopoverArrow />
                            <PopoverCloseButton />
                            <PopoverHeader color={"orange"} fontWeight={"bold"}>
                              {
                                Strings.app.dialog.address_verify_popup
                                  .unverified.title
                              }
                            </PopoverHeader>
                            <PopoverBody color={"orange"}>
                              {
                                Strings.app.dialog.address_verify_popup
                                  .unverified.message
                              }
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      )}
                    </HStack>
                  </ListItem>
                ))}
            </List>
          </Box>
          <Box hidden={needTOFU()}>
            {/* <TagEditor tags={["hello"]} delimiters={[]} placeholder="," /> */}

            <FormLabel mt={2} fontSize={14}>
              {S.content.editor.from.title}{" "}
            </FormLabel>
            <Select value={selectedFrom} fontSize={"sm"} flex={1} onChange={(e) => {
              // console.log('************* ', e);
              setSelectedFrom(e.target.value);
            }}>
              {froms && froms.map((from, idx) => {
                return (<option key={`${from.address}-key`} value={from.address}>{from.label} - {from.address + mailAddressSuffix()}</option>)
              })}
              
            </Select>
            {/* <Flex justifyContent={"space-between"}>
            <Text>From: </Text>  
            </Flex> */}

            <HStack alignItems={"center"} justifyContent={"space-between"}>
              <FormLabel fontSize={14}>{S.content.editor.to.title} </FormLabel>
              {/* title={"Scan address QRCode"} */}
              <QRCodeSourceChooseConfirmationButton
                withoutBackground={true}
                onCompleted={(value) => {
                  onScanned(value);
                }}
              />
            </HStack>
            <NormalEmailAddressTips disclosure={normalAddressDisclosure} />
            <AddressInput
              disabled={isSending()}
              selectedAccount={selectedAccount}
              toValues={toValues}
              // onInput={onInput}
              // onFocus={onFocus}
              // onBlur={onBlur}
              onDelete={onDelete}
              onAddition={onAddition}
              onValidate={onValidate}
            />

            <FormLabel mt={2} fontSize={14}>
              {S.content.editor.subject.title}{" "}
            </FormLabel>
            {/* <Input id="txtSubject" value={subject} onChange={(event)=> { */}
            <Input
              id="txtSubject"
              disabled={isSending()}
              fontSize={14}
              ref={subjectRef}
              placeholder={S.content.editor.subject.placeholder}
              value={subject}
              onChange={(event) => {
                setSubject(event.target.value);
              }}
              mb={2}
            />
            {/* <Lorem count={2} /> */}
            <Box display={"inline-block"} width={"100%"}>
            <CKEditor
              // disabled={true}
            //   config={ {
            //     toolbar: ["bold","italic","fontsize", "|","imageUpload","blockQuote","insertTable","undo","redo"]
            // } }
              disabled={isSending()}
              ref={ckeditorRef}
              editor={ClassicEditor}
              // config={ { plugins: [ ClassicEditor.Base64UploadAdapter] } }
              data={newBody(
                editMode,
                contentOfMessage && contentOfMessage.header
                  ? contentOfMessage.header.sendDate || ""
                  : "",
                contentOfMessage && contentOfMessage.header
                  ? contentOfMessage.header.from
                  : "",
                contentOfMessage && contentOfMessage.body
                  ? contentOfMessage.body
                  : "<br /><br /><br />Sent by PlexiMail"
              )}
              onReady={(editor) => {
                // ckeditor = editor;
                editor.editing.view.change((writer) => {
                  // writer.setStyle(
                  //   "min-height",
                  //   "200px",
                  //   editor.editing.view.document.getRoot()
                  // );
                  // writer.setStyle(
                  //   "max-height",
                  //   "350px",
                  //   editor.editing.view.document.getRoot()
                  // );
                  // writer.setStyle(
                  //   "font-size",
                  //   "14px",
                  //   editor.editing.view.document.getRoot()
                  // );
                  // writer.setStyle(
                  //   "padding-left",
                  //   "25px",
                  //   editor.editing.view.document.getRoot()
                  // );
                  // writer.setStyle(
                  //   "padding-right",
                  //   "25px",
                  //   editor.editing.view.document.getRoot()
                  // );
                  // writer.setStyle(
                  //   "width",
                  //   "600px",
                  //   editor.editing.view.document.getRoot()
                  // );
                });
                // You can store the "editor" and use when it is needed.
                // console.log("Editor is ready to use!", editor);
                setMessageBody(editor.getData());
                // console.log('****** editor is ready: ', editor.getData());
              }}
              onChange={(event, editor) => {
                // const data = editor.getData();
                const data = editor.getData();
                setMessageBody(data);
                // console.log( { event, editor, data } );
              }}
              onBlur={(event, editor) => {
                // console.log( 'Blur.', editor );
              }}
              onFocus={(event, editor) => {
                // console.log( 'Focus.', editor );
              }}
            /></Box>
            <Text
              mt={3}
              fontSize={14}
              fontWeight={"var(--chakra-fontWeights-medium)"}
            >
              {Strings.editor.content.editor.attachment.title}
            </Text>
            {/* <List spacing={3} textColor="gray.600" fontSize={"12px"} hidden={!isValidPlexiMailAddress()}> */}
            <List
              spacing={3}
              textColor="gray.600"
              fontSize={"12px"}
              hidden={!isReadyToUploadAttachment()}
            >
              {attachments.length > 0 &&
                attachments.map((attachment, index) => (
                  <ListItem
                    id={"attachment-item-" + attachment.id}
                    key={"attachment-item-" + attachment.id}
                    cursor={"pointer"}
                    onClick={() => {}}
                  >
                    {/* <HStack borderBottom={"1px solid lightGray"} justifyContent={'space-between'}> */}
                    <Flex w={"100%"}>
                      <Center mr={2}>
                        <Avatar name={attachment.name} textColor={"white"} />
                      </Center>
                      <VStack flex={1} align={"left"}>
                        {attachment.name && attachment.name.length > 48 && (
                          <Tooltip label={attachment.name}>
                            <Text fontWeight={"bold"}>
                              {formatFilename(attachment.name)}
                            </Text>
                          </Tooltip>
                        )}
                        {(!attachment.name || attachment.name.length <= 48) && (
                          <Text fontWeight={"bold"}>
                            {formatFilename(attachment.name)}
                          </Text>
                        )}
                        <Text>{niceBytes(attachment.size)}</Text>
                        {attachment.encrypting && (
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: S.message.upload.encrypting,
                            }}
                          ></Text>
                        )}
                        {/* {!attachment.encrypting &&
                                      <Text dangerouslySetInnerHTML={ {__html: ((attachment.progress < attachment.size) ? S.message.upload.paragraph1_3 : '')} }></Text>
                                      } */}
                        {/* {(window.appConfig.web3ConfigMode !== Web3ConfigMode.W3UI) &&
                                      <Text dangerouslySetInnerHTML={ {__html: ((attachment.progress < attachment.size) ? (S.message.upload.paragraph1_1 + niceBytes(attachment.progress) + S.message.upload.paragraph1_2) : '')} }></Text>
                                      } */}
                        {/* <Progress colorScheme='green' size='md' value={20} /> */}
                        {!attachment.encrypting && (
                          <Text
                            dangerouslySetInnerHTML={{
                              __html:
                                attachment.progress < attachment.size
                                  ? S.message.upload.paragraph1_4 +
                                    niceBytes(attachment.progress) +
                                    S.message.upload.paragraph1_5 +
                                    niceBytes(attachment.size)
                                  : "",
                            }}
                          ></Text>
                        )}

                        {/* <Text dangerouslySetInnerHTML={ {__html: ((attachment.progress < attachment.size) ? (S.message.upload.paragraph1_1 + niceBytes(attachment.progress) + ' / ' + niceBytes(attachment.size) + S.message.upload.paragraph1_2) : '')} }></Text> */}
                        {attachment.uploading && (
                          <Progress
                            h={1}
                            colorScheme="green"
                            size="md"
                            value={
                              (attachment.progress / attachment.size) * 100
                            }
                          />
                        )}
                      </VStack>
                      <Center ml={2}>
                        <CloseButton
                          disabled={
                            (isUploading &&
                              !(
                                attachment.uploading && attachment.progress > 0
                              )) ||
                            isSending()
                          }
                          onClick={(e) => {
                            // setUploading(false)
                            const attachment = attachments[index];
                            if (attachment.abortController) {
                              attachment.abortController.abort();
                              attachment.abortController = null;

                              if (attachmentEncryptionKeys[attachment.id]) {
                                delete attachmentEncryptionKeys[attachment.id];
                              }
                              attachments.splice(index, 1);
                              setAttachments([...attachments]);
                              setAttachmentEncryptionKeys({...attachmentEncryptionKeys});
                            } else {
                              if (attachment.cid && attachment.cid.length > 0) {
                                window.mailService
                                  .removeFile(attachment.cid, false)
                                  .then(() => {
                                    if (
                                      attachmentEncryptionKeys[attachment.id]
                                    ) {
                                      delete attachmentEncryptionKeys[
                                        attachment.id
                                      ];
                                    }
                                    attachments.splice(index, 1);
                                    setAttachments([...attachments]);
                                    setAttachmentEncryptionKeys({...attachmentEncryptionKeys});
                                  })
                                  .catch((e) => {
                                    console.error(e);
                                    showError(e);
                                  });
                              } else {
                                if (attachmentEncryptionKeys[attachment.id]) {
                                  delete attachmentEncryptionKeys[
                                    attachment.id
                                  ];
                                }
                                attachments.splice(index, 1);
                                setAttachments([...attachments]);
                                setAttachmentEncryptionKeys({...attachmentEncryptionKeys});
                              }
                            }
                          }}
                        ></CloseButton>
                      </Center>
                    </Flex>

                    {/* } */}
                    {/* </HStack> */}
                  </ListItem>
                ))}
            </List>

            {/* {!isValidPlexiMailAddress() &&
                      <Text color="orange">{Strings.editor.message.restriction}&nbsp;<TutorialModalLink title="Learn more" anchor={"email-bridging"} />
                      <Link color={"#00aaff"} onClick={() => {
                        normalAddressDisclosure.onOpen()
                      }}>Learn more</Link> 
                      </Text>
                    } */}

            {/* <Dropzone 
                  value={files} 
                  minHeight="64px"
                  onChange={updateFiles} 
                  onClean={handleClean}
                  label={"Drop your files here or Click to add your files"}
                  >
                  {files.map((file, index) => (
                    <FileItem {...file}
                    id={file.id}
                    onDelete={onDelete}
                    onSee={handleSee}
            //localization={"ES-es"}
                    resultOnTooltip
                    preview
                    info
                    hd />
                  ))}
                </Dropzone>

                <FullScreenPreview
                  imgSource={imageSrc}
                  openImage={imageSrc}
                  onClose={(e) => handleSee(undefined)}
                /> */}
          </Box>
        </ModalBody>

        <ModalFooter>
          <input
            id="attachment-file"
            onChange={handleFileUpload}
            type="file"
            style={{ display: "none" }}
            multiple={"multiple"}
            // multiple={false}
          />
          {/* <Button leftIcon={<ReactIcons.FaPaperclip />} colorScheme='pink' variant='solid' mr={3} onClick={addAttachment} disabled={isUploading || isSending()}>Attachment(s)</Button> */}
          <Tooltip label={S.button.add_attachment.tips}>
            {/* <IconButton mr={3} icon={<ReactIcons.FaPaperclip />} color="gray.600" disabled={isUploading || isSending() || !toValues || (toValues.length === 0) || isTraditionalMailRecipient()} onClick={addAttachment} hidden={sendMailPharse === SendMailPharse.ConfirmRecipients} /> */}
            <IconButton
              mr={3}
              icon={<ReactIcons.FaPaperclip />}
              color="gray.600"
              isDisabled={
                isUploading || isSending() || !isReadyToUploadAttachment()
              }
              onClick={addAttachment}
              hidden={sendMailPharse === SendMailPharse.ConfirmRecipients}
            />
          </Tooltip>
          {/* <Button colorScheme='red' disabled={isSending()} mr={3} onClick={closeEditor}>Close</Button> */}

          <Button
            colorScheme="green"
            isLoading={isSending()}
            onClick={sendMessage}
            isDisabled={isUploading || !toValues || toValues.length === 0}
          >
            {sendMailPharse === SendMailPharse.ComposeMessage &&
              S.button.send.send}
            {sendMailPharse === SendMailPharse.CheckRecipients &&
              S.button.send.send}
            {sendMailPharse === SendMailPharse.ConfirmRecipients &&
              S.button.send.trust_and_send}
            {sendMailPharse === SendMailPharse.Sending && S.button.send.send}
          </Button>
          {/* <Tooltip label="Sent Mail"></Tooltip> */}
          <PasswordAlert
            encryptionPassword={encryptionPassword}
            assignable={toValues && toValues.length === 1}
            disclosure={passwordDisclosure}
            onCompleted={(password, shouldAssignAccount, canReply) => {
              if (password && password.length > 0) {
                setEncryptionPassword(password);
                if (confirmPasswordTask) {
                  confirmPasswordTask.resolve(password);
                  setConfirmPasswordTask(null);
                } else {
                  doSendTraditionalMail(
                    password,
                    shouldAssignAccount,
                    canReply
                  ).then(() => {});
                }
              } else {
                if (confirmPasswordTask) {
                  confirmPasswordTask.reject(new Error('no password'));
                  setConfirmPasswordTask(null);
                }
              }
            }}
          />
          <UnverifiedAlert
            disclosure={unverifiedDisclosure}
            onCompleted={() => {
              // realSendMessage().then(() => {}).catch(e => {
              //   console.error(e);
              // })
              if (editMode === "new") {
                hasDraft().then((has) => {
                  if (has) {
                    loadDraftDisclosure.onOpen();
                  }
                });
              }
            }}
          />
          <LoadDraftAlert
            disclosure={loadDraftDisclosure}
            onCompleted={(confirmed) => {
              if (confirmed) {
                loadDraft()
                  .then(() => {})
                  .catch((e) => {
                    console.error(e);
                    showError(e);
                  });
              } else {
                removeDraft()
                  .then(() => {})
                  .catch((e) => {
                    console.error(e);
                    showError(e);
                  });
              }
            }}
          />
          <SaveDraftAlert
            disclosure={saveDraftDisclosure}
            onCompleted={(confirmed) => {
              if (confirmed) {
                saveDraft()
                  .then(() => {
                    closeEditor();
                  })
                  .catch((e) => {
                    console.error(e);
                    showError(e);
                  });
              } else {
                closeEditor();
              }
            }}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MailEditor;
